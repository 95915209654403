import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const addCreditDetails = createAsyncThunk('addCredit', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/add_credits`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getCreditDetails = createAsyncThunk('getCredit', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}billing/get_creditm/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const deleteCredit = createAsyncThunk('deleteCredit', async (id) => {
    try {
        const { data } = await ax.delete(`${backendURL}billing/delete_credit/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const addAccountCredit = createAsyncThunk('add-crdit', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/add_accountcredit`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// billing/save_Account_creditprice
export const saveAccCreditPrice = createAsyncThunk('save-account-credit', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/save_Account_creditprice`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// billing/add_requestcredit
export const addCreditRate = createAsyncThunk('add-crdit=rate', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/save_Account_creditprice`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// billing / add request credit
export const addRequestCredit = createAsyncThunk('add-req-credit', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/add_requestcredit`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// billing / get request credit
export const getRequestCredit = createAsyncThunk('get-req-credit', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}billing/get_requestcredit/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

// billing // getCredit Price
export const getCreditPrice = createAsyncThunk('billing/get_creditprice', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}billing/get_creditprice/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else return error.response;
    }
});

// buy credit // get credit package

export const getCreditPackage = createAsyncThunk('billing/buy-credit', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}billing/get_credit_package`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else return error.response;
    }
});

// billing // my-credit // used credit
export const getCreditUsed = createAsyncThunk('billing/get_usedCredit', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}billing/get_credit_used_account/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else return error.response;
    }
});

// billing // my-credit // credit history
export const getCreditHistory = createAsyncThunk('billing/get_credit_history', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}billing/get_credit_purhistory/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else return error.response;
    }
});
// billing // my-credit // get billing payment
export const getBillPayment = createAsyncThunk('billing/get_billing_payment', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}billing/get_billingpayment/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else return error.response;
    }
});

// billing / proceed to paymemt
export const proceedToPayment = createAsyncThunk('proceed-to-payment', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/credit_payment`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const updateCrdPaymentDetail = createAsyncThunk('update-credit-payment-detail', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/update_payment_detail`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
