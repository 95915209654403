import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const MedicationMaster = createAsyncThunk('medication_master', async () => {
    try {
        const { data } = await ax.get(`${backendURL}patient/medic_mdata/`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});
