import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const RelRequest = createAsyncThunk('request', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/getServicerequest`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const allRelRequest = createAsyncThunk('allRelRequest', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/getreleasedrequestinfo`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//service_request/getdataServicerequest/12

export const getServicerequest = createAsyncThunk('getServicerequest', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/getdataServicerequest/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

//service_request/getdataset/12

export const getDataset = createAsyncThunk('getDataset', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/getdataset/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});
