import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const diagnosisMaster = createAsyncThunk('diagnosis_master', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/diagnosis_mdata/`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const addDiagnosis = createAsyncThunk('add_diagnosis', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/add-diagnosis/`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const deleteDiagnosis = createAsyncThunk('remove-diagnosis', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/remove-diagnosis`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
