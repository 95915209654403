import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Popconfirm, Progress, Modal, moment, Divider } from 'components/elements/AntComponent';
import { InfoCircleOutlined, DownloadOutlined, LoadingOutlined } from 'components/elements/IconComponent';
import { Input, Button, Form, Checkbox, DatePicker, Select, InputNumber, Upload, Radio } from 'components/elements/FormComponent';
import { useSelector, useDispatch, message, Result } from 'components/elements/CommonComponent';
import FileSaver from 'file-saver';
import { getCountry, getState } from 'store/actions/commonServiceAction';
import { symptomsMaster } from 'store/actions/master-data/SymptomsActions';
import { diagnosisMaster } from 'store/actions/master-data/DiagnosisActions';
import { getOrderManagement, saveOrder, adminSaveOrder } from 'store/actions/orderManagementAction';
import { approveReq } from 'store/actions/pipeline-request/pipelinereqActions';
import { documentDownload } from 'store/actions/pipeline-request/wizard-api/associateDocumentActions';
import NewPatientHealth from './NewPatientHealth';
import ExisingPatientModals from 'components/order-management/modals/ExistingPatientModal';
import { AccountTypes, PatientTypes } from 'store/actions/service-request/newRequestActions';
import ReportRate from 'components/order-management/ReportRate';
import { getCreditDetails } from 'store/actions/billing/creditManagerAction';
import dayjs from 'dayjs';
import { sendMail } from 'store/actions/pipeline-request/wizard-api/ReqInfoAction';

const { TextArea } = Input;

export default function NewServiceRequest() {
    const [form] = Form.useForm();
    const history = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [pntID, setPntID] = useState(0);
    const progress = useSelector((state) => state.upload.progress);
    const downloadProgress = useSelector((state) => state.download.docDownProgress);
    const { orderInfo, error1, loading, loading3, adSaveOrder, success3,error3 } = useSelector((state) => state.order);
    const { getCredit } = useSelector((state) => state.creditManager);
    const { pntQues } = useSelector((state) => state.reqInfo);
    const { docdownload } = useSelector((state) => state.associateDoc);
    const { accType, loading2 } = useSelector((state) => state.request);
    const [fileName, setFileName] = useState('');
    const { success5, error5 } = useSelector((state) => state.creditManager);
    const { countryInfo, stateInfo, loading6, loading7 } = useSelector((state) => state.commonData);
    const { symptomsInfo, loading: sympLoading } = useSelector((state) => state.symptoms);
    const { diagnosisInfo, loading: diagLoading } = useSelector((state) => state.diagnosis);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const [phq, setPhq] = useState({ item1: '', item2: '', item3: '', item4: '', item5: '', item6: '', item7: '', item8: '' });
    const [validatePhq, setValidatePhq] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [consent1, setConsent1] = useState(true);
    const [consent2, setConsent2] = useState(true);
    const [consent3, setConsent3] = useState(true);
    const [medicInfo, setMedicInfo] = useState([]);
    const successmsg = showSuccessmsg ? success3 : null;
    const [errorMsg, setErrormsg] = useState(false);
    const showerrMsg = errorMsg ? error3 : false;
    const [visibleUpload, setVisibleUpload] = useState(true);
    const [approveRequest, setApproveRequest] = useState(false);
    const [errorForm, setErrorForm] = useState(false);
    const [check2, setCheck2] = useState(false);
    const [check3, setCheck3] = useState(false);
    const [check5, setCheck5] = useState(false);
    const [check6, setCheck6] = useState(false);
    const [check7, setCheck7] = useState(false);
    const [check9, setCheck9] = useState(false);
    const [check10, setCheck10] = useState(false);
    const [ECfile, setECFile] = useState(null);
    const [EOfile, setEOFile] = useState(null);
    const [ECfile1, setECfile1] = useState(null);
    const [EOfile1, setEOfile1] = useState(null);
    const [showsuccessmsg, setShowsuccessmsg] = useState(false);
    const successmsg1 = showsuccessmsg ? success5 : null;
    const [showErrmsg, setShowErrmsg] = useState(false);
    const errmsg = showErrmsg ? error5 : null;

    const [selectedCountry, setSelectedCountry] = useState(0);
    const customFormat = (percent) => `${percent}%`;
    const [visible, setVisible] = useState(true);
    const [questionID, setQuestionID] = useState(0);
    const [showDrawer, setShowDrawer] = useState(false);
    const statusReq = location?.state?.status || false;
    const [defaultValues, setDefaultValues] = useState(null);
    let reqId = location?.state?.reqId || null;

    let requestFrom = location.state?.requestFrom || null;
    const isActive = location?.state?.active ? true : false;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorData, setErrorData] = useState(null);
    const [ampName, setAmpName] = useState('');
    const [isNFBChecked, setIsNFBChecked] = useState(false);
    const [isMedChecked, setIsMedChecked] = useState(false);
    const [isRushChecked, setIsRushChecked] = useState(false);
    const inputRef = useRef(null);
    const accTypes = accType?.data || [];

    const [termModal, setTermModal] = useState(false);
    const [isTermChecked, setIsTermChecked] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [isBilling, setIsBilling] = useState(false);
    const [totalCredit, setTotalCredit] = useState(0);
    const [accountId, setAccountId] = useState(0);
    const [billingType, setBillingType] = useState('');
    const [selectedDiag, setSelectedDiag] = useState([]);
    const [selectedSymp, setSelectedSymp] = useState([]);
    const isReqUpdate = location?.state?.isReqUpdate;
    const [availableCredit, setAvailableCredit] = useState(0);
    const [defaultCredit, setDefaultCredit] = useState(0);
    const [accountDetail, setAccountDetail] = useState({});
    const [email, setEmail] = useState('');

    const getStatusIndicator = () => {
        if (availableCredit < defaultCredit) {
            return (
                <span className="indicator error-indicator">
                    Insufficient credit. Available balance for {accountDetail?.label} is {availableCredit}. Charges will be applied upon submitting this request.
                </span>
            );
        } else if (availableCredit === defaultCredit) {
            return (
                <span className="indicator warning-indicator">
                    Warning: Available credit only covers the default report. Available balance for {accountDetail?.label} is {availableCredit}. Extra charges will be incurred for additional reports.
                </span>
            );
        }
        return null;
    };

    const showModal = (e) => {
        e.stopPropagation();
        setTermModal(true);
    };

    const handelTermChanges = (e) => {
        setIsTermChecked(e.target.checked);
        if (!e.target.checked) {
            setErrMsg('To proceed, please accept the terms and conditions');
        } else {
            setErrMsg('');
        }
    };

    const GendersOption = [
        {
            label: 'Male',
            value: 'Male',
        },
        {
            label: 'Female',
            value: 'Female',
        },
        {
            label: 'Other',
            value: 'Other',
        },
    ];
    const genderIdentityOptions = [
        {
            label: 'Male',
            value: 'Male',
        },
        {
            label: 'Female',
            value: 'Female',
        },
        {
            label: 'Non-binary',
            value: 'Non-binary',
        },
        {
            label: 'Transgender',
            value: 'Transgender',
        },
    ];
    const occupationOptions = [
        {
            value: 'Physician',
            label: 'Physician',
        },
        {
            value: 'Security',
            label: 'Security',
        },
        {
            value: 'Business man',
            label: 'Business man',
        },
        {
            value: 'Social Worker',
            label: 'Social Worker',
        },
        {
            value: 'Engineer',
            label: 'Engineer',
        },
        {
            value: 'Others',
            label: 'Others',
        },
    ];
    const amplifierOptions = [
        {
            label: 'Zeto',
            value: 'Zeto',
        },
        {
            label: 'Deymed',
            value: 'Deymed',
        },
        {
            label: 'Mitsar',
            value: 'Mitsar',
        },
        {
            label: 'Brain Master',
            value: 'Brain Master',
        },
        {
            label: 'CGX',
            value: 'CGX',
        },
    ];

    const [updateAmplifier, setUpdateAmplifier] = useState(amplifierOptions.map((option) => option.value));

    const accountOptions = accTypes.map((item) => {
        return { value: item.id, label: item.acct_name, isBilling: item.is_billing, billingType: item?.bill_type, availableCredit: item?.balance_credit };
    });

    const countryOptions = loading6
        ? []
        : countryInfo?.data?.map((item) => {
              return {
                  label: item.countryname,
                  value: item.id.toString(),
                  key: item.id,
              };
          });

    const stateOptions = loading7
        ? []
        : stateInfo?.data?.map((item) => {
              return {
                  label: item.statename,
                  value: item.id.toString(),
                  key: item.id,
              };
          });

    const diagOptions = diagnosisInfo?.data?.map((item) => {
        return {
            label: item.diagnosis_name,
            value: item.id.toString(),
            key: item.id,
        };
    });

    const sympOptions = symptomsInfo?.data?.map((item) => {
        return {
            label: item.symptoms_name,
            value: item.id.toString(),
            key: item.id,
        };
    });

    function getOrderDetails() {
        dispatch(getOrderManagement(location?.state?.reqId));
    }

    const getDefaultCredit = () => {
        dispatch(getCreditDetails(accountId));
    };

    useEffect(() => {
        getDefaultCredit();
    }, [accountId !== 0]);

    useEffect(() => {
        const totalDefaultCreditValue = getCredit?.data?.filter((item) => item?.is_associate || item?.is_default)?.reduce((acc, item) => acc + item?.credit_Value, 0);

        setDefaultCredit(totalDefaultCreditValue);
    }, [getCredit]);

    useEffect(() => {
        if (accountDetail) {
            setAvailableCredit(accountDetail?.availableCredit);
        }
    }, [accountDetail]);

    useEffect(() => {
        if (location?.state?.reqId) {
            setDefaultValues([]);
            getOrderDetails();
        } else {
            form.resetFields();
            setDefaultValues([]);
        }
    }, [dispatch]);

    useEffect(() => {
        if (pntQues?.data && reqId) {
            let score = pntQues?.data;
            setPhq({
                item1: score[0]?.phq_score,
                item2: score[1]?.phq_score,
                item3: score[2]?.phq_score,
                item4: score[3]?.phq_score,
                item5: score[4]?.phq_score,
                item6: score[5]?.phq_score,
                item7: score[6]?.phq_score,
                item8: score[7]?.phq_score,
            });
        }
    }, [pntQues?.data]);

    useEffect(() => {
        if (orderInfo?.data && reqId) {
            setDefaultValues(orderInfo?.data?.reqinfo);
            setConsent1(orderInfo?.data?.reqinfo?.eeg_research);
            setConsent2(orderInfo?.data?.reqinfo?.eeg_analyst);
            setConsent3(orderInfo?.data?.reqinfo?.eeg_data_privacy);
            let doclist = orderInfo?.data?.doclist;
            if (orderInfo?.data?.doclist) {
                for (let i = 0; i < doclist.length; i++) {
                    if (doclist[i].doc_type == 'Eye Open') {
                        setECFile(doclist[i]);
                    } else {
                        setEOFile(doclist[i]);
                    }
                }
            } else {
                setECFile(null);
                setEOFile(null);
            }
        }
    }, [orderInfo?.data]);

    useEffect(() => {
        if (visibleUpload) {
            const timer = setTimeout(() => {
                if (progress == 100) {
                    setVisibleUpload(false);
                }
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, [progress]);

    useEffect(() => {
        if (countryInfo?.data) {
            getStateDetails(231);
        }
    }, [countryInfo]);

    useEffect(() => {
        getSymptoms();
    }, []);

    useEffect(() => {
        getDiagnosis();
    }, []);

    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                if (downloadProgress == 100) {
                    setVisible(false);
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [downloadProgress]);

    useEffect(() => {
        if (docdownload) {
            downloadDocument();
        }
    }, [docdownload]);

    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                if (downloadProgress == 100) {
                    setVisible(false);
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [downloadProgress]);

    useEffect(() => {
        if (showerrMsg) {
            if (error3?.data) {
                if (error3?.EO_edf?.edf_file) {
                    message.error(`The Eyes opened EDF file has ${error3?.EO_edf?.edf_duration_eo} seconds recorded duration. It should be
                   minimum of 2 minutes duration.`);
                } else if (error3?.EC_edf?.edf_file) {
                    message.error(`The Eyes closed EDF file has ${error3?.EC_edf?.edf_duration_ec} seconds recorded duration. It should be
                    minimum of 2 minutes duration.`);
                } else if (error3?.EO_edf?.edf_file && error3?.EC_edf?.edf_file) {
                    message.error(`The Eyes closed and Eyes opened EDF files have ${error3?.EO_edf?.edf_duration_eo} seconds recorded duration. They should be
                    minimum of 2 minutes duration each.`);
                } else {
                    message.error(error3?.message);
                    setIsModalOpen(true);
                    form.setFieldsValue({
                        first_Name: error3?.data?.pnt_info?.first_name || '',
                        last_Name: error3?.data?.pnt_info?.last_name || '',
                        gender: error3?.data?.pnt_info?.gender || '',
                        genderIdentity: error3?.data?.pnt_info?.gender_identity || '',
                        occupation: error3?.data?.pnt_info?.occupation || '',
                        contactno: error3?.data?.pnt_info?.contact_number || '',
                        handedness: error3?.data?.pnt_info?.handedness || '',
                        address: error3?.data?.pnt_info?.address || '',
                        city: error3?.data?.pnt_info?.city || '',
                        country: error3?.data?.pnt_info?.country || '231',
                        zip: error3?.data?.pnt_info?.zip || '',
                        state: error3?.data?.pnt_info?.state || '',
                        email: error3?.data?.pnt_info?.pnt_contact_email || '',
                    });
                    setPntID(error3?.data?.pnt_info?.id);
                }
            } else {
                message.error('Request couldnt be created!');
            }

            setErrormsg(false);
        }
        if (successmsg && location.state.reqId == null) {
            submitEmail();
            setEmail('');
        }
    }, [showerrMsg, successmsg]);

    const submitEmail = () => {
        let inputJson = {
            email: email,
        };
        dispatch(sendMail(inputJson));
    };

    useEffect(() => {
        getCountryState(selectedCountry);
    }, [dispatch]);

    useEffect(() => {
        dispatch(AccountTypes());
    }, [dispatch]);

    const displayDrawer = () => {
        setShowDrawer(true);
    };

    const drawerCallbackFunc = (item, item2, item3) => {
        setShowDrawer(item);
        setIsBilling(item2);
        setBillingType(item3);
    };

    const callbackFuncs = (item) => {
        setShowsuccessmsg(item);
        setShowErrmsg(item);
    };

    const callbackReport = (item, item1) => {
        setShowResult(item);
        setTotalCredit(item1);
    };

    function getDiagnosis() {
        let payload = {};
        dispatch(diagnosisMaster(payload));
    }
    function getSymptoms() {
        let payload = {};
        dispatch(symptomsMaster(payload));
    }

    function downloadFile(id, name) {
        setFileName(name);
        let inputJson = {
            docid: id,
        };
        dispatch(documentDownload(inputJson));
        setVisible(true);
    }

    function getStateDetails(id) {
        let inputJson = {
            countryid: id,
        };
        dispatch(getState(inputJson));
    }

    function getCountryState(id) {
        let inputJson = {
            countryid: id || 0,
        };
        dispatch(getCountry(inputJson));
    }
    function getPatientDetails(id) {
        dispatch(PatientTypes(id));
    }
    const downloadDocument = () => {
        var fileExt = fileName?.split('.').pop();
        if (fileExt == 'edf') {
            const bytes = new Uint8Array(
                atob(docdownload?.data?.encodefiledata)
                    .split('')
                    .map((char) => char.charCodeAt(0))
            );
            const blob = new Blob([bytes], { type: 'text/plain;charset=utf-8;base64' });
            FileSaver.saveAs(blob, `${fileName}.edf`);
        } else return;
    };

    const changeFileEC = (info) => {
        setECfile1(info?.fileList);
    };

    const changeFileEO = (info) => {
        setEOfile1(info?.fileList);
    };

    const normalizeName = (name) => {
        return name.trim().replace(/\s+/g, ' ');
    };

    const handleAccountChange = (e, value) => {
        setAccountDetail(value);
        getPatientDetails(e);
        setAccountId(e);
        setIsBilling(value?.isBilling);
        setBillingType(value?.billingType);
    };

    const changeCountry = (value) => {
        let id = value;
        form.setFieldsValue({
            country: value.toString(),
        });
        form.resetFields(['state']);
        setSelectedCountry(id);
        getStateDetails(id);
    };

    const addItem = (e) => {
        e.preventDefault();
        const normalizedAmpName = normalizeName(ampName);
        if (normalizedAmpName.length > 3) {
            const lowercaseAmpName = normalizedAmpName.toLowerCase();
            if (!updateAmplifier.map((option) => option.toLowerCase()).includes(lowercaseAmpName)) {
                setUpdateAmplifier([...updateAmplifier, normalizedAmpName]);
                setAmpName('');
            } else {
                message.error('Amplifier name already exists.');
            }
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    };

    const handleKeyDown = (fieldName) => (event) => {
        const fieldOptions = fieldName === 'ppClinicalDiagnosis' ? diagOptions : sympOptions;
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submission
            const inputValue = event.target.value.trim().toLowerCase();
            const existingOption = fieldOptions?.find((option) => option.label?.toLowerCase() === inputValue) || null;
            const val = form.getFieldValue(fieldName) || [];
            const filteredOptions = fieldOptions?.filter((option) => val.includes(option.label?.toLowerCase()) || option.label?.toLowerCase() === inputValue?.toLowerCase());
            const filteredValues = filteredOptions?.map((option) => option.value);
            if (filteredOptions) {
                if (existingOption) {
                    const combinedValues = [
                        ...filteredValues,
                        ...val.filter((v) => {
                            return !filteredOptions.some((opt) => opt.label?.toLowerCase() === v?.toLowerCase());
                        }),
                    ];
                    const combinedValuesSet = new Set([...combinedValues]);
                    const filterVal = Array.from(combinedValuesSet);
                    form.setFieldValue(fieldName, filterVal);
                } else {
                    const filtValue = val.filter((item) => item === inputValue);
                    const defValue = fieldName === 'ppClinicalDiagnosis' ? selectedDiag : selectedSymp || [];
                    const lowercaseValues = val?.map((item) => item.toLowerCase());
                    const lowercaseDefValue = defValue ? defValue?.map((item) => item.toLowerCase()) : [];
                    const newValues =
                        filtValue.length > 0 ? [...lowercaseValues, inputValue.toLowerCase()].filter((item, index, self) => self.indexOf(item) === index && item !== '') : [...lowercaseValues, ...lowercaseDefValue];
                    const combinedValuesSet1 = new Set(newValues);
                    const filterVal = Array.from(combinedValuesSet1);
                    form.setFieldValue(fieldName, filterVal);
                }
            }
            event.target.value = '';
        }
    };

    const onNameChange = (event) => {
        setAmpName(event.target.value);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setErrorData(null);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
        setPntID(0);
        setErrorData(true);
        form.setFieldsValue({ country: '231' });
        setECFile(null);
        setECfile1(null);
        setEOfile1(null);
        setEOFile(null);
        setCheck2(false);
        setCheck3(false);
        setCheck5(false);
        setCheck6(false);
        setCheck7(false);
        setCheck9(false);
        setCheck10(false);
    };

    const onChangeCheckbox = (checkedValues) => {
        const isNFBChecked = checkedValues.includes('NFB Protocols');
        const isMedChecked = checkedValues.includes('Med Recommendation');
        const isRushChecked = checkedValues.includes('RUSH');
        setIsNFBChecked(isNFBChecked);
        setIsMedChecked(isMedChecked);
        setIsRushChecked(isRushChecked);
    };

    const formatter = (value) => {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (!match) return value;
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    };

    const parser = (value) => {
        const cleaned = ('' + value).replace(/\D/g, '');
        if (cleaned === '' || isNaN(cleaned)) {
            return null;
        }
        return parseInt(cleaned);
    };

    const validateAge = (_, value) => {
        const birth = new Date(value);
        const today = new Date();
        let age = today.getFullYear() - birth.getFullYear();
        const month = today.getMonth() - birth.getMonth();

        if (month < 0 || (month === 0 && today.getDate() < birth.getDate())) {
            age--;
        }
        if (age < 5) {
            return Promise.reject('Age must be at least 5 years old.');
        }
        return Promise.resolve();
    };

    const validatePhone = (rule, value) => {
        const pattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        if (value && !pattern.test(value)) {
            return Promise.reject('Please enter a valid US phone number');
        } else if (Number.isNaN(value)) {
            return Promise.reject('Please enter number');
        } else {
            return Promise.resolve();
        }
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const callbackFunc = (val) => {
        let val1 = val;
        val1.forEach((item, i) => {
            if (item.id == 1) {
                if (item.r1 == true) {
                    phq.item1 = 0;
                } else if (item.r2 == true) {
                    phq.item1 = 1;
                } else if (item.r3 === true) {
                    phq.item1 = 2;
                } else if (item.r4 == true) {
                    phq.item1 = 3;
                } else {
                    phq.item1 = '';
                }
            }
            if (item.id == 2) {
                if (item.r1 == true) {
                    phq.item2 = 0;
                } else if (item.r2 == true) {
                    phq.item2 = 1;
                } else if (item.r3 === true) {
                    phq.item2 = 2;
                } else if (item.r4 == true) {
                    phq.item2 = 3;
                } else {
                    phq.item2 = '';
                }
            }
            if (item.id == 3) {
                if (item.r1 == true) {
                    phq.item3 = 0;
                } else if (item.r2 == true) {
                    phq.item3 = 1;
                } else if (item.r3 === true) {
                    phq.item3 = 2;
                } else if (item.r4 == true) {
                    phq.item3 = 3;
                } else {
                    phq.item3 = '';
                }
            }
            if (item.id == 4) {
                if (item.r1 == true) {
                    phq.item4 = 0;
                } else if (item.r2 == true) {
                    phq.item4 = 1;
                } else if (item.r3 === true) {
                    phq.item4 = 2;
                } else if (item.r4 == true) {
                    phq.item4 = 3;
                } else {
                    phq.item4 = '';
                }
            }
            if (item.id == 5) {
                if (item.r1 == true) {
                    phq.item5 = 0;
                } else if (item.r2 == true) {
                    phq.item5 = 1;
                } else if (item.r3 === true) {
                    phq.item5 = 2;
                } else if (item.r4 == true) {
                    phq.item5 = 3;
                } else {
                    phq.item5 = '';
                }
            }
            if (item.id == 6) {
                if (item.r1 == true) {
                    phq.item6 = 0;
                } else if (item.r2 == true) {
                    phq.item6 = 1;
                } else if (item.r3 === true) {
                    phq.item6 = 2;
                } else if (item.r4 == true) {
                    phq.item6 = 3;
                } else {
                    phq.item6 = '';
                }
            }
            if (item.id == 7) {
                if (item.r1 == true) {
                    phq.item7 = 0;
                } else if (item.r2 == true) {
                    phq.item7 = 1;
                } else if (item.r3 === true) {
                    phq.item7 = 2;
                } else if (item.r4 == true) {
                    phq.item7 = 3;
                } else {
                    phq.item7 = '';
                }
            }
            if (item.id == 8) {
                if (item.r1 == true) {
                    phq.item8 = 0;
                } else if (item.r2 == true) {
                    phq.item8 = 1;
                } else if (item.r3 === true) {
                    phq.item8 = 2;
                } else if (item.r4 == true) {
                    phq.item8 = 3;
                } else {
                    phq.item8 = '';
                }
            }
        });
    };

    const callbackMedic = (item) => {
        setMedicInfo(item);
    };

    const callbackConsent = (item1) => {
        setTermModal(item1);
    };

    const loadData = (defaultValues, id) => {
        form.setFieldsValue({
            first_Name: defaultValues?.first_name || '',
            last_Name: defaultValues?.last_name || '',
            gender: defaultValues?.gender || '',
            genderIdentity: defaultValues?.gender_identity || '',
            account: Number(location?.state?.reqDetail?.accountid ? location?.state?.reqDetail?.accountid : defaultValues?.accountid) || '',
            occupation: defaultValues?.occupation || '',
            contactno: defaultValues?.contact_number || '',
            handedness: defaultValues?.handedness || '',
            address: defaultValues?.address || '',
            city: defaultValues?.city || '',
            country: defaultValues && defaultValues !== null ? defaultValues?.country : '231',
            zip: defaultValues?.zip || '',
            state: defaultValues?.state || '',
            email: defaultValues?.pnt_contact_email || '',
            amplifierUsed: defaultValues?.amplifierUsed || '',
            clinicalAmplifier: defaultValues?.clinician_and_amplifier_used == 'N/A' ? '' : defaultValues?.clinician_and_amplifier_used || '',
            pastPsychiatric: defaultValues?.past_psychiatric_medication_response == 'N/A' ? '' : defaultValues?.past_psychiatric_medication_response || '',
            pntSymptomsConcerns: defaultValues?.patient_symptomsconcerns == 'N/A' || defaultValues?.patient_symptomsconcerns == '' ? [] : defaultValues?.patient_symptomsconcerns?.split(',') || [],
            medications: defaultValues?.medications == 'N/A' ? '' : defaultValues?.medications || '',
            medsTaken: defaultValues?.were_meds_taken_within_48_hours_of_appointment === true ? 'True' : defaultValues?.were_meds_taken_within_48_hours_of_appointment === false ? 'False' : null || '',
            stimulants: defaultValues?.did_client_have_stimulants_day_of_scan === true ? 'True' : defaultValues?.did_client_have_stimulants_day_of_scan === false ? 'False' : null || '',
            ppClinicalDiagnosis: defaultValues?.Past_Present_clinical_diagnosis == 'N/A' || defaultValues?.Past_Present_clinical_diagnosis == '' ? [] : defaultValues?.Past_Present_clinical_diagnosis?.split(',') || [],
            previousReport: defaultValues?.previous_qeeg_report_pre_post_comaprison_request == 'N/A' ? '' : defaultValues?.previous_qeeg_report_pre_post_comaprison_request?.split(',') || '',
            patientRequire: defaultValues?.does_patient_require == 'N/A' ? '' : defaultValues?.does_patient_require?.split(',') || '',
            briefHistory: defaultValues?.brief_history == 'N/A' ? '' : defaultValues?.brief_history || '',
            dob: defaultValues ? moment(defaultValues?.dob) : null,
            eyeopenfile: null,
            eyeclosefile: null,
        });
        setEmail(defaultValues?.pnt_contact_email);
        if (reqId) {
            setPntID(defaultValues?.patientid);
        } else {
            setPntID(id);
        }

        if (defaultValues?.patient_symptomsconcerns == 'N/A') {
            setCheck3(true);
        } else {
            setCheck3(false);
        }
        if (defaultValues.Past_Present_clinical_diagnosis == 'N/A') {
            setCheck2(true);
        } else {
            setCheck2(false);
        }
        if (defaultValues?.does_patient_require === 'N/A') {
            setCheck9(true);
        } else {
            setCheck9(false);
        }
        if (defaultValues?.past_psychiatric_medication_response === 'N/A') {
            setCheck7(true);
        } else {
            setCheck7(false);
        }
        if (defaultValues?.brief_history === 'N/A') {
            setCheck10(true);
        } else {
            setCheck10(false);
        }

        if (location?.state?.reqId) {
            if (location?.state?.error && defaultValues?.were_meds_taken_within_48_hours_of_appointment === null) {
                setCheck5(true);
            } else if (defaultValues?.were_meds_taken_within_48_hours_of_appointment === true || defaultValues?.were_meds_taken_within_48_hours_of_appointment === false) {
                setCheck5(false);
            } else {
                if ((!isActive && !orderInfo?.data?.reqinfo?.session_is_draft) || orderInfo?.data?.reqinfo?.session_is_draft) {
                    setCheck5(false);
                } else {
                    setCheck5(true);
                }
            }
            if (location?.state?.error && defaultValues?.did_client_have_stimulants_day_of_scan === null) {
                setCheck6(true);
            } else if (defaultValues?.did_client_have_stimulants_day_of_scan === true || defaultValues?.did_client_have_stimulants_day_of_scan === false) {
                setCheck6(false);
            } else {
                if ((!isActive && !orderInfo?.data?.reqinfo?.session_is_draft) || orderInfo?.data?.reqinfo?.session_is_draft) {
                    setCheck6(false);
                } else {
                    setCheck6(true);
                }
            }
        } else {
            setCheck5(false);
            setCheck6(false);
            setECFile(null);
            setEOFile(null);
            setECfile1(null);
            setEOfile1(null);
        }
        setQuestionID(0);
        setAccountId(Number(location?.state?.reqDetail?.accountid ? location?.state?.reqDetail?.accountid : defaultValues?.accountid));
    };

    useEffect(() => {
        if (defaultValues && reqId) {
            loadData(defaultValues, 0);
        } else {
            form.resetFields();
            form.setFieldsValue({ country: '231' });
        }
    }, [defaultValues]);

    useEffect(() => {
        if (loading) {
            form.resetFields();
            setCheck5(false);
            setCheck6(false);
            setECFile(null);
            setEOFile(null);
            setECfile1(null);
            setEOfile1(null);
        }
    }, [loading]);

    const onFinishFailed = (errorInfo) => {
        if (errorInfo?.errorFields?.length > 0) {
            setErrorForm(true);
        } else {
            setErrorForm(false);
        }
    };

    const onFinish = (values) => {
        let formData = new FormData();
        let validphq = false;
        setErrorForm(false);
        if (phq.item1 === '' || phq.item2 === '' || phq.item3 === '' || phq.item4 === '' || phq.item5 === '' || phq.item6 === '' || phq.item7 === '' || phq.item8 === '') {
            setValidatePhq(true);
            validphq = true;
        } else {
            setValidatePhq(false);
            validphq = false;
        }

        let d = [];
        let medic = medicInfo;
        for (let i = 0; i < medic.length; i++) {
            if (medic[i].field1 !== '') {
                d.push({
                    medicine_name: medic[i].field1,
                    dosage: medic[i].field2,
                    medication_started_on: '',
                    medication_ended_on: '',
                    status: 'Present',
                });
            }
        }
        setVisible(false);

        if (!validphq) {
            setErrMsg('');
            let x = values;
            let inputJson = {
                reqid: location?.state?.reqId || 0,
                req_type: 'eeg data analysis',
                accountid: Number(x.account),
                first_name: x.first_Name || '',
                patientid: pntID || 0,
                last_name: x.last_Name || '',
                gender: x.gender || '',
                dob: x['dob'].format('YYYY-MM-DD') || '',
                pnt_contact_email: x.email || '',
                occupation: x.occupation || '',
                gender_identity: x.genderIdentity,
                address: x.address || '',
                country: x.country || '',
                contact_number: x.contactno?.toString() || '',
                state: x.state || '',
                city: x.city || '',
                zip: x.zip || '',
                handedness: x.handedness || '',
                PntQuestionnaireid: isReqUpdate ? location?.state?.reqId : questionID || 0,
                amplifierUsed: x.amplifierUsed || '',
                clinician_and_amplifier_used: x.clinicalAmplifier || '',
                Past_Present_clinical_diagnosis: check2 ? 'N/A' : x.ppClinicalDiagnosis?.toString() || '',
                patient_symptomsconcerns: check3 ? 'N/A' : x.pntSymptomsConcerns?.toString() || '',
                medications: x.medications || '',
                were_meds_taken_within_48_hours_of_appointment: check5 ? null : x.medsTaken || '',
                did_client_have_stimulants_day_of_scan: check6 ? null : x.stimulants || '',
                past_psychiatric_medication_response: check7 ? 'N/A' : x?.pastPsychiatric || '',
                previous_qeeg_report_pre_post_comaprison_request: '',
                does_patient_require: check9 ? 'N/A' : x?.patientRequire?.toString() || '',
                brief_history: check10 ? 'N/A' : x?.briefHistory || '',
                EC_equipment_type: '',
                EO_equipment_type: '',
                diagnosis: check2 ? 'N/A' : x.ppClinicalDiagnosis?.toString() || '',
                undiagnosis: '',
                symptoms: check3 ? 'N/A' : x.pntSymptomsConcerns?.toString() || '',
                unsymptoms: '',
                phqid: isReqUpdate ? location?.state?.reqId : 0,
                'Little interest or pleasure in doing things': phq.item1,
                'Feeling down, depressed, or hopeless': phq.item2,
                'Trouble falling or staying asleep, or sleeping too much': phq.item3,
                'Feeling tired or having little energy': phq.item4,
                'Poor appetite or overeating': phq.item5,
                'Feeling bad about yourself or that you are a failure or have let yourself or your family down': phq.item6,
                'Trouble concentrating on things, such as reading the newspaper or watching television': phq.item7,
                'Moving or speaking so slowly that other people could have noticed. Or the opposite being so fidgety or restless that you have been moving around a lot more than usual.': phq.item8,
                medic: d,
                eeg_research: consent1,
                eeg_analyst: consent2,
                eeg_data_privacy: consent3,
            };
            if (ECfile && EOfile && !location?.state?.error) {
                formData.append('EC_File', '');
                formData.append('EO_File', '');
                formData.append('InputJson', JSON.stringify(inputJson));
                dispatch(adminSaveOrder(formData));
                setShowSuccessmsg(true);
                setErrormsg(true);
                if (approveRequest) {
                    dispatch(approveReq(location?.state?.reqId));
                }
            } else if (ECfile && EOfile && location?.state?.error) {
                let openfile = x.eyeopenfile.file.name.split('.').pop();
                let eyeclose = x.eyeclosefile.file.name.split('.').pop();
                if (x.eyeopenfile.file.size >= 52428800) {
                    message.error('File size must be less than 50MB!');
                } else if (openfile?.toLowerCase() !== 'edf') {
                    message.error('The Eyes opened file is not EDF format');
                } else if (x.eyeclosefile.file.size >= 52428800) {
                    message.error('File size must be less than 50MB!');
                } else if (eyeclose?.toLowerCase() !== 'edf') {
                    message.error('The Eyes closed file is not EDF format');
                } else if (x.eyeopenfile.file.name == x.eyeclosefile.file.name) {
                    message.error('Same file for both types cannot be uploaded');
                } else {
                    formData.append('EC_File', x.eyeclosefile.fileList[0].originFileObj);
                    formData.append('EO_File', x.eyeopenfile.fileList[0].originFileObj);

                    formData.append('InputJson', JSON.stringify(inputJson));
                    dispatch(adminSaveOrder(formData));
                    setShowSuccessmsg(true);
                    setErrormsg(true);
                    if (approveRequest) {
                        dispatch(approveReq(location?.state?.reqId));
                    }
                }
            } else {
                let openfile = x.eyeopenfile.file.name.split('.').pop();
                let eyeclose = x.eyeclosefile.file.name.split('.').pop();
                if (x.eyeopenfile.file.size >= 52428800) {
                    message.error('File size must be less than 50MB!');
                } else if (openfile?.toLowerCase() !== 'edf') {
                    message.error('The Eyes opened file is not EDF format');
                } else if (x.eyeclosefile.file.size >= 52428800) {
                    message.error('File size must be less than 50MB!');
                } else if (eyeclose?.toLowerCase() !== 'edf') {
                    message.error('The Eyes closed file is not EDF format');
                } else if (x.eyeopenfile.file.name == x.eyeclosefile.file.name) {
                    message.error('Same file for both types cannot be uploaded');
                } else {
                    formData.append('EC_File', x.eyeclosefile.fileList[0].originFileObj);
                    formData.append('EO_File', x.eyeopenfile.fileList[0].originFileObj);
                    formData.append('InputJson', JSON.stringify(inputJson));
                    dispatch(adminSaveOrder(formData));
                    setShowSuccessmsg(true);
                    setErrormsg(true);
                    if (approveRequest) {
                        dispatch(approveReq(location?.state?.reqId));
                    }
                }
            }
            setVisible(true);
        } else {
            if (!isTermChecked) {
                setErrMsg('To proceed, please accept the terms and conditions');
            }
        }
    };
    const handleChangeDiagnosis = (val) => (e) => {
        if (e?.length > 0) {
            if (val === 'ppClinicalDiagnosis') {
                setSelectedDiag(e);
            } else {
                setSelectedSymp(e);
            }
        } else {
            if (val === 'ppClinicalDiagnosis') {
                setSelectedDiag([]);
            } else {
                setSelectedSymp([]);
            }
        }
    };
    function initializeDatepicker(_defaultDate) {}
    const defaultDate = dayjs().subtract(5, 'year');

    // Call the function with the default date
    initializeDatepicker(defaultDate);

    const disableDate = (date) => {
        return date.isAfter(defaultDate, 'day');
    };
    return (
        <div className="row order-management-sts">
            {successmsg ? (
                <div className=" vh-100 bg-white">
                    {!showResult && isBilling ? (
                        <div>
                            <ReportRate
                                data={{
                                    callbackReport: callbackReport,
                                    saveOrder: adSaveOrder,
                                    accountId: accountId,
                                    isSuccess: successmsg,
                                    billingType: billingType,
                                    callbackFuncs: callbackFuncs,
                                    isMedChecked: isMedChecked,
                                    isNFBChecked: isNFBChecked,
                                    isRushChecked: isRushChecked,
                                    availableCredit: availableCredit,
                                    accountDetail: accountDetail,
                                }}
                            ></ReportRate>
                        </div>
                    ) : (
                        <div className="p-5">
                            <Result
                                className="p-5"
                                status="success"
                                title={[
                                    <div key="resultinfo">
                                        {isReqUpdate ? <h4 className="fw-normal mb-3"> Order Updated Successfully!</h4> : <h4 className="fw-normal mb-3"> Order Submitted Successfully!</h4>}
                                        <div className="d-flex flex-row justify-content-center">
                                            {/* <h4 className="fw-normal">
                                                Your reference number is
                                                <span className="sub-title-req" style={{ fontWeight: '500', fontSize: '28px' }}>
                                                    {adSaveOrder?.data?.request_number}
                                                </span>
                                            </h4>
                                            {location.state.reqId == null && <h4 className="fw-normal">, patient consent email has been sent</h4>}

                                            {isBilling && (
                                                <h4 className="fw-normal ps-1">
                                                    and a total of&nbsp;
                                                    <span className="sub-title-req" style={{ fontWeight: '500', fontSize: '28px' }}>
                                                        {totalCredit}
                                                    </span>{' '}
                                                    credits were used for this request.
                                                </h4>
                                            )} */}

                                            <h4 className="fw-normal">
                                                Your reference number is{' '}
                                                <span className="sub-title-req" style={{ fontWeight: '500', fontSize: '28px' }}>
                                                {adSaveOrder?.data?.request_number}
                                                </span>
                                                {location.state.reqId == null && ', patient consent email has been sent'}
                                                {isBilling && (
                                                <>
                                                    {' and a total of '}
                                                    <span className="sub-title-req" style={{ fontWeight: '500', fontSize: '28px' }}>
                                                    {totalCredit}
                                                    </span>
                                                    {' credits were used for this request.'}
                                                </>
                                                )}
                                            </h4>
                                        </div>
                                    </div>,
                                ]}
                                extra={[
                                    <React.Fragment key="content">
                                        <Button type="primary" className="mx-auto text-center" key="console" onClick={() => history(isReqUpdate ? '/view-request' : '/new-request')}>
                                            {isReqUpdate ? 'Back to Request in Pipeline' : 'Back to New Request'}
                                        </Button>
                                    </React.Fragment>,
                                ]}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className="serv-grid">
                    <div className="d-flex">
                        {' '}
                        <h5 className="mb-0">New Service Request</h5>
                        {isBilling && billingType == 'credit' && availableCredit <= defaultCredit && <div className="mx-auto">{getStatusIndicator()}</div>}
                        <div className="ms-auto">
                            <Button type="primary" onClick={() => history(isReqUpdate ? '/view-request' : '/new-request')}>
                                Back
                            </Button>
                        </div>
                    </div>
                    <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} className="w-100 row m-0" autoComplete="off">
                        <div className=" px-0">
                            <div className="card rounded mt-2 border-0">
                                <div className="card-header fs-5 border-bottoms p-0 d-flex ">
                                    <h5 className="my-auto col heading-title p-2 antd-bg-color mb-1 fs-16 d-flex justify-content-between">
                                        Patient Demography
                                        {reqId !== null ? (
                                            ''
                                        ) : (
                                            <a className="fs-6 text-end" onClick={() => displayDrawer()}>
                                                Click here for existing Patient
                                            </a>
                                        )}
                                    </h5>
                                </div>
                                <div className="card-body row  px-1 py-3 pb-0">
                                    <div className="row m-0 px-2 order-management-modal">
                                        <div className="col">
                                            <Form.Item
                                                name="first_Name"
                                                label="First name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item
                                                name="last_Name"
                                                label="Last name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item
                                                name="gender"
                                                label="Sex at birth"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Select options={GendersOption} />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item
                                                name="genderIdentity"
                                                label="Gender identity"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Select options={genderIdentityOptions} />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item
                                                label="DOB"
                                                name="dob"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                    {
                                                        validator: validateAge,
                                                    },
                                                ]}
                                            >
                                                <DatePicker size="large" format="MM-DD-YYYY" defaultPickerValue={defaultDate} disabledDate={disableDate} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row m-0 px-2 order-management-modal">
                                        <div className="col">
                                            <Form.Item
                                                label="Contact phone"
                                                name="contactno"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                    {
                                                        validator: validatePhone,
                                                    },
                                                ]}
                                            >
                                                <InputNumber className="w-100" placeholder="(123) 456-7890" maxLength={14} formatter={formatter} parser={parser} />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            {/* <Form.Item name="email" label="Email" rules={[{ type: 'email', message: 'The email is not valid' }]}>
                                                <Input />
                                            </Form.Item> */}

                                            <Form.Item
                                                name="email"
                                                label="Email"
                                                rules={[
                                                    { type: 'email', message: 'The email is not valid' },
                                                    // { required: true, message: 'This field is required' },
                                                ]}
                                            >
                                                <Input onChange={(e) => setEmail(e.target.value)} />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item name="handedness" label="Handedness" rules={[{ required: true, message: 'This field is required' }]}>
                                                <Select
                                                    options={[
                                                        { value: 'righthand', label: 'Right Hand' },
                                                        { value: 'lefthand', label: 'Left Hand' },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="col">
                                            <Form.Item label="Account" name="account" className="col w-100" rules={[{ required: true, message: 'This field is required' }]}>
                                                <Select
                                                    showSearch
                                                    placeholder=""
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                    filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                                    notFoundContent={
                                                        <div className="text-center p-4">
                                                            {loading2 ? (
                                                                <span>
                                                                    <LoadingOutlined />
                                                                    Loading...
                                                                </span>
                                                            ) : (
                                                                <span>No account available</span>
                                                            )}
                                                        </div>
                                                    }
                                                    options={accountOptions}
                                                    onChange={handleAccountChange}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item name="occupation" label="Occupation">
                                                <Select options={occupationOptions} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row m-0 px-2 order-management-modal">
                                        <div className="col ">
                                            <Form.Item name="address" label="Address">
                                                <Input />
                                            </Form.Item>
                                        </div>
                                        <div className="col ">
                                            <Form.Item label="Country" name="country">
                                                <Select
                                                    showSearch
                                                    getPopupContainer={(trigger) => trigger.parentNode}
                                                    onChange={changeCountry}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                    filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                                    options={countryOptions}
                                                    notFoundContent={
                                                        <div className="text-center p-4">
                                                            {loading6 ? (
                                                                <span>
                                                                    <LoadingOutlined />
                                                                    Loading...
                                                                </span>
                                                            ) : (
                                                                <span>No country available</span>
                                                            )}
                                                        </div>
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item label="State" name="state">
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                    filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                                    getPopupContainer={(trigger) => trigger.parentNode}
                                                    options={stateOptions}
                                                    notFoundContent={
                                                        <div className="text-center p-4">
                                                            {loading7 ? (
                                                                <span>
                                                                    <LoadingOutlined />
                                                                    Loading...
                                                                </span>
                                                            ) : (
                                                                <span>No state available</span>
                                                            )}
                                                        </div>
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item name="city" label="City">
                                                <Input />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item name="zip" label="Zip">
                                                <InputNumber type="number" size="large" className="w-100" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {/* session questionnaire */}
                                <div className="card-header fs-5 border-bottoms p-0 d-flex ">
                                    <h5 className="my-auto col heading-title p-2 antd-bg-color mb-1 fs-16 ">Session Questionnaire</h5>
                                </div>
                                <div className="card-body row px-1 py-3 pb-0">
                                    <div className="row m-0 px-2 pb-3">
                                        <div className="col ">
                                            <Form.Item
                                                name="clinicalAmplifier"
                                                label="Clinician"
                                                className="mb-1"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <TextArea rows={1} disabled={location?.state?.error} />
                                            </Form.Item>
                                        </div>
                                        <div className="col ">
                                            <Form.Item
                                                name="amplifierUsed"
                                                label="Amplifier Used"
                                                className="mb-1"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    dropdownRender={(menu) => (
                                                        <>
                                                            {menu}
                                                            <Divider className="my-2" />
                                                            <div className="px-2">
                                                                <span className="fw-bold">Other</span>
                                                            </div>
                                                            <div className="d-flex align-items-end  w-75  p-2">
                                                                <Input
                                                                    className="h-100"
                                                                    placeholder="Enter the Amplifier Name"
                                                                    ref={inputRef}
                                                                    value={ampName}
                                                                    onChange={onNameChange}
                                                                    onPressEnter={addItem}
                                                                    onKeyDown={(e) => e.stopPropagation()}
                                                                />
                                                                <Button className="mx-2 h-100 " type="primary" onClick={addItem} disabled={ampName.length <= 3}>
                                                                    Add
                                                                </Button>
                                                            </div>
                                                        </>
                                                    )}
                                                    disabled={location?.state?.error}
                                                >
                                                    {updateAmplifier.map((item) => (
                                                        <Select.Option key={item} value={item}>
                                                            {item}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item
                                                name="ppClinicalDiagnosis"
                                                label=" Past/Present Clinical Diagnosis (if applicable)"
                                                className="mb-1"
                                                rules={[
                                                    {
                                                        required: !check2,
                                                        message: check2 == false ? 'This field is required' : '',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    options={diagOptions}
                                                    notFoundContent={
                                                        <div className="text-center p-4">
                                                            {diagLoading ? (
                                                                <span>
                                                                    <LoadingOutlined />
                                                                    Loading...
                                                                </span>
                                                            ) : (
                                                                <span>No diagnosis available</span>
                                                            )}
                                                        </div>
                                                    }
                                                    disabled={location?.state?.error || check2}
                                                    mode="tags"
                                                    onChange={handleChangeDiagnosis('ppClinicalDiagnosis')}
                                                    onKeyDown={handleKeyDown('ppClinicalDiagnosis')}
                                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                />
                                            </Form.Item>
                                            {!check2 ? (
                                                <Popconfirm title="" description="Are you sure that you want to ignore diagnosis?" onConfirm={() => setCheck2(true)} okText="Yes" cancelText="No">
                                                    <span>
                                                        <Checkbox className="na-check" disabled>
                                                            If not applicable, N/A
                                                        </Checkbox>
                                                    </span>
                                                </Popconfirm>
                                            ) : (
                                                <Checkbox
                                                    className="na-check"
                                                    checked={check2}
                                                    disabled={location?.state?.error}
                                                    onChange={(e) => {
                                                        setCheck2(e.target.checked);
                                                        form.resetFields(['ppClinicalDiagnosis']);
                                                    }}
                                                >
                                                    If not applicable, N/A
                                                </Checkbox>
                                            )}
                                        </div>
                                        <div className="col">
                                            <Form.Item
                                                name="pntSymptomsConcerns"
                                                label="Patient Symptoms/Concerns"
                                                className="mb-1"
                                                rules={[
                                                    {
                                                        required: !check3,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    options={sympOptions}
                                                    notFoundContent={
                                                        <div className="text-center p-4">
                                                            {sympLoading ? (
                                                                <span>
                                                                    <LoadingOutlined />
                                                                    Loading...
                                                                </span>
                                                            ) : (
                                                                <span>No symptoms available</span>
                                                            )}
                                                        </div>
                                                    }
                                                    disabled={location?.state?.error || check3}
                                                    mode="tags"
                                                    onChange={handleChangeDiagnosis('pntSymptomsConcerns')}
                                                    onKeyDown={handleKeyDown('pntSymptomsConcerns')}
                                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                />
                                            </Form.Item>
                                            {!check3 ? (
                                                <Popconfirm
                                                    title=""
                                                    description="Are you sure that you want to ignore symptoms?"
                                                    onConfirm={() => {
                                                        setCheck3(true);
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <span>
                                                        <Checkbox className="na-check" disabled>
                                                            If not applicable, N/A
                                                        </Checkbox>
                                                    </span>
                                                </Popconfirm>
                                            ) : (
                                                <Checkbox
                                                    className="na-check"
                                                    checked={check3}
                                                    disabled={location?.state?.error}
                                                    onChange={(e) => {
                                                        setCheck3(e.target.checked);
                                                        form.resetFields(['pntSymptomsConcerns']);
                                                    }}
                                                >
                                                    If not applicable, N/A
                                                </Checkbox>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row m-0 px-2">
                                        <div className="col">
                                            <Form.Item
                                                name="pastPsychiatric"
                                                label="Past Psychiatric Medication Response (If Known)"
                                                className="mb-1"
                                                rules={[
                                                    {
                                                        required: !check7,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <TextArea rows={1} disabled={location?.state?.error || check7} />
                                            </Form.Item>
                                            <Checkbox
                                                className="na-check"
                                                checked={check7}
                                                disabled={location?.state?.error}
                                                onChange={(e) => {
                                                    setCheck7(e.target.checked);
                                                    form.resetFields(['pastPsychiatric']);
                                                }}
                                            >
                                                If not applicable, N/A
                                            </Checkbox>
                                        </div>
                                        <div className="col">
                                            <Form.Item
                                                name="medsTaken"
                                                label="Were meds taken within 48 hours of appointment?"
                                                className="mb-1"
                                                rules={[
                                                    {
                                                        required: !check5,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Radio.Group name="radiogroup" disabled={location?.state?.error || check5}>
                                                    <Radio value={'True'}>Yes</Radio>
                                                    <Radio value={'False'}>No</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                            <Checkbox
                                                className="na-check"
                                                checked={check5}
                                                disabled={location?.state?.error}
                                                onChange={(e) => {
                                                    setCheck5(e.target.checked);
                                                    form.resetFields(['medsTaken']);
                                                }}
                                            >
                                                If not applicable, N/A
                                            </Checkbox>
                                        </div>
                                        <div className="col">
                                            <Form.Item
                                                name="stimulants"
                                                label="Did client have stimulants day of scan? (caffeine, soda, cannabis, etc)"
                                                className="mb-1"
                                                rules={[
                                                    {
                                                        required: !check6,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Radio.Group name="radiogroup1" disabled={location?.state?.error || check6}>
                                                    <Radio value={'True'}>Yes</Radio>
                                                    <Radio value={'False'}>No</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                            <Checkbox
                                                className="na-check"
                                                checked={check6}
                                                disabled={location?.state?.error}
                                                onChange={(e) => {
                                                    setCheck6(e.target.checked);
                                                    form.resetFields(['stimulants']);
                                                }}
                                            >
                                                If not applicable, N/A
                                            </Checkbox>
                                        </div>
                                        {!accTypes.is_billing ? (
                                            <div className="col mb-1">
                                                <Form.Item
                                                    name="patientRequire"
                                                    label="Does patient require"
                                                    className="mb-1"
                                                    rules={[
                                                        {
                                                            required: !accTypes?.is_billing ? !check9 : true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Checkbox.Group disabled={check9 || location?.state?.error} onChange={onChangeCheckbox}>
                                                        <div>
                                                            <Checkbox value="RUSH" className="me-2">
                                                                RUSH
                                                            </Checkbox>
                                                        </div>
                                                    </Checkbox.Group>
                                                </Form.Item>
                                                <Checkbox
                                                    className="na-check"
                                                    checked={check9}
                                                    disabled={location?.state?.error}
                                                    onChange={(e) => {
                                                        setCheck9(e.target.checked);
                                                        form.resetFields(['patientRequire']);
                                                        setIsNFBChecked(false);
                                                        setIsMedChecked(false);
                                                        setIsRushChecked(false);
                                                    }}
                                                >
                                                    If not applicable, N/A
                                                </Checkbox>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="row m-0 px-2 mb-3">
                                        <div className="">
                                            <Form.Item
                                                name="briefHistory"
                                                label="Brief History"
                                                className="mb-1"
                                                rules={[
                                                    {
                                                        required: !check10,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <TextArea rows={5} disabled={location?.state?.error || check10} />
                                            </Form.Item>
                                            <Checkbox
                                                className="na-check"
                                                checked={check10}
                                                disabled={location?.state?.error}
                                                onChange={(e) => {
                                                    setCheck10(e.target.checked);
                                                    form.resetFields(['briefHistory']);
                                                }}
                                            >
                                                If not applicable, N/A
                                            </Checkbox>
                                        </div>
                                    </div>
                                </div>
                                {/* patient intake form */}
                                <div className="card-header fs-5 border-bottoms p-0 d-flex ">
                                    <h5 className="my-auto col heading-title p-2 antd-bg-color mb-1 fs-16 ">Patient Health Questionnaire</h5>
                                </div>
                                <NewPatientHealth
                                    data={{
                                        reqId: location?.state?.reqId,
                                        callbackFunc: callbackFunc,
                                        isError: location?.state?.error,
                                        validatePhq: validatePhq,
                                        callbackMedic: callbackMedic,
                                        error1: errorData || null,
                                        isUae: selectedCountry,
                                    }}
                                />
                                <div className="card-header fs-5 border-bottoms p-0 d-flex ">
                                    <h5 className="my-auto col heading-title p-2 antd-bg-color mb-1 fs-16 ">EDF file upload</h5>
                                </div>
                                <div className="my-2 row mx-3">
                                    <div className="col-md-6 col-lg-6 ps-0 pt-3">
                                        {ECfile && !location?.state?.error ? (
                                            <>
                                                <label className="mb-2">Eyes Closed</label>
                                                <div className="d-flex border p-3 rounded">
                                                    <div className="col text-break">{ECfile?.orginal_name}</div>
                                                    <div className="ms-auto">
                                                        <DownloadOutlined onClick={() => downloadFile(ECfile?.id, ECfile?.orginal_name)} />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <Form.Item
                                                name="eyeclosefile"
                                                label="Eyes Closed"
                                                rules={[
                                                    {
                                                        required: statusReq ? false : true,

                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Upload
                                                    beforeUpload={() => {
                                                        return false;
                                                    }}
                                                    maxCount={1}
                                                    fileList={ECfile1 || []}
                                                    onChange={changeFileEC}
                                                    listType="picture"
                                                    accept={'.edf'}
                                                >
                                                    <Button className="p-4 w-100">Click or drag file to this area to upload</Button>
                                                </Upload>
                                            </Form.Item>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-lg-6 pt-3 pe-0">
                                        {EOfile && !location?.state?.error ? (
                                            <>
                                                <label className="mb-2">Eyes Opened</label>
                                                <div className="d-flex border p-3 rounded">
                                                    <div className="col text-break">{EOfile?.orginal_name}</div>
                                                    <div className="ms-auto">
                                                        <DownloadOutlined onClick={() => downloadFile(ECfile?.id, ECfile?.filename)} />
                                                    </div>
                                                </div>{' '}
                                            </>
                                        ) : (
                                            <Form.Item
                                                name="eyeopenfile"
                                                label="Eyes Opened"
                                                rules={[
                                                    {
                                                        required: statusReq ? false : true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Upload
                                                    beforeUpload={() => {
                                                        return false;
                                                    }}
                                                    listType="picture"
                                                    maxCount={1}
                                                    fileList={EOfile1 || []}
                                                    onChange={changeFileEO}
                                                    accept={'.edf'}
                                                >
                                                    <Button className="p-4 w-100">Click or drag file to this area to upload</Button>
                                                </Upload>
                                            </Form.Item>
                                        )}
                                    </div>
                                    <div className="text-center info-edf d-flex">
                                        <span></span>
                                        <InfoCircleOutlined className="my-auto" />{' '}
                                        <div className="p-1">
                                            <span>Each EDF file should be minimum of 2 minutes recorded duration to get better result.</span>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="p-3 patient-consent">
                                    <Checkbox checked={isTermChecked} className="check-agree" onChange={handelTermChanges}>
                                        I agree to the{' '}
                                    </Checkbox>
                                    <span className="preview-term" onClick={showModal}>
                                        terms and conditions
                                    </span>
                                </div>

                                <PatientConsent
                                    data={{
                                        isOpen: termModal,
                                        callbackConsent: callbackConsent,
                                    }}
                                /> */}
                                <div className="line-progress px-2">
                                    {' '}
                                    {progress && visibleUpload ? (
                                        <Progress
                                            percent={`${progress}`}
                                            strokeColor={{
                                                '0%': '#1F98DF',
                                                '100%': '#87d068',
                                            }}
                                            format={customFormat}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {downloadProgress && visible ? (
                                        <Progress
                                            percent={`${downloadProgress}`}
                                            strokeColor={{
                                                '0%': '#1F98DF',
                                                '100%': '#87d068',
                                            }}
                                            format={customFormat}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="my-3 text-end row mx-0 justify-content-end pe-0">
                            {errorForm ? <div className="text-danger col-auto ms-auto text-end mx-3">Mandatory field(s) not filled</div> : ''}
                            {validatePhq && errMsg ? (
                                <div className="text-danger text-end col-auto ms-auto">Patient Health Questionnaire is required and accept the terms and conditions to proceed</div>
                            ) : validatePhq ? (
                                <div className="text-danger text-end col-auto ms-auto">Patient Health Questionnaire is required</div>
                            ) : errMsg ? (
                                <div className="text-danger text-end col-auto ms-auto">{errMsg}</div>
                            ) : (
                                ''
                            )}
                            {defaultValues?.session_is_draft || (!isActive && reqId !== null && requestFrom === 3) ? (
                                <>
                                    <Button
                                        type="primary"
                                        loading={loading3}
                                        htmlType="submit"
                                        className="col-auto me-2 text-end"
                                        onClick={() => {
                                            setApproveRequest(true);
                                            setVisibleUpload(true);
                                        }}
                                    >
                                        Save
                                    </Button>
                                </>
                            ) : (
                                ''
                            )}
                            {location?.state?.error ? (
                                <Button
                                    type="primary"
                                    loading={loading3}
                                    htmlType="submit"
                                    className="col-auto me-0 text-end"
                                    onClick={() => {
                                        setApproveRequest(false);
                                        setVisibleUpload(true);
                                    }}
                                >
                                    Save
                                </Button>
                            ) : (
                                ''
                            )}
                            {reqId == null && !isActive ? (
                                // <Tooltip title={!isTermChecked ? 'To proceed, please accept the Terms and Conditions' : ''} className="mt-0">
                                <Button
                                    type="primary"
                                    loading={loading3}
                                    htmlType="submit"
                                    className="col-auto"
                                    // disabled={!isTermChecked}
                                    onClick={() => {
                                        setApproveRequest(false);
                                        setVisibleUpload(true);
                                    }}
                                >
                                    Save
                                </Button>
                            ) : (
                                // </Tooltip>
                                ''
                            )}
                            {reqId != null ? (
                                <Button
                                    type="primary"
                                    loading={loading3}
                                    htmlType="submit"
                                    className="col-auto"
                                    onClick={() => {
                                        setApproveRequest(false);
                                        setVisibleUpload(true);
                                    }}
                                >
                                    Save
                                </Button>
                            ) : (
                                ''
                            )}
                        </div>
                    </Form>
                </div>
            )}

            <ExisingPatientModals data={{ drawerCallbackFunc: drawerCallbackFunc, isOpen: showDrawer, loadData: loadData, isAdmin: true, accountId: accountId }} />

            <Modal
                title="Confirm"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        No
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Yes
                    </Button>,
                ]}
            >
                This patient is already exist. Would you like to continue?
            </Modal>
        </div>
    );
}
