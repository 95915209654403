import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    downloadProgress: 0,
    reqDownProgress: 0,
    docDownProgress: 0,
    resultDownProgress: 0,
    resultDownloadProgress: 0,
};

const downloadSlice = createSlice({
    name: 'download',
    initialState,
    reducers: {
        setDownloadProgress: (state, action) => {
            state.downloadProgress = action.payload;
        },
        setReqDownProgress: (state, action) => {
            state.reqDownProgress = action.payload;
        },
        setDocDownProgress: (state, action) => {
            state.docDownProgress = action.payload;
        },
        setResultDownProgress: (state, action) => {
            state.resultDownProgress = action.payload;
        },
        setResultDownloadProgress: (state, action) => {
            state.resultDownloadProgress = action.payload;
        },
    },
});

export const { setDownloadProgress, setReqDownProgress, setDocDownProgress, setResultDownProgress, setResultDownloadProgress } = downloadSlice.actions;
export default downloadSlice.reducer;
