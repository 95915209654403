import { createSlice } from '@reduxjs/toolkit';
import { addSymptoms, deleteSymptoms, symptomsMaster } from 'store/actions/master-data/SymptomsActions';

const initialState = {
  loading: false,
  symptomsInfo: null,
  error: null,
  success: false,
  //addsymp
  loading1:false,
  addSymptomsInfo:null,
  error1:null,
  success1:false,
  //removediagnosis
  loading2:false,
  rmvsymp:null,
  error2:null,
  success2:false
}

const symptomsMasterSlice = createSlice({
  name: 'symptomsMaster',
  initialState,
  reducers: {},
  extraReducers:(builder)=> {
  builder
  //get symptoms
  .addCase(symptomsMaster.pending,(state)=>{
    state.loading = true;
    state.error = null;
    state.success = false;
  })
  .addCase(symptomsMaster.fulfilled,(state,{payload})=>{
    state.loading = false;
    state.symptomsInfo = payload;
    state.success=true;
  })
  .addCase(symptomsMaster.rejected,(state,{payload})=>{
    state.loading = false;
    state.error = payload;
    state.success=false;
  })
  //add symptoms
  .addCase(addSymptoms.pending,(state)=>{
    state.loading1 = true
    state.error1 = null
    state.success1= false;
  })
  .addCase(addSymptoms.fulfilled,(state,{payload})=>{
    state.loading1 = false
    state.addSymptomsInfo = payload;
    state.success1=true;
  })
  .addCase(addSymptoms.rejected,(state,{payload})=>{
    state.loading1 = false;
    state.error1 = payload
    state.success1 = false;
  })
  //delete symptoms
  .addCase(deleteSymptoms.pending,(state)=>{
    state.loading2 = true
    state.error2 = null
    state.success2= false;
  })
  .addCase(deleteSymptoms.fulfilled,(state,{payload})=>{
    state.loading2 = false
    state.rmvsymp = payload;
    state.success2=true;
  })
  .addCase(deleteSymptoms.rejected,(state,{payload})=>{
    state.loading2 = false;
    state.error2 = payload;
    state.success2 = false;
  })
  },
})
export default symptomsMasterSlice.reducer

