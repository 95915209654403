import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';
import { setDocDownProgress } from 'store/slices/downloadFile';
import { docUploadProgress } from 'store/slices/uploadFile';

const backendURL = config.apiURL;

export const associateDocument = createAsyncThunk('associateDocument', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/doclist`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

let uploadprogress = 0;
export const documentUpload = createAsyncThunk('docupload', async (formData, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/docupload`, formData, {
            onUploadProgress: (progressEvent) => {
                uploadprogress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                dispatch(docUploadProgress(uploadprogress));
            },
        });
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// docrepo/docdownload
let progress = 0;
export const documentDownload = createAsyncThunk('docdownload', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/docdownload`, payload, {
            onDownloadProgress: (progressEvent) => {
                progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                dispatch(setDocDownProgress(progress));
            },
        });
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const doclistDetails = createAsyncThunk('doclistdetails', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}docrepo/doclist/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});

//docrepo/updatedoc
export const updateAssDoc = createAsyncThunk('updateAssDoc', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/updatedoc`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//equipment type
export const getDocEquitypes = createAsyncThunk('docequitypess', async () => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/eqtypes`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});
//docrepo/docdelete
export const deleteDocument = createAsyncThunk('document-delete', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/docdelete`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
