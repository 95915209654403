import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const symptomsMaster = createAsyncThunk('symptoms_master', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/symptoms_mdata/`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const addSymptoms = createAsyncThunk('add_symptoms', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/add-symptoms/`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const deleteSymptoms = createAsyncThunk('remove-symp', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/remove-symptoms`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
