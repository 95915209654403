import { createSlice } from '@reduxjs/toolkit';
import { AddPhotobiomodulation, GetPhotobiomodulation, addPDRContent, addPntForm, allPatientTag, commonMasterData, getAccountAll, getCountry, getPDRContent, getPh8Data, getState, saveCommonData, verifyPntForm } from 'store/actions/commonServiceAction';


const initialState = {
    //account-al
    loading: false,
    getAllAccount: null,
    error: null,
    success: false,
    //patient-tag-all
    loading1: false,
    allTags: null,
    error1: null,
    success1: false,
    //diagnosis,symp,supp,lifestyle,
    loading2: false,
    commonRecData: null,
    error2: null,
    success2: false,
    //save common service
    loading3: false,
    savecommonData: null,
    error3: null,
    success3: false,
    //add public pnt,
    loading5:false,
    addpntInfo:null,
    error5:null,
    success5:false,
    //verify pnt form
    loading4:false,
    // verifyPntForm:null,
    pntaccInfo:null,
    error4:null,
    success4:false,
    //get country
    loading6:false,
    countryInfo:null,
    error6:null,
    success6:false,
    //get state
    loading7:false,
    stateInfo:null,
    error7:null,
    success7:false,
     //get ph8
     loadingy:false,
     ph8Data:null,
     error8:null,
     success8:false,
     //add photo bio
     loading9:false,
     addPhotoInfo:null,
     error9:null,
     success9:false,
     //get photo bio
     loading10:false,
     getPhotoInfo:null,
     error10:null,
     success10:false,
     //add pdr content
     loading11:false,
     addPdrInfo:null,
     error11:null,
     success11:false,
     //getpdr content
     loading12:false,
     getPdrInfo:null,
     error12:null,
     success12:false,
}

const commonSlice = createSlice({
    name: 'common-slices',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAccountAll.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getAccountAll.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.getAllAccount = payload;
                state.success = true;
            })
            .addCase(getAccountAll.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            //all patient tags
            .addCase(allPatientTag.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(allPatientTag.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.allTags = payload;
                state.success1 = true;
            })
            .addCase(allPatientTag.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.error1 = payload;
                state.success1 = false;
            })
            //diagnosis,symp,supp,lifestyle
            .addCase(commonMasterData.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(commonMasterData.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.commonRecData = payload;
                state.success2 = false;
            })
            .addCase(commonMasterData.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.error2 = payload;
                state.success2 = false;
            })
            //save diagnosis,symp,supp,lifestyle
            .addCase(saveCommonData.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(saveCommonData.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.savecommonData = payload;
                state.success3 = true;
            })
            .addCase(saveCommonData.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })
            //verify public patient form
            .addCase(verifyPntForm.pending, (state) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(verifyPntForm.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.pntaccInfo = payload;
                state.success4 = true;
            })
            .addCase(verifyPntForm.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            })
            //add public patient form
            .addCase(addPntForm.pending, (state) => {
                state.loading5 = true;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(addPntForm.fulfilled, (state, { payload }) => {
                state.loading5 = false;
                state.addpntInfo = payload;
                state.success5 = true;
            })
            .addCase(addPntForm.rejected, (state, { payload }) => {
                state.loading5 = false;
                state.error5 = payload;
                state.success5 = false;
            })
             //get country info
             .addCase(getCountry.pending, (state) => {
                state.loading6 = true;
                state.error6 = null;
                state.success6 = false;
            })
            .addCase(getCountry.fulfilled, (state, { payload }) => {
                state.loading6 = false;
                state.countryInfo = payload;
                state.success6 = true;
            })
            .addCase(getCountry.rejected, (state, { payload }) => {
                state.loading6 = false;
                state.error6 = payload;
                state.success6 = false;
            })
             //get state info
             .addCase(getState.pending, (state) => {
                state.loading7 = true;
                state.error7 = null;
                state.success7 = false;
            })
            .addCase(getState.fulfilled, (state, { payload }) => {
                state.loading7 = false;
                state.stateInfo = payload;
                state.success7 = true;
            })
            .addCase(getState.rejected, (state, { payload }) => {
                state.loading7 = false;
                state.error7 = payload;
                state.success7 = false;
            })
            //get phq8
            .addCase(getPh8Data.pending, (state) => {
                state.loadingy = true;
                state.error8 = null;
                state.success8 = false;
            })
            .addCase(getPh8Data.fulfilled, (state, { payload }) => {
                state.loadingy = false;
                state.ph8Data = payload;
                state.success8 = true;
            })
            .addCase(getPh8Data.rejected, (state, { payload }) => {
                state.loadingy = false;
                state.error8= payload;
                state.success8 = false;
            })
            //add photobiomodulation
            .addCase(AddPhotobiomodulation.pending, (state) => {
                state.loading9 = true;
                state.error9 = null;
                state.success9 = false;
            })
            .addCase(AddPhotobiomodulation.fulfilled, (state, { payload }) => {
                state.loading9 = false;
                state.addPhotoInfo = payload;
                state.success9 = true;
            })
            .addCase(AddPhotobiomodulation.rejected, (state, { payload }) => {
                state.loading9 = false;
                state.error9= payload;
                state.success9 = false;
            })
            //get photobiomodulation
            .addCase(GetPhotobiomodulation.pending, (state) => {
                state.loading10 = true;
                state.error10 = null;
                state.success10 = false;
            })
            .addCase(GetPhotobiomodulation.fulfilled, (state, { payload }) => {
                state.loading10 = false;
                state.getPhotoInfo = payload;
                state.success10 = true;
            })
            .addCase(GetPhotobiomodulation.rejected, (state, { payload }) => {
                state.loading10 = false;
                state.error10= payload;
                state.success10 = false;
            })
            //add pdr content
            .addCase(addPDRContent.pending, (state) => {
                state.loading11 = true;
                state.error11 = null;
                state.success11 = false;
            })
            .addCase(addPDRContent.fulfilled, (state, { payload }) => {
                state.loading11 = false;
                state.addPdrInfo = payload;
                state.success11 = true;
            })
            .addCase(addPDRContent.rejected, (state, { payload }) => {
                state.loading11 = false;
                state.error11= payload;
                state.success11 = false;
            })
            //get pdr content
            .addCase(getPDRContent.pending, (state) => {
                state.loading12 = true;
                state.error12= null;
                state.success12 = false;
            })
            .addCase(getPDRContent.fulfilled, (state, { payload }) => {
                state.loading12 = false;
                state.getPdrInfo = payload;
                state.success12 = true;
            })
            .addCase(getPDRContent.rejected, (state, { payload }) => {
                state.loading12 = false;
                state.error12= payload;
                state.success12 = false;
            })
    },

});

export default commonSlice.reducer

