import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Button, Input } from 'components/elements/FormComponent';
import { message, useDispatch, useSelector } from 'components/elements/CommonComponent';
import LoginImage from 'assets/images/login-image.png';
import EEGImage from 'assets/images/brandname.png';
import 'assets/styles/auth/login.scss';
import { changeDefaultPass, confirmPass, VerifyToken } from 'store/actions/authActions';

const ResetPassword = () => {
    const { success6, loading6, error6 } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const passwordPattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const [showSuccessmsg, setShowsuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success6 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error6 : false;

    const onFinish = (values) => {
        const inputJson = {
            old_password: values.oldpassword?.trim(),
            new_password: values.password?.trim(),
            userid: localStorage.getItem('userid'),
        };
        dispatch(changeDefaultPass(inputJson));
        sessionStorage.setItem('password', values.password);
        setShowsuccessmsg(true);
        setShowErrormsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            setShowsuccessmsg(false);
            message.success('Password Changed Successfully');
            navigate('/dashboard');
        }
        if (errormsg) {
            setShowErrormsg(false);
            message.error("Password couldn't be changed");
        }
    }, [successmsg]);

    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
    });
    const validateNewPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (value && getFieldValue('oldpassword') === value) {
                return Promise.reject(new Error('New password cannot be the same as the old password!'));
            }
            return Promise.resolve();
        },
    });

    return (
        <div className="row login-cont w-100">
            <div className="col text-end">
                <img src={LoginImage} width="90%" height="90%" alt="EEG Login" />
            </div>
            <div className="col-md-5 my-auto">
                <div className="site-card-border-less-wrapper login-btn">
                    <img src={EEGImage} width="60%" height="100" alt="brand name"></img>
                    <h1 className="mt-3">Welcome Back !</h1>
                    <h5 className="mb-5 w-50 mx-auto">You must change your password</h5>
                    <Form
                        form={form}
                        layout="vertical"
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="oldpassword"
                            className="text-start mb-3"
                            label="Old Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password" autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            className="text-start mb-3"
                            label="New Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                                {
                                    pattern: passwordPattern,
                                    message: 'Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character',
                                },
                                validateNewPassword,
                            ]}
                        >
                            <Input.Password placeholder="Password" autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item
                            name="cpassword"
                            className="text-start"
                            label="Confirm Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                                validateConfirmPassword,
                            ]}
                        >
                            <Input.Password placeholder="Confirm Password" autoComplete="confirm-password" />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                span: 24,
                            }}
                        >
                            <Button block className="btn-reset btn mt-3" htmlType="submit" size="large" loading={loading6}>
                                Change Password
                            </Button>
                            {/* <div className="text-end mt-3 pe-1">
                                <Link to="/login" className="text-decoration-none ">
                                    Back to Login ?
                                </Link>
                            </div> */}
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
