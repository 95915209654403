import { createSlice } from '@reduxjs/toolkit';
import { addCreditDetails, getCreditDetails, deleteCredit, addAccountCredit, addCreditRate,addRequestCredit,getRequestCredit,saveAccCreditPrice, getCreditPrice, getCreditPackage,getCreditUsed,getCreditHistory,
    getBillPayment,proceedToPayment, updateCrdPaymentDetail} from 'store/actions/billing/creditManagerAction'

const initialState = {
    //get credit details 
    loading: false,
    getCredit: null,
    error: null,
    success: false,
    // add credit details
    loading1: false,
    addCredit: null,
    error1: null,
    success1: false,
    //delete credit details 
    loading2: false,
    delCredit: null,
    error2: null,
    success2: false,
    // add account credit
    loading3: false,
    addAccCr: null,
    error3: null,
    success3: false,
    //add credit rate
    loading4: false,
    addAccRate: null,
    error4: null,
    success4: false,
    //add Request Credit
    loading5: false,
    addReqCrd: null,
    error5: null,
    success5: false,
    //get Request Credit
    loading6: false,
    getReqCrd: null,
    error6: null,
    success6: false,
    //save account credit rate
    loading7: false,
    saveAccCrd: null,
    error7: null,
    success7: false,
    //get credit price
    loading8:false,
    getCrdPrc:null,
    error8:null,
    success8:false,
    //get credit package
    loading9:false,
    getCrdPkg:null,
    error9:null,
    success9:false,
    //get used credit 
    loading10:false,
    getCrdUsed:null,
    error10:null,
    success10:false,
    //get credit history
    loading11:false,
    getCrdHis:null,
    error11:null,
    success11:false,
    // get billing payment
    loading12:false,
    getBillPay:null,
    error12:null,
    success12:false,
    // proceed to payment
    loading13:false,
    proceedPay:null,
    error13:null,
    success13:false,
    //update credit payment detail 
    loading14: false,
    updateCrdPStatus: null,
    error14: null,
    success14: false,
}

const creditManagerSlice = createSlice({
    name: 'creditManager',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //get credit details
            .addCase(getCreditDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getCreditDetails.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.getCredit = payload;
                state.success = true;
            })
            .addCase(getCreditDetails.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            //add credit details
            .addCase(addCreditDetails.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(addCreditDetails.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.addCredit = payload;
                state.success1 = true;
            })
            .addCase(addCreditDetails.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.error1 = payload;
                state.success1 = false;
            })
            //delete credit details
            .addCase(deleteCredit.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(deleteCredit.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.delCredit = payload;
                state.success2 = true;
            })
            .addCase(deleteCredit.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.error2 = payload;
                state.success2 = false;
            })
            // add account credit
            .addCase(addAccountCredit.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(addAccountCredit.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.addAccCr = payload;
                state.success3 = true;
            })
            .addCase(addAccountCredit.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })
            //add credit rate
            .addCase(addCreditRate.pending, (state) => {
                state.loading4= true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(addCreditRate.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.addAccRate = payload;
                state.success4 = true;
            })
            .addCase(addCreditRate.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            })
             //add  request credit 
             .addCase(addRequestCredit.pending, (state) => {
                state.loading5= true;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(addRequestCredit.fulfilled, (state, { payload }) => {
                state.loading5 = false;
                state.addReqCrd = payload;
                state.success5 = true;
            })
            .addCase(addRequestCredit.rejected, (state, { payload }) => {
                state.loading5 = false;
                state.error5 = payload;
                state.success5 = false;
            })
             //get  request credit 
             .addCase(getRequestCredit.pending, (state) => {
                state.loading6= true;
                state.error6 = null;
                state.success6 = false;
            })
            .addCase(getRequestCredit.fulfilled, (state, { payload }) => {
                state.loading6 = false;
                state.getReqCrd = payload;
                state.success6 = true;
            })
            .addCase(getRequestCredit.rejected, (state, { payload }) => {
                state.loading6 = false;
                state.error6 = payload;
                state.success6 = false;
            })
           //save account credit rate 
             .addCase(saveAccCreditPrice.pending, (state) => {
                state.loading7= true;
                state.error7 = null;
                state.success7 = false;
            })
            .addCase(saveAccCreditPrice.fulfilled, (state, { payload }) => {
                state.loading7 = false;
                state.saveAccCrd = payload;
                state.success7 = true;
            })
            .addCase(saveAccCreditPrice.rejected, (state, { payload }) => {
                state.loading7 = false;
                state.error7 = payload;
                state.success7 = false;
            })
            //get crdit price
            .addCase(getCreditPrice.pending, (state) => {
                state.loading8= true;
                state.error8 = null;
                state.success8 = false;
            })
            .addCase(getCreditPrice.fulfilled, (state, { payload }) => {
                state.loading8 = false;
                state.getCrdPrc = payload;
                state.success8 = true;
            })
            .addCase(getCreditPrice.rejected, (state, { payload }) => {
                state.loading8 = false;
                state.error8 = payload;
                state.success8 = false;
            })
            //get credit package
            .addCase(getCreditPackage.pending, (state) => {
                state.loading9= true;
                state.error9= null;
                state.success9 = false;
            })
            .addCase(getCreditPackage.fulfilled, (state, { payload }) => {
                state.loading9 = false;
                state.getCrdPkg = payload;
                state.success9 = true;
            })
            .addCase(getCreditPackage.rejected, (state, { payload }) => {
                state.loading9 = false;
                state.error9 = payload;
                state.success9 = false;
            })
            // get used credit
            .addCase(getCreditUsed.pending, (state) => {
                state.loading10= true;
                state.error10= null;
                state.success10 = false;
            })
            .addCase(getCreditUsed.fulfilled, (state, { payload }) => {
                state.loading10 = false;
                state.getCrdUsed = payload;
                state.success10 = true;
            })
            .addCase(getCreditUsed.rejected, (state, { payload }) => {
                state.loading10 = false;
                state.error10 = payload;
                state.success10 = false;
            })
            // get credit history
            .addCase(getCreditHistory.pending, (state) => {
                state.loading11= true;
                state.error11= null;
                state.success11= false;
            })
            .addCase(getCreditHistory.fulfilled, (state, { payload }) => {
                state.loading11 = false;
                state.getCrdHis = payload;
                state.success11 = true;
            })
            .addCase(getCreditHistory.rejected, (state, { payload }) => {
                state.loading11 = false;
                state.error11 = payload;
                state.success11 = false;
            })
            // get billing payment 
            .addCase(getBillPayment.pending, (state) => {
                state.loading12= true;
                state.error12= null;
                state.success12= false;
            })
            .addCase(getBillPayment.fulfilled, (state, { payload }) => {
                state.loading12 = false;
                state.getBillPay = payload;
                state.success12 = true;
            })
            .addCase(getBillPayment.rejected, (state, { payload }) => {
                state.loading12 = false;
                state.error12 = payload;
                state.success12 = false;
            })
            // proceed to payment
            .addCase(proceedToPayment.pending, (state) => {
                state.loading13= true;
                state.error13= null;
                state.success13= false;
            })
            .addCase(proceedToPayment.fulfilled, (state, { payload }) => {
                state.loading13 = false;
                state.proceedPay = payload;
                state.success13 = true;
            })
            .addCase(proceedToPayment.rejected, (state, { payload }) => {
                state.loading13 = false;
                state.error13 = payload;
                state.success13 = false;
            })
            //update credit payment details
            .addCase(updateCrdPaymentDetail.pending, (state) => {
                state.loading14 = true;
                state.error14 = null;
                state.success14 = false;
            })
            .addCase(updateCrdPaymentDetail.fulfilled, (state, { payload }) => {
                state.loading14 = false;
                state.updateCrdPStatus = payload;
                state.success14 = true;
            })
            .addCase(updateCrdPaymentDetail.rejected, (state, { payload }) => {
                state.loading14 = false;
                state.error14 = payload;
                state.success14 = false;
            })
    },
})
export default creditManagerSlice.reducer