import React, { useState } from 'react';
import { Modal } from 'components/elements/AntComponent';
import BuyCredit from 'components/billing/account/BuyCredit';

export default function BuyPlanModal(props) {
    const [open, setOpen] = useState(props.data.openModal);
    const closeWindow = () => {
        setOpen(false);
        props.data.closeModal();
    };
    return (
        <div>
            <Modal title="Buy Plan" width={1000} open={props.data.openModal} onCancel={() => closeWindow()}>
                <BuyCredit data={{ edit: true, closeModal: props.data.closeModal }} />
            </Modal>
        </div>
    );
}
