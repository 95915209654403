import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

//account/getAccount
export const accountList = createAsyncThunk('accountlist', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}account/getAccount`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//account/saveAccount
export const addAccount = createAsyncThunk('addaccount', async (formData, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}account/saveAccount`, formData);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//account/getUser/9
export const accountUser = createAsyncThunk('accountuser', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}account/getUser`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//account/getAccount/8

export const getAccountData = createAsyncThunk('getAccountdata', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}account/getAccount/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

//account/saveUser

export const addUser = createAsyncThunk('adduser', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}auth/generate_user`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//account/getUserGroup
export const getUserGroup = createAsyncThunk('getusergroup', async  (payload, { rejectWithValue })  => {
    try {            
        const { data } = await ax.post( `${backendURL}account/getusergroups`, payload)
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data)
        } else {
            return rejectWithValue(error.message)
        }
    }
}  
);