import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, Skeleton } from 'components/elements/CommonComponent';
import { PlusSquareOutlined, DeleteOutlined, SearchOutlined } from 'components/elements/IconComponent';
import { Input, Select, Checkbox } from 'components/elements/FormComponent';
import { getPh8Data } from 'store/actions/commonServiceAction';
import axios from 'axios';
import { getPntQuestionnaire } from 'store/actions/pipeline-request/wizard-api/ReqInfoAction';
import { requestMedication } from 'store/actions/orderManagementAction';

const baseurl = 'https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms';

export default function NewPatientHealth(props) {
    const dispatch = useDispatch();
    const { ph8Data, loadingy } = useSelector((state) => state.commonData);
    const [phq, setPhq] = useState([]);
    const [phq1, setPhq1] = useState([]);
    const [rows, setRows] = useState([
        { id: 1, field1: '', field2: '' },
        { id: 2, field1: '', field2: '' },
        { id: 3, field1: '', field2: '' },
        { id: 4, field1: '', field2: '' },
    ]);
    const [nextId, setNextId] = useState(5);
    const [drugOptions, setDrugOptions] = useState([]);
    const [dosage, setDosage] = useState([]);
    const { orderInfo, reqMedic } = useSelector((state) => state.order);
    const defData = reqMedic ? reqMedic?.data : [];
    const { pntQues } = useSelector((state) => state.reqInfo);
    const [hasValue, setHasValue] = useState([]);
    const reqId = props?.data?.reqId || null;
    const validatePhq = props?.data?.validatePhq;
    const orderData = orderInfo?.data?.reqinfo || null;
    const isError = props?.data?.isError || false;
    const errorData = props?.data?.error1 || null;

    useEffect(() => {
        const clearedRows = rows.map((row) => ({ ...row, field1: '', field2: '' }));
        setRows(clearedRows);
    }, [props?.data?.isUae]);

    useEffect(() => {
        if (errorData) {
            setRows([
                { id: 1, field1: '', field2: '' },
                { id: 2, field1: '', field2: '' },
                { id: 3, field1: '', field2: '' },
                { id: 4, field1: '', field2: '' },
            ]);
        }
    }, [errorData]);

    const questionData = ph8Data?.data?.map((item, i) => {
        return {
            id: item.id,
            heading: item.heading,
            radioName: 'question' + i,
            r1: false,
            r2: false,
            r3: false,
            r4: false,
        };
    });

    const questionData1 = pntQues?.data?.map((item, i) => {
        return {
            id: item.phqid,
            heading: item.phq,
            radioName: 'question' + i,
            score: item.phq_score,
            r1: item.phq_score == 0 ? true : false,
            r2: item.phq_score == 1 ? true : false,
            r3: item.phq_score == 2 ? true : false,
            r4: item.phq_score == 3 ? true : false,
        };
    });

    function getphq8() {
        let payload = {};
        dispatch(getPh8Data(payload));
    }

    function getPntQuestion() {
        dispatch(getPntQuestionnaire(reqId));
    }

    useEffect(() => {
        if (reqId) {
            getPntQuestion();
        } else {
            getphq8();
            getPntQuestion();

        }
    }, [reqId]);

    useEffect(()=>{
        if(defData && reqId){
            const rows = defData.map(item => ({
                field1: item.medicine_name || '',
                field2: item.dosage || ''
            }));
            // Assuming you have a function or state to set the rows data
            setRows(rows);
        }
    }, [defData])

    useEffect(() => {
        if (questionData) {
            setPhq1(questionData);
        }
    }, [ph8Data?.data, errorData]);

    useEffect(() => {
        if (questionData1) {
            setPhq(questionData1);
        }
    }, [pntQues?.data]);

    const changeQuestionOpt = (name, val) => (e) => {
        let checked = e.target.checked;
        const isExisting = hasValue.some((item) => item === val.id);
        const data = phq1 || [];
        let valArr = [];
        if (!isExisting) {
            const updatedData = [...hasValue, val.id];
            setHasValue(updatedData);
        }
        if (name == 'r1') {
            if (checked === true) {
                setPhq1((current) =>
                    current.map((obj) => {
                        if (obj.id === val.id) {
                            return { ...obj, r1: true, r2: false, r3: false, r4: false };
                        }
                        return obj;
                    })
                );
                let updatedData = data?.map((obj) => {
                    if (obj.id === val.id) {
                        return { ...obj, r1: true, r2: false, r3: false, r4: false };
                    }
                    return obj;
                });
                valArr = updatedData;
            } else return null;
        } else if (name == 'r2') {
            if (checked === true) {
                setPhq1((current) =>
                    current.map((obj) => {
                        if (obj.id === val.id) {
                            return { ...obj, r1: false, r2: true, r3: false, r4: false };
                        }
                        return obj;
                    })
                );
                let updatedData = data?.map((obj) => {
                    if (obj.id === val.id) {
                        return { ...obj, r1: false, r2: true, r3: false, r4: false };
                    }
                    return obj;
                });
                valArr = updatedData;
            }
        } else if (name == 'r3') {
            if (checked === true) {
                setPhq1((current) =>
                    current.map((obj) => {
                        if (obj.id === val.id) {
                            return { ...obj, r1: false, r2: false, r3: true, r4: false };
                        }
                        return obj;
                    })
                );
                let updatedData = data?.map((obj) => {
                    if (obj.id === val.id) {
                        return { ...obj, r1: false, r2: false, r3: true, r4: false };
                    }
                    return obj;
                });
                valArr = updatedData;
            }
        } else {
            if (checked === true) {
                setPhq1((current) =>
                    current.map((obj) => {
                        if (obj.id === val.id) {
                            return { ...obj, r1: false, r2: false, r3: false, r4: true };
                        }
                        return obj;
                    })
                );
                let updatedData = data?.map((obj) => {
                    if (obj.id === val.id) {
                        return { ...obj, r1: false, r2: false, r3: false, r4: true };
                    }
                    return obj;
                });
                valArr = updatedData;
            }
        }
        props?.data?.callbackFunc(valArr);
    };

    async function medicName(value) {
        await axios.get(`${baseurl}=${value}`).then((res) => {
            if (res) {
                let arr = [];
                let d = res.data[1];
                for (let i = 0; i < d.length; i++) {
                    arr.push({ value: d[i], label: d[i], id: i });
                }
                setDrugOptions(arr);
                setDosage([]);
            }
        });
    }

    function getDosage(val) {
        axios.get(`${baseurl}=${val}&ef=STRENGTHS_AND_FORMS,RXCUIS`).then((res) => {
            if (res) {
                if (res.data[2].STRENGTHS_AND_FORMS.length > 0) {
                    setDosage(res.data[2].STRENGTHS_AND_FORMS[0]);
                }
            }
        });
    }

    //medication
    const handleAddRow = () => {
        const newRow = { id: nextId, field1: '', field2: '' };
        setRows([...rows, newRow]);
        setNextId(nextId + 1);
    };

    function getPntQuestion() {
        dispatch(getPntQuestionnaire(reqId));
    }

    useEffect(() => {
        // if (reqId) {
        //     getPntQuestion();
        // } else {
        getphq8();
        // }
    }, [reqId]);

    useEffect(() => {
        if (orderInfo?.data?.reqinfo && reqId) {
            let inputJson = {
                patientid: orderData?.patientid,
                servicerequestid: reqId,
            };
            dispatch(requestMedication(inputJson));
        }
    }, [dispatch, orderInfo?.data]);

    const handleDeleteRow = (id) => {
        const updatedRows = rows.filter((row) => row.id != id);
        setRows(updatedRows);
        props?.data?.callbackMedic(updatedRows);
    };

    const handleSearch = (val, id, fieldName) => {
        const updatedRows = rows.map((row) => {
            if (row.id === id) {
                return { ...row, [fieldName]: val };
            }
            return row;
        });
        setDosage([]);
        setRows(updatedRows);
        if (fieldName == 'field1') {
            getDosage(val);
        }
        props?.data?.callbackMedic(updatedRows);
    };

    const dosageOptions = dosage.map(function (dosage) {
        return {
            value: dosage,
            label: dosage,
        };
    });

    const handleInputChange = (e, id, field) => {
        const value = e.target.value;
        const updatedRows = rows.map((row) => (row.id === id ? { ...row, [field]: value } : row));
        setRows(updatedRows);
        props?.data?.callbackMedic(updatedRows);
    };

    const changeQuestionOpt1 = (name, val) => (e) => {
        let checked = e.target.checked;
        const isExisting = hasValue.some((item) => item === val.id);
        const data = phq || [];
        let valArr = [];
        if (!isExisting) {
            const updatedData = [...hasValue, val.id];
            setHasValue(updatedData);
        }
        if (name == 'r1') {
            if (checked === true) {
                setPhq((current) =>
                    current.map((obj) => {
                        if (obj.id === val.id) {
                            return { ...obj, score: 0, r1: true, r2: false, r3: false, r4: false };
                        }
                        return obj;
                    })
                );
                let updatedData = data?.map((obj) => {
                    if (obj.id === val.id) {
                        return { ...obj, score: 0, r1: true, r2: false, r3: false, r4: false };
                    }
                    return obj;
                });
                valArr = updatedData;
            } else return null;
        } else if (name == 'r2') {
            if (checked === true) {
                setPhq((current) =>
                    current.map((obj) => {
                        if (obj.id === val.id) {
                            return { ...obj, score: 1, r1: false, r2: true, r3: false, r4: false };
                        }
                        return obj;
                    })
                );
                let updatedData = data?.map((obj) => {
                    if (obj.id === val.id) {
                        return { ...obj, score: 1, r1: false, r2: true, r3: false, r4: false };
                    }
                    return obj;
                });
                valArr = updatedData;
            }
        } else if (name == 'r3') {
            if (checked === true) {
                setPhq((current) =>
                    current.map((obj) => {
                        if (obj.id === val.id) {
                            return { ...obj, score: 2, r1: false, r2: false, r3: true, r4: false };
                        }
                        return obj;
                    })
                );
                let updatedData = data?.map((obj) => {
                    if (obj.id === val.id) {
                        return { ...obj, score: 2, r1: false, r2: false, r3: true, r4: false };
                    }
                    return obj;
                });
                valArr = updatedData;
            }
        } else {
            if (checked === true) {
                setPhq((current) =>
                    current.map((obj) => {
                        if (obj.id === val.id) {
                            return { ...obj, score: 3, r1: false, r2: false, r3: false, r4: true };
                        }
                        return obj;
                    })
                );
                let updatedData = data?.map((obj) => {
                    if (obj.id === val.id) {
                        return { ...obj, score: 3, r1: false, r2: false, r3: false, r4: true };
                    }
                    return obj;
                });
                valArr = updatedData;
            }
        }
        props?.data?.callbackFunc(valArr);
    };

    return (
        <div>
            <div className="card-body row m-0 ph8">
                <table className={`${validatePhq ? 'border-danger border' : ''}table-bordered phq-tbl`}>
                    <thead>
                        <tr className={`${validatePhq ? 'border-danger border' : 'border-bottom'} bg-light`}>
                            <th className={`${validatePhq ? 'border-danger border' : ''} text-start ms-2 p-1`}>
                                <span className="text-danger">* </span>Over the last 2 weeks, how often have you been bothered by any of the following problems?
                            </th>
                            <th className={`${validatePhq ? 'border-danger border' : ''} ms-2 p-1 rdata`}>Not at all</th>
                            <th className={`${validatePhq ? 'border-danger border' : ''} ms-2 p-1 rdata`}>Several days</th>
                            <th className={`${validatePhq ? 'border-danger border' : ''} ms-2 p-1 rdata`}>More than half the days</th>
                            <th className={`${validatePhq ? 'border-danger border' : ''} ms-2 p-1 rdata`}>Nearly every day</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loadingy ? (
                            <tr>
                                <td colSpan={4}>
                                    <Skeleton active paragraph={4} />
                                </td>
                            </tr>
                        ) : 
                         pntQues?.data  && reqId ? (
                            <>
                                {phq?.map((item, i) => (
                                    <tr key={item.id} className={`${validatePhq ? 'border-danger border' : 'border-bottom'}`}>
                                        <td className={`${validatePhq ? 'border-danger border' : 'border-bottom'} p-2`}>{item.heading}</td>
                                        <td className={`${item.score == 0 ? 'active bg-aliceblue' : ''} ${validatePhq ? 'border-danger border' : ''} text-center`}>
                                            <Checkbox onChange={changeQuestionOpt1('r1', item)} checked={item.score == 0 ? true : false}></Checkbox>
                                        </td>
                                        <td className={`${item.score == 1 ? 'active bg-aliceblue' : ''} ${validatePhq ? 'border-danger border' : ''} text-center`}>
                                            <Checkbox onChange={changeQuestionOpt1('r2', item)} checked={item.score == 1 ? true : false}></Checkbox>
                                        </td>
                                        <td className={`${item.score == 2 ? 'active bg-aliceblue' : ''} ${validatePhq ? 'border-danger border' : ''} text-center`}>
                                            <Checkbox onChange={changeQuestionOpt1('r3', item)} checked={item.score == 2 ? true : false}></Checkbox>
                                        </td>
                                        <td className={`${item.score == 3 ? 'active bg-aliceblue' : ''} text-center`}>
                                            <Checkbox onChange={changeQuestionOpt1('r4', item)} checked={item.score == 3 ? true : false}></Checkbox>
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <>
                                {phq1?.map((item, i) => (
                                    <tr key={item.id} className={`${validatePhq ? 'border-danger border' : 'border-bottom'}`}>
                                        <td className={`${validatePhq ? 'border-danger border' : 'border-bottom'} p-2`}>{item.heading}</td>
                                        <td className={`${item.r1 ? 'active bg-aliceblue' : ''} ${validatePhq ? 'border-danger border' : ''} p-2 text-center`}>
                                            <Checkbox checked={item.r1} onChange={changeQuestionOpt('r1', item)} />
                                        </td>
                                        <td className={`${item.r2 ? 'active bg-aliceblue' : ''} ${validatePhq ? 'border-danger border' : ''} p-2 text-center`}>
                                            <Checkbox checked={item.r2} onChange={changeQuestionOpt('r2', item)} />
                                        </td>
                                        <td className={`${item.r3 ? 'active bg-aliceblue' : ''} ${validatePhq ? 'border-danger border' : ''} p-2 text-center`}>
                                            <Checkbox checked={item.r3} onChange={changeQuestionOpt('r3', item)} />
                                        </td>
                                        <td className={`${item.r4 ? 'active bg-aliceblue' : ''} ${validatePhq ? 'border-danger border' : ''} p-2 text-center`}>
                                            <Checkbox checked={item.r4} onChange={changeQuestionOpt('r4', item)} />
                                        </td>
                                    </tr>
                                ))}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="card-header fs-5 border-bottoms p-0 d-flex ">
                <h5 className="my-auto col heading-title p-2 antd-bg-color mb-1 fs-16 ">Medication Details</h5>
            </div>
            <div className="card-body m-0  p-2 medic-order-tbl">
                <div className="card-body m-0p-2">
                    <h6 className="mb-3 mt-1">Provide all the medication you have taken past 30 days</h6>
                    {
                        (defData?.length > 0 && orderData?.session_is_draft && reqId) || !isError ? (
                            <>
                                <div className="row mx-0 mb-2  p-0 medication-title">
                                    <div className="sub-title col-md-5 me-3 ps-0 med-heading">Medicine Name</div>
                                    <div className="sub-title col-md-5 ps-0 med-heading">Dosage</div>
                                    <div className="col-auto text-center">
                                        {' '}
                                        <PlusSquareOutlined className="text-primary" onClick={handleAddRow} />
                                    </div>
                                </div>
                                {rows.length > 0 ? (
                                    <>
                                        {rows.map((row) => (
                                            <div className="row mx-0 mb-2" key={row.id}>
                                                <div className="col-md-5 me-3 ps-0">
                                                    {props?.data?.isUae == 229 ? (
                                                        <Input className="w-100 mb-1" placeholder="Medicine Name" value={row.field1} onChange={(e) => handleInputChange(e, row.id, 'field1')} />
                                                    ) : (
                                                        <Select
                                                            className="w-100 mb-1"
                                                            showSearch
                                                            notFoundContent={
                                                                <div className="text-center p-4">
                                                                    <SearchOutlined className="pe-2 pb-2" />
                                                                    Search to find medicine
                                                                </div>
                                                            }
                                                            placeholder="Medicine Name"
                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                            onSearch={medicName}
                                                            onClick={() => {
                                                                setDrugOptions([]);
                                                            }}
                                                            value={row.field1}
                                                            defaultValue={row.field1}
                                                            options={drugOptions}
                                                            onSelect={(e) => handleSearch(e, row.id, 'field1')}
                                                        />
                                                    )}
                                                </div>
                                                <div className="col-md-5 ps-0">
                                                    {props?.data?.isUae == 229 ? (
                                                        <Input className="w-100" placeholder="Dosage" value={row.field2} onChange={(e) => handleInputChange(e, row.id, 'field2')} />
                                                    ) : (
                                                        <Select
                                                            className="w-100"
                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                            placeholder="Dosage"
                                                            value={row.field2}
                                                            defaultValue={row.field2}
                                                            notFoundContent={
                                                                <div className="text-center p-4">
                                                                    <SearchOutlined className="pe-2 pb-2" />
                                                                    Select medicine to find dosage
                                                                </div>
                                                            }
                                                            onSelect={(e) => handleSearch(e, row.id, 'field2')}
                                                            onClick={() => {
                                                                setDrugOptions([]);
                                                            }}
                                                            options={dosageOptions}
                                                        />
                                                    )}
                                                </div>
                                                <div className="col-auto text-center">
                                                    <DeleteOutlined className="text-danger" onClick={() => handleDeleteRow(row.id)} />
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div className="bg-light p-4 mt-3 h-100">No medicine associated</div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="row mx-0 mb-2  p-0 medication-title">
                                    <div className="sub-title col-md-5 me-3 ps-0 med-heading">Medicine Name</div>
                                    <div className="sub-title col-md-5 ps-0 med-heading">Dosage</div>
                                    {isError || (reqId && !orderData?.session_is_draft) ? (
                                        ''
                                    ) : (
                                        <div className="col-auto text-center">
                                            {' '}
                                            <PlusSquareOutlined className="text-primary" onClick={handleAddRow} />
                                        </div>
                                    )}
                                </div>
                                {rows.length > 0 ? (
                                    <>
                                        {rows.map((row) => (
                                            <div className="row mx-0 mb-2" key={row.id}>
                                                <div className="col-md-5 me-3 ps-0">
                                                    {props?.data?.isUae == 229 ? (
                                                        <Input className="w-100 mb-1" placeholder="Medicine Name" value={row.field1} onChange={(e) => handleInputChange(e, row.id, 'field1')} />
                                                    ) : (
                                                        <Select
                                                            className="w-100 mb-1"
                                                            showSearch
                                                            notFoundContent={
                                                                <div className="text-center p-4">
                                                                    <SearchOutlined className="pe-2 pb-2" />
                                                                    Search to find medicine
                                                                </div>
                                                            }
                                                            placeholder="Medicine Name"
                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                            value={row.field1}
                                                            defaultValue={row.field1}
                                                            onSearch={medicName}
                                                            options={drugOptions}
                                                            onClick={() => {
                                                                setDrugOptions([]);
                                                            }}
                                                            onSelect={(e) => handleSearch(e, row.id, 'field1')}
                                                        />
                                                    )}
                                                </div>
                                                <div className="col-md-5 ps-0">
                                                    {props?.data?.isUae == 229 ? (
                                                        <Input className="w-100" placeholder="Dosage" value={row.field2} onChange={(e) => handleInputChange(e, row.id, 'field2')} />
                                                    ) : (
                                                        <Select
                                                            className="w-100"
                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                            placeholder="Dosage"
                                                            value={row.field2}
                                                            defaultValue={row.field2}
                                                            notFoundContent={
                                                                <div className="text-center p-4">
                                                                    <SearchOutlined className="pe-2 pb-2" />
                                                                    Select medicine to find dosage
                                                                </div>
                                                            }
                                                            onSelect={(e) => handleSearch(e, row.id, 'field2')}
                                                            options={dosageOptions}
                                                        />
                                                    )}
                                                </div>
                                                <div className="col-auto text-center">
                                                    <DeleteOutlined className="text-danger" onClick={() => handleDeleteRow(row.id)} />
                                                </div>
                                            </div>
                                        ))}{' '}
                                    </>
                                ) : (
                                    ''
                                )}
                                {isError || (rows.length == 0 && reqId && !orderData?.session_is_draft) ? <div className="bg-light p-4 mt-3 h-100">No medicine associated</div> : ''}
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
