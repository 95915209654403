import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const newRequest = createAsyncThunk('newrequest', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/getServicerequest`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const RequestTypes = createAsyncThunk('reqTypes', async () => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/reqtypes`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const AccountTypes = createAsyncThunk('accountTypes', async () => {
    try {
        const { data } = await ax.get(`${backendURL}account/getAccountAll`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const PatientTypes = createAsyncThunk('patientypes', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}patient/getPatientacctview/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const AddPatient = createAsyncThunk('addpatient', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/addpatient`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const SaveRequestService = createAsyncThunk('saverequest', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/saveServicerequest`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
