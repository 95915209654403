import { createSlice } from '@reduxjs/toolkit';
import { GetAdjunctTherapy,GetAssociatedInterpretation,GetNeurofeedback,GetRecordingAnalysis, saveAdjunctTherapy, saveInterpretFindings,
   SaveLifestyle, SaveMedication, saveNeurofeedback, saveRecordingAnalysis, SaveSupplement } from 'store/actions/pipeline-request/wizard-api/RecordingAnalysisAction';

const initialState = {
    //recording analysis
  loading: false,
  recfields: null,
  error: null,
  success: false,
  //interpretation fields,
  loading1: false,
  error1: null,
  intfields: null,
  success1: false,
  //neurofeedback recommendation,
  loading2: false,
  error2: null,
  neurofields: null,
  success2: false,
  //adjunct therapy
  loading3: false,
  error3: null,
  adjfields: null,
  success3: false,
  //lifestyle
  loading4: false,
  error4: null,
  lifeInfo: null,
  success4: false,
  //supplement
  loading5: false,
  error5: null,
  suppInfo: null,
  success5: false,
  //medication
  loading6: false,
  error6: null,
  medicInfo: null,
  success6: false,
  //save analysis
  loading7: false,
  error7: null,
  analysisInfo: null,
  success7: false,
  //save interpretation findings
  loading8: false,
  error8: null,
  intInfo: null,
  success8: false,
  //save neuro feilds
  loading9: false,
  error9: null,
  neuroInfo: null,
  success9: false,
  //save adjunct
  loading10: false,
  error10: null,
  adjInfo: null,
  success10: false,
}

const recAnalysisSlice = createSlice({
  name: 'recording-analysis-details-all',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //associated table details
      .addCase(GetRecordingAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(GetRecordingAnalysis.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.recfields = payload;
        state.success = true;
      })
      .addCase(GetRecordingAnalysis.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.success = false;
      })
      //interpretation fields
      .addCase(GetAssociatedInterpretation.pending, (state) => {
        state.loading1 = true;
        state.error1 = null;
        state.success1 = false;
      })
      .addCase(GetAssociatedInterpretation.fulfilled, (state, { payload }) => {
        state.loading1 = false;
        state.intfields = payload;
        state.success1 = true;
      })
      .addCase(GetAssociatedInterpretation.rejected, (state, { payload }) => {
        state.loading1 = false;
        state.error1 = payload;
        state.success1 = false;
      })
      //document download
      .addCase(GetNeurofeedback.pending, (state) => {
        state.loading2 = true;
        state.error2 = null;
        state.success2 = false;
      })
      .addCase(GetNeurofeedback.fulfilled, (state, { payload }) => {
        state.loading2 = false;
        state.neurofields = payload;
        state.success2 = true;
      })
      .addCase(GetNeurofeedback.rejected, (state, { payload }) => {
        state.loading2 = false;
        state.error2 = payload;
        state.success2 = false;
      })
      //single document details
      .addCase(GetAdjunctTherapy.pending, (state) => {
        state.loading3 = true;
        state.error3 = null;
        state.success3 = false;
      })
      .addCase(GetAdjunctTherapy.fulfilled, (state, { payload }) => {
        state.loading3 = false;
        state.adjfields = payload;
        state.success3 = true;
      })
      .addCase(GetAdjunctTherapy.rejected, (state, { payload }) => {
        state.loading3 = false;
        state.error3 = payload;
        state.success3 = false;
      })
      //lifestyle
      .addCase(SaveLifestyle.pending, (state) => {
        state.loading4 = true;
        state.error4 = null;
        state.success4 = false;
      })
      .addCase(SaveLifestyle.fulfilled, (state, { payload }) => {
        state.loading4 = false;
        state.lifeInfo = payload;
        state.success4 = true;
      })
      .addCase(SaveLifestyle.rejected, (state, { payload }) => {
        state.loading4 = false;
        state.error4 = payload;
        state.success4 = false;
      })
      //supplementation
      .addCase(SaveSupplement.pending, (state) => {
        state.loading5 = true;
        state.error5 = null;
        state.success5 = false;
      })
      .addCase(SaveSupplement.fulfilled, (state, { payload }) => {
        state.loading5 = false;
        state.suppInfo = payload;
        state.success5 = true;
      })
      .addCase(SaveSupplement.rejected, (state, { payload }) => {
        state.loading5 = false;
        state.error5 = payload;
        state.success5 = false;
      })
      //medication
      .addCase(SaveMedication.pending, (state) => {
        state.loading6 = true;
        state.error6 = null;
        state.success6 = false;
      })
      .addCase(SaveMedication.fulfilled, (state, { payload }) => {
        state.loading6= false;
        state.medicInfo = payload;
        state.success6 = true;
      })
      .addCase(SaveMedication.rejected, (state, { payload }) => {
        state.loading6 = false;
        state.error6 = payload;
        state.success6 = false;
      })
      //save recording analysis
       //medication
       .addCase(saveRecordingAnalysis.pending, (state) => {
        state.loading7 = true;
        state.error7 = null;
        state.success7 = false;
      })
      .addCase(saveRecordingAnalysis.fulfilled, (state, { payload }) => {
        state.loading7= false;
        state.analysisInfo = payload;
        state.success7 = true;
      })
      .addCase(saveRecordingAnalysis.rejected, (state, { payload }) => {
        state.loading7 = false;
        state.error7 = payload;
        state.success7 = false;
      })
      //save interpretation
       //medication
       .addCase(saveInterpretFindings.pending, (state) => {
        state.loading8 = true;
        state.error8 = null;
        state.success8 = false;
      })
      .addCase(saveInterpretFindings.fulfilled, (state, { payload }) => {
        state.loading8= false;
        state.intInfo = payload;
        state.success8 = true;
      })
      .addCase(saveInterpretFindings.rejected, (state, { payload }) => {
        state.loading8 = false;
        state.error8 = payload;
        state.success8 = false;
      })
      //save neurofeedback
       //medication
       .addCase(saveNeurofeedback.pending, (state) => {
        state.loading9 = true;
        state.error9 = null;
        state.success9 = false;
      })
      .addCase(saveNeurofeedback.fulfilled, (state, { payload }) => {
        state.loading9= false;
        state.neuroInfo = payload;
        state.success9= true;
      })
      .addCase(saveNeurofeedback.rejected, (state, { payload }) => {
        state.loading9 = false;
        state.error9 = payload;
        state.success9 = false;
      })
      //save adjunct therapy
       //medication
       .addCase(saveAdjunctTherapy.pending, (state) => {
        state.loading10 = true;
        state.error6 = null;
        state.success10 = false;
      })
      .addCase(saveAdjunctTherapy.fulfilled, (state, { payload }) => {
        state.loading10= false;
        state.adjInfo = payload;
        state.success10 = true;
      })
      .addCase(saveAdjunctTherapy.rejected, (state, { payload }) => {
        state.loading10 = false;
        state.error10 = payload;
        state.success10 = false;
      })
  },
})
export default recAnalysisSlice.reducer

