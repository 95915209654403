import { createSlice } from '@reduxjs/toolkit';
import { amazonSearchTable,amazonSearch} from 'store/actions/amazon-search/amazonSearchActions';

const initialState = {
  loading: false,
  reqInfo: null,
  error: null,
  success: false,

  loading1: false,
  reqData: null,
  error1: null,
  success1: false,
}

const amazonSearchSlice = createSlice({
  name: 'amazonsearchtable',
  initialState,
  reducers: {},
  extraReducers:(builder)=> {
    builder
    .addCase(amazonSearchTable.pending,(state)=>{
      state.loading = true;
      state.error=null;
      state.success = false;
    })
    .addCase(amazonSearchTable.fulfilled,(state,{payload})=>{
      state.loading = false;
      state.reqInfo = payload;
      state.success=true;
    })
    .addCase(amazonSearchTable.rejected,(state,{payload})=>{
      state.loading = false;
      state.error = payload;
      state.success=false;
    })

    .addCase(amazonSearch.pending,(state)=>{
      state.loading1 = true;
      state.error1=null;
      state.success1 = false;
    })
    .addCase(amazonSearch.fulfilled,(state,{payload})=>{
      state.loading1 = false;
      state.reqData = payload;
      state.success1=true;
    })
    .addCase(amazonSearch.rejected,(state,{payload})=>{
      state.loading1 = false;
      state.error1 = payload;
      state.success1=false;
    })
    
  },
 
})
export default amazonSearchSlice.reducer

