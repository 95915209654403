import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Tooltip, Progress, moment, Popconfirm, Popover, Tabs } from 'components/elements/AntComponent';
import { DoubleLeftOutlined, FilePdfOutlined, FileImageOutlined, SyncOutlined } from 'components/elements/IconComponent';
import { Skeleton, useSelector, useDispatch, Spin, message, url2 } from 'components/elements/CommonComponent';
import { Image, Button } from 'components/elements/FormComponent';
import { EdfOutput, getEdfJobAutomate } from 'store/actions/pipeline-request/edfProcessActions';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { cancelAllEDFjob, getEdfAnalyzerTopos, resetAllEDFjob } from 'store/actions/jobmanager/jobManagerActions';
import axios from 'axios';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import config from 'assets/config';

function TopographyImages(props) {
    const [outputVal, setOutputVal] = useState();
    const [outputVal1, setOutputVal1] = useState();
    const [outputVal2, setOutputVal2] = useState();
    const [outputVal3, setOutputVal3] = useState();
    const [outputVala, setOutputVala] = useState();
    const [outputVal1a, setOutputVal1a] = useState();
    const [outputVal2a, setOutputVal2a] = useState();
    const [outputVal3a, setOutputVal3a] = useState();
    const [loadingIcon, setLoadingIcon] = useState(false);
    const popdata = props.data.output1?.length === 0 ? '' : props.data?.output1?.split('.')?.pop();
    const popdata1 = props.data.output2?.length === 0 ? '' : props.data?.output2?.split('.')?.pop();
    const popdata2 = props.data.eooutput1?.length === 0 ? '' : props.data?.eooutput1?.split('.')?.pop();
    const popdata3 = props.data.eooutput2?.length === 0 ? '' : props.data?.eooutput2?.split('.')?.pop();

    useEffect(() => {
        if (popdata == 'txt') {
            if (props.data?.output1) {
                setLoadingIcon(true);
                getPngInfo(url2 + props.data?.output1);
            } else {
                setLoadingIcon(false);
            }
        }
    }, [popdata, props.data?.output1]);

    useEffect(() => {
        if (popdata1 == 'txt') {
            if (props.data?.output2) {
                setLoadingIcon(true);
                getPngInfo1(url2 + props.data?.output2);
            } else {
                setLoadingIcon(false);
            }
        }
    }, [popdata1, props.data.output2]);

    useEffect(() => {
        if (popdata2 == 'txt') {
            if (props.data?.eooutput1) {
                setLoadingIcon(true);
                getPngInfo3(url2 + props.data?.eooutput1);
            } else {
                setLoadingIcon(false);
            }
        }
    }, [popdata2, props.data?.eooutput1]);

    useEffect(() => {
        if (popdata3 == 'txt') {
            if (props.data?.eooutput2) {
                setLoadingIcon(true);
                getPngInfo4(url2 + props.data?.eooutput2);
            } else {
                setLoadingIcon(false);
            }
        }
    }, [popdata3, props.data.eooutput2]);
    function getPngInfo(imageUrl) {
        axios
            .get(imageUrl)
            .then((response) => {
                const page1DataValue = response?.data?.page1_data;
                const page2DataValue = response?.data?.page2_data;
                setLoadingIcon(false);
                setOutputVal1(page2DataValue);
                setOutputVal(page1DataValue);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }
    function getPngInfo1(imageUrl) {
        axios
            .get(imageUrl)
            .then((response) => {
                const page1DataValue = response?.data?.page1_data;
                const page2DataValue = response?.data?.page2_data;
                setLoadingIcon(false);
                setOutputVal3(page2DataValue);
                setOutputVal2(page1DataValue);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }
    function getPngInfo3(imageUrl) {
        axios
            .get(imageUrl)
            .then((response) => {
                const page1DataValue = response?.data?.page1_data;
                const page2DataValue = response?.data?.page2_data;
                setLoadingIcon(false);
                setOutputVal1a(page2DataValue);
                setOutputVala(page1DataValue);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }
    function getPngInfo4(imageUrl) {
        axios
            .get(imageUrl)
            .then((response) => {
                const page1DataValue = response?.data?.page1_data;
                const page2DataValue = response?.data?.page2_data;
                setLoadingIcon(false);
                setOutputVal3a(page2DataValue);
                setOutputVal2a(page1DataValue);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    return (
        <div className="topo-height">
            <h6 className="txt-primary fs-17">{props.data.title}</h6>
            <h6 className="mt-3">Eye Closed</h6>
            <div className="bg-aliceblue p-2 d-flex">
                <div className="col-md-6">
                    <p className="fw-bold fs-15">Processed Result</p>
                    <div>
                        {props.data.output2 && props.data.output2?.length === 0 ? (
                            'No data found'
                        ) : (
                            <>
                                {popdata === 'png' || popdata === 'jpg' || popdata === 'jpeg' ? (
                                    <Image src={url2 + props.data.output2} />
                                ) : popdata === 'txt' ? (
                                    <Spin spinning={loadingIcon}>
                                        <Image src={`data:image/png;base64,${outputVal2}`} width="100%" height="auto" />
                                        <Image src={`data:image/png;base64,${outputVal3}`} width="100%" height="auto" />
                                    </Spin>
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="col-md-6">
                    <p className="fw-bold fs-15">Non-Processed Result</p>
                    <div>
                        {props.data.output1 && props.data.output1?.length === 0 ? (
                            'No data found'
                        ) : (
                            <>
                                {popdata === 'png' || popdata === 'jpg' || popdata === 'jpeg' ? (
                                    <Image src={url2 + props.data.output1} />
                                ) : popdata === 'txt' ? (
                                    <Spin spinning={loadingIcon}>
                                        <Image src={`data:image/png;base64,${outputVal}`} width="100%" height="auto" />
                                        <Image src={`data:image/png;base64,${outputVal1}`} width="100%" height="auto" />
                                    </Spin>
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {props.data.id === '3' ? (
                ''
            ) : (
                <>
                    <h6 className="mt-3">Eye Opened</h6>
                    <div className="bg-aliceblue p-2 d-flex">
                        <div className="col-md-6">
                            <p className="fw-bold fs-15">Processed Result</p>
                            <div>
                                {props.data.eooutput2 && props.data.eooutput2?.length === 0 ? (
                                    'No data found'
                                ) : (
                                    <>
                                        {popdata === 'png' || popdata === 'jpg' || popdata === 'jpeg' ? (
                                            <Image src={url2 + props.data.eooutput2} />
                                        ) : popdata === 'txt' ? (
                                            <Spin spinning={loadingIcon}>
                                                {' '}
                                                <Image src={`data:image/png;base64,${outputVal2a}`} width="100%" height="auto" />
                                                <Image src={`data:image/png;base64,${outputVal3a}`} width="100%" height="auto" />
                                            </Spin>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <p className="fw-bold fs-15">Non-Processed Result</p>
                            <div>
                                {props.data.eooutput1 && props.data.eooutput1?.length === 0 ? (
                                    'No data found'
                                ) : (
                                    <>
                                        {popdata === 'png' || popdata === 'jpg' || popdata === 'jpeg' ? (
                                            <Image src={url2 + props.data.eooutput1} />
                                        ) : popdata === 'txt' ? (
                                            <Spin spinning={loadingIcon}>
                                                {' '}
                                                <Image src={`data:image/png;base64,${outputVala}`} width="100%" height="auto" />
                                                <Image src={`data:image/png;base64,${outputVal1a}`} width="100%" height="auto" />
                                            </Spin>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {/* <div className="d-flex">
                {props.data.type1 == 'png' ? (
                    <div className="mt-3 d-flex">
                        <div className="border-end px-3">
                            <h6 className="text-center mb-4">Preprocessed Topography </h6>
                            <Image src={url2 + '/' + props.data.output1} width="100%" height="auto" />
                        </div>
                        <div className="border-end px-3">
                            <h6 className="text-center mb-4">Non-preprocessed Topography</h6>
                            <Image src={url2 + '/' + props.data.output2} width="100%" height="auto" />
                        </div>
                    </div>
                ) : props.data.type1 == 'txt' ? (
                    <div className="d-flex w-100">
                        <div className="px-3">
                            <Spin spinning={loadingIcon}>
                                <h6 className="text-center mb-4">Preprocessed Topography</h6>
                                <Image src={`data:image/png;base64,${outputVal}`} width="100%" height="auto" />
                                <Image src={`data:image/png;base64,${outputVal1}`} width="100%" height="auto" />
                            </Spin>
                        </div>
                        <div className="border-start px-2">
                            <Spin spinning={loadingIcon}>
                                <h6 className="text-center mb-4">Non-preprocessed Topography</h6>
                                <Image src={`data:image/png;base64,${outputVal2}`} width="100%" height="auto" />
                                <Image src={`data:image/png;base64,${outputVal3}`} width="100%" height="auto" />
                            </Spin>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div> */}
        </div>
    );
}
export default function ArtifactToggle() {
    const location = useLocation();
    const history = useNavigate();
    const backendURL = config.apiURL;
    const url1 = backendURL.slice(0, backendURL.lastIndexOf('/'));
    const url2 = url1.slice(0, url1.lastIndexOf('/'));
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [title, setTitle] = useState('');
    const dispatch = useDispatch();
    const [loadingJob, setLoadingJob] = useState(true);
    const { edfDocInfo, edfoutput, loading4, success4, loading7, success7, edfDoclistInfoUpd } = useSelector((state) => state.doclistEdf);
    const { loading8, success8, success6, loading6, edfAnalyzerInfo } = useSelector((state) => state.jobManager);
    const [showMsg, setShowMsg] = useState(false);
    const serviceRequestid = edfDoclistInfoUpd ? edfDoclistInfoUpd.data : [];
    const successMsg = showMsg ? success4 : null;
    const downloadProgress = useSelector((state) => state.download.downloadProgress);
    const [hideprogress, setHideprogress] = useState(false);
    const showprogress = hideprogress ? downloadProgress : null;
    const userRole = sessionStorage.getItem('role');
    const ReqId = location.state.sid;
    const jobManagerReq = location?.state?.reqFrom;
    const edfReq = location?.state?.reqFromEdf;
    const requestFrom = jobManagerReq ? jobManagerReq : edfReq;
    const jobProcessInfo = edfDoclistInfoUpd ? edfDoclistInfoUpd?.Job_info[0] : [];
    const customFormat = (percent) => `${percent}%`;
    const [open2, setOpen2] = useState(false);
    const [eyeCondition, setEyeCondition] = useState('');
    const [topoName, setTopoName] = useState('FFT-Absolute Power');
    const [outputVal, setOutputVal] = useState('');
    const [outputVal1, setOutputVal1] = useState('');
    const [outputType, setOutputType] = useState('');
    const [loadingIcon, setLoadingIcon] = useState(false);
    const [eyeCloseArr, setEyeCloseArr] = useState([]);
    const [eyeOpenArr, setEyeOpenArr] = useState([]);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success8 : null;
    const [resultPath, setResultPath] = useState('');
    const [resultGphName, setResultGphName] = useState('');
    const [showSuccessmsg1, setShowSuccessmsg1] = useState(false);
    const successmsg1 = showSuccessmsg1 ? success6 : null;

    const docs = [
        {
            uri: `${url2}/${resultPath}`,
            fileName: resultGphName,
        },
    ];

    useEffect(() => {
        allJobs();
    }, [dispatch]);

    function allJobs() {
        let inputJson = {
            req_id: ReqId,
        };
        // dispatch(getEdfJob(inputJson))
        dispatch(getEdfJobAutomate(inputJson));
    }

    function getEdfAnalyzers() {
        dispatch(getEdfAnalyzerTopos(ReqId));
    }

    useEffect(() => {
        getEdfAnalyzers();
    }, []);

    const handleCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (edfDoclistInfoUpd?.data?.overall_status != 'complete' && edfDoclistInfoUpd?.data?.overall_status != 'error') {
            const interval = setInterval(() => {
                allJobs();
            }, 8000); // 8 seconds in milliseconds
            return () => clearInterval(interval);
        }
    });

    const callEDF = (stepid, stepname, otype) => {
        let inputJson = {
            job_id: stepname,
            step_id: stepid,
        };
        if (otype == 'pdf') {
            setOpen3(true);
        } else if (otype == 'xlsx') {
            setOpen1(true);
        } else if ((otype == 'png' && stepid == 21) || stepid == 22 || stepid == 23) {
            setOpen2(true);
        } else {
            setOpen(true);
        }
        dispatch(EdfOutput(inputJson));
        setShowMsg(true);
        setHideprogress(true);
    };
    const handleCancel1 = () => {
        setOpen1(false);
    };
    const handleCancel2 = () => {
        setOpen2(false);
    };

    useEffect(() => {
        if (successMsg) {
            if (edfoutput?.step_output?.output_type == 'xlsx' || edfoutput?.step_output?.output_type == 'xls') {
                triggerBase64Download(
                    `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${edfoutput?.step_output?.output}`,
                    `${edfDoclistInfoUpd ? edfDoclistInfoUpd?.data?.RequestNumber : 'Output' && eyeCondition == 'open' ? 'EO' : 'EC'}result_file`
                );
            } else return;
            setShowMsg(false);
        }
    }, [successMsg]);

    useEffect(() => {
        if (success7) {
            setLoadingJob(false);
        }
    }, [success7]);

    useEffect(() => {
        if (showprogress) {
            if (showprogress == 100) {
                setTimeout(() => {
                    setHideprogress(false);
                    setOpen1(false);
                }, 3000);
            }
        }
    }, [showprogress]);

    const handleBack = () => {
        if (edfDoclistInfoUpd?.data?.Request_status === 'Released') {
            history(`/released-request/dataset-information`, {
                state: {
                    reqId: edfDoclistInfoUpd?.data.ServiceRequestID,
                },
            });
        } else {
            history(`/view-request/pipeline-request`, {
                state: {
                    reqId: edfDoclistInfoUpd?.data.ServiceRequestID,
                    request_from: requestFrom,
                },
            });
        }
    };
    const cancelJob = (id) => {
        let inputJson = {
            servicerequestid: id,
        };
        dispatch(cancelAllEDFjob(inputJson));
        setShowSuccessmsg1(true);
    };
    const resetJob = (id) => {
        let inputJson = {
            servicerequestid: id,
        };
        dispatch(resetAllEDFjob(inputJson));
        setShowSuccessmsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            message.success('Job reset has been done successfully');
            allJobs();
            setShowSuccessmsg(false);
        }
    }, [successmsg]);

    useEffect(() => {
        if (successmsg1) {
            message.success('Job cancel has been done successfully');
            handleBackFunc();
            setShowSuccessmsg1(false);
        }
    }, [successmsg1]);

    useEffect(() => {
        if (edfDoclistInfoUpd?.Job_info) {
            if (edfDoclistInfoUpd?.Job_info[0].doc_type == 'Eye Open') {
                setEyeOpenArr(edfDoclistInfoUpd?.Job_info[0]);
                setEyeCloseArr(edfDoclistInfoUpd?.Job_info[1]);
            } else {
                setEyeOpenArr(edfDoclistInfoUpd?.Job_info[1]);
                setEyeCloseArr(edfDoclistInfoUpd?.Job_info[0]);
            }
        }
    }, [edfDoclistInfoUpd?.Job_info]);

    const handleCancel3 = () => {
        setOpen3(false);
    };
    const handleCancel4 = () => {
        setOpen4(false);
        setResultPath('');
    };

    const handleOpenTopography = (item) => {
        setOutputType(item?.output_type);
        if (item.output_type === 'txt') {
            setOutputVal('');
            if (item?.output_file) {
                setLoadingIcon(true);
                getPngInfo(url2 + '/' + item?.output_file);
                setOpen3(true);
            } else {
                setLoadingIcon(false);
            }
        } else {
            setOutputVal(item?.output_file);
            setOpen3(true);
        }
    };

    const handleResultPreview = (item) => {
        setOutputType(item?.output_type);
        if (item?.output_type === 'pdf') {
            setResultPath(item?.output_file);
            setResultGphName(item?.step_name);
            setOpen4(true);
            setLoadingIcon(true);
        }
    };

    function getPngInfo(imageUrl) {
        axios
            .get(imageUrl)
            .then((response) => {
                const page1DataValue = response?.data?.page1_data;
                const page2DataValue = response?.data?.page2_data;
                setLoadingIcon(false);
                setOutputVal1(page2DataValue);
                setOutputVal(page1DataValue);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }
    const handleBackFunc = () => {
        history('/edf_job_manager');
    };
    const jobEO = edfDoclistInfoUpd?.Job_info?.filter((job) => job.doc_type?.toLowerCase() === 'eye open').flatMap((job) => job.processing_steps);
    // const jobEO = edfDoclistInfoUpd?.Job_info?.filter((job) => job.doc_type?.toLowerCase() == 'eye open').map((job) => job.processing_steps);
    const jobEC = edfDoclistInfoUpd?.Job_info?.filter((job) => job.doc_type?.toLowerCase() == 'eye close').flatMap((job) => job.processing_steps);
    const topographySteps = jobEO ? jobEO?.filter((job) => job.heading?.toLowerCase() === 'topography').flatMap((step) => step.steps) : [];
    const topographyStepsEC = jobEC ? jobEC?.filter((job) => job.heading?.toLowerCase() === 'topography').flatMap((step) => step.steps) : [];

    const tabItems = [
        {
            label: 'Absolute Power',
            key: '1',
            children: (
                <TopographyImages
                    data={{
                        title: 'Absolute Power',
                        output1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EC_absolute_power_topo_path || '',
                        type1: '',
                        id: '1',
                        status: '',
                        output2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EC_absolute_power_topo_path,
                        eooutput1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EO_absolute_power_topo_path,
                        eooutput2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EO_absolute_power_topo_path,
                    }}
                />
            ),
        },
        {
            label: 'Relative Power',
            key: '2',
            children: (
                <TopographyImages
                    data={{
                        title: 'Relative Power',
                        output1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EC_relative_power_topo_path || '',
                        type1: '',
                        id: '2',
                        status: '',
                        output2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EC_relative_power_topo_path,
                        eooutput1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EO_relative_power_topo_path,
                        eooutput2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EO_relative_power_topo_path,
                    }}
                />
            ),
        },
        {
            label: 'PDR Topography',
            key: '3',
            children: (
                <TopographyImages
                    data={{
                        title: 'PDR Topography',
                        output1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EC_pdr_topo_path || '',
                        type1: '',
                        id: '3',
                        status: '',
                        output2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EC_pdr_topo_path,
                        eooutput1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EO_pdr_topo_path,
                        eooutput2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EO_pdr_topo_path,
                    }}
                />
            ),
        },
        {
            label: 'FFT Absolute Power',
            key: '4',
            children: (
                <TopographyImages
                    data={{
                        title: 'FFT Absolute Power',
                        output1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EC_FFT_absolute_power_topo_path || '',
                        type1: '',
                        id: '4',
                        status: '',
                        output2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EC_FFT_absolute_power_topo_path,
                        eooutput1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EO_FFT_absolute_power_topo_path,
                        eooutput2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EO_FFT_absolute_power_topo_path,
                    }}
                />
            ),
        },
        {
            label: 'Z-Scored FFT Absolute Power',
            key: '5',
            children: (
                <TopographyImages
                    data={{
                        title: 'Z-Scored FFT Absolute Power',
                        output1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EC_Z_scored_FFT_absolute_power_topo_path || '',
                        type1: '',
                        id: '5',
                        status: '',
                        output2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EC_Z_scored_FFT_absolute_power_topo_path,
                        eooutput1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EO_Z_scored_FFT_absolute_power_topo_path,
                        eooutput2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EO_Z_scored_FFT_absolute_power_topo_path,
                    }}
                />
            ),
        },
        {
            label: 'FFT Relative Power',
            key: '6',
            children: (
                <TopographyImages
                    data={{
                        title: 'FFT Relative Power',
                        output1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EC_FFT_relative_power_topo_path || '',
                        type1: '',
                        id: '6',
                        status: '',
                        output2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EC_FFT_relative_power_topo_path,
                        eooutput1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EO_FFT_relative_power_topo_path,
                        eooutput2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EO_FFT_relative_power_topo_path,
                    }}
                />
            ),
        },
        {
            label: 'Result EDF Graph',
            key: '7',
            children: (
                <TopographyImages
                    data={{
                        title: 'Result EDF Graph',
                        output1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EC_result_graph_path || '',
                        type1: '',
                        id: '7',
                        status: '',
                        output2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EC_result_graph_path,
                        eooutput1: edfAnalyzerInfo?.job_path_info?.edf_analyser_job_info?.EO_result_graph_path,
                        eooutput2: edfAnalyzerInfo?.job_path_info?.edf_job_info?.EO_result_graph_path,
                    }}
                />
            ),
        },
    ];

    const items1 = topographySteps
        ? topographySteps?.map((acc) => {
              const matchingStepEC = topographyStepsEC.filter((val) => val.step_id == acc.step_id).map((val) => val.output_file);
              return {
                  label: acc.step_name,
                  key: acc.step_id,
                  children: (
                      <TopographyImages data={{ title: acc.step_name, output1: acc.output_file, type1: acc.output_type, id: acc.step_id, status: acc.step_status, output2: matchingStepEC ? matchingStepEC[0] : null }} />
                  ),
              };
          })
        : [];
    const items2 = topographyStepsEC
        ? topographyStepsEC?.map((acc) => {
              return {
                  label: acc.step_name,
                  key: acc.step_id,
                  children: 'This page is under development',
              };
          })
        : [];

    return (
        <div className="p-0 text-start edf-top-most-outer">
            <div className="row p-0 edf-top-outer">
                <div className="rounded-0 edf-outer">
                    <div className="d-flex">
                        <h5 className="">EDF Processing Details</h5>
                        <div className="ms-auto mb-1">
                            <Button type="primary" onClick={handleBackFunc}>
                                Back
                            </Button>
                        </div>
                    </div>
                    <div className="tab-pane bg-white fade show active p-2 mb-2" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="bg-light">
                            <div className="row p-2 mx-1 mt-1">
                                <div className="form-group col">
                                    <label className="fw-bold">Job # </label>
                                    <div className="ms-0 text-capitalize">
                                        {userRole === 'researcher' ? (
                                            <a className="text-decoration-none text-dark"> {edfDoclistInfoUpd ? edfDoclistInfoUpd?.Job_info[0]?.job_number : ' '} </a>
                                        ) : (
                                            <a className="text-decoration-none text-dark">{edfDoclistInfoUpd ? edfDoclistInfoUpd?.Job_info[0]?.job_number : ' '}</a>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group col">
                                    <label className="fw-bold">Request No </label>
                                    <div className="ms-1"> {edfDoclistInfoUpd ? edfDoclistInfoUpd?.data?.encoded_RequestNumber : '---'}</div>
                                </div>
                                <div className="form-group col">
                                    <label className="fw-bold">Account Name </label>
                                    <div className="ms-1 text-capitalize">
                                        {userRole === 'researcher' ? (
                                            <a className="text-decoration-none text-dark"> {edfDoclistInfoUpd ? edfDoclistInfoUpd?.data?.account_name : ' '} </a>
                                        ) : (
                                            <a className="text-decoration-none text-dark">{edfDoclistInfoUpd ? edfDoclistInfoUpd?.data?.account_name : ' '}</a>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group col">
                                    <label className="fw-bold">Submitted On </label>
                                    <div className="ms-1">{edfDoclistInfoUpd ? moment(edfDoclistInfoUpd?.Job_info[1]?.submitted_on).format('MM-DD-YYYY') : ''}</div>
                                </div>
                                <div className="form-group col">
                                    <label className="fw-bold">Submitted by</label>
                                    <div className="ms-1"> Axon Solutions</div>
                                </div>
                                <div className="form-group col">
                                    <label className="fw-bold">Overall Status</label>
                                    <div
                                        className={`ms-1 text-capitalize fw-bold ${edfDoclistInfoUpd?.data?.overall_status == 'complete' ? 'text-success' : edfDoclistInfoUpd?.data?.overall_status?.toLowerCase() == 'inprogress' ? 'txt-warning' : edfDoclistInfoUpd?.data?.overall_status?.toLowerCase() == 'error' ? 'text-danger' : 'text-primary'}`}
                                    >
                                        {edfDoclistInfoUpd ? edfDoclistInfoUpd?.data?.overall_status : '---'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 edf-pcontainers">
                        <div className="left-nav-container bg-white p-2">
                            <Tabs defaultActiveKey="1" tabPosition="left" items={tabItems} />
                        </div>
                    </div>
                </div>
            </div>

            {/* before after image comparison of edf */}
            <Modal title={title} open={open} onCancel={handleCancel} width={1300}>
                <div className="d-flex output-modal">
                    {loading4 ? (
                        <>
                            <Skeleton.Image active className="col p-2 w-100 h-100" />{' '}
                        </>
                    ) : (
                        <>
                            {edfoutput?.step_output?.output_file ? (
                                <Image className="col" src={`${url2}${edfoutput?.step_output?.output_file}`} />
                            ) : (
                                <Image className="col" src={`data:image/png;base64,${edfoutput?.step_output?.output}`} />
                            )}
                        </>
                    )}
                </div>
            </Modal>

            {/* topography starts */}
            <Modal
                title="PDR Topography"
                open={open2}
                width={1000}
                onCancel={handleCancel2}
                footer={[
                    <Button type="primary" className="bg-danger text-white" onClick={handleCancel2}>
                        Close
                    </Button>,
                ]}
            >
                <div className="d-flex output-modal border-top">
                    {loading4 ? (
                        <>
                            <Skeleton.Image active className="col p-2 w-100 h-100" />{' '}
                        </>
                    ) : (
                        <>
                            <Image className="col " src={`data:image/png;base64,${edfoutput?.step_output?.output}`} />
                        </>
                    )}
                </div>
            </Modal>

            <Modal
                title={topoName}
                open={open3}
                width={1000}
                onCancel={handleCancel3}
                footer={[
                    <Button key="closespdf" type="primary" className="bg-danger text-white" onClick={handleCancel3}>
                        Close
                    </Button>,
                ]}
            >
                <div style={{ height: '550px', overflow: 'auto' }} className="row">
                    {hideprogress ? (
                        <Progress
                            type="circle"
                            className="text-center mx-auto my-auto"
                            percent={`${showprogress}`}
                            size={200}
                            strokeWidth={4}
                            strokeColor={{
                                '0%': '#1F98DF',
                                '100%': '#87d068',
                            }}
                            format={customFormat}
                        />
                    ) : outputType == 'png' ? (
                        <Image src={url2 + '/' + outputVal} />
                    ) : (
                        <>
                            {loadingIcon ? (
                                <Spin />
                            ) : (
                                <>
                                    <Image src={`data:image/png;base64,${outputVal}`} /> <Image src={`data:image/png;base64,${outputVal1}`} />
                                </>
                            )}
                        </>
                    )}
                </div>
            </Modal>

            {/* topography ends */}
            <Modal
                title={title}
                open={open1}
                onCancel={handleCancel1}
                height={200}
                footer={[
                    <Button key="closepdf" type="primary" className="bg-danger text-white" onClick={handleCancel1}>
                        Close
                    </Button>,
                ]}
            >
                <div className=" output-modal text-center my-auto">
                    <p className="text-center mt-5 mb-3">Downloading File...</p>

                    {hideprogress ? (
                        <Progress
                            type="circle"
                            className="text-center mx-auto my-auto"
                            percent={`${showprogress}`}
                            size={200}
                            strokeWidth={4}
                            strokeColor={{
                                '0%': '#1F98DF',
                                '100%': '#87d068',
                            }}
                            format={customFormat}
                        />
                    ) : (
                        ''
                    )}
                </div>
            </Modal>

            {/* result preview */}
            <Modal
                title="Report Preview"
                open={open4}
                onCancel={handleCancel4}
                width={700}
                footer={[
                    <Button key="closepdf" type="primary" className="bg-danger text-white" onClick={handleCancel4}>
                        Close
                    </Button>,
                ]}
            >
                <div className=" output-modal text-center my-auto">
                    <DocViewer
                        documents={docs}
                        initialActiveDocument={docs[1]}
                        pluginRenderers={DocViewerRenderers}
                        theme={{
                            primary: '#5a53b2',
                            secondary: '#ffffff',
                            tertiary: 'rgb(102 96 176 / 40%)',
                            textPrimary: '#ffffff',
                            textSecondary: '#5a53b2',
                            textTertiary: '#ffffff',
                            disableThemeScrollbar: true,
                        }}
                    />
                </div>
            </Modal>
        </div>
    );
}
