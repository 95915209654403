import React, { useState } from 'react';
import UserDetail from './UserDetails';
import ServiceRef from './ServiceRef';
import { Tabs } from 'components/elements/AntComponent';
import { UserIcon } from 'assets/images/custom-icons/customIcons';

export default function Security() {
    const [activeTab, setActiveTab] = useState('1');

    const items1 = [
        {
            key: '1',
            label: <span className="icon-job"><span className='pb-4'>User Account Management</span></span>,
            children: <UserDetail data={{ activeTab: activeTab }} />,
        },
        {
            key: '2',
            label: (
                <span className="icon-err-job d-flex my-auto">
                    <span className="mt-auto mb-0 pb-1">Service Reference</span>
                </span>
            ),
            children: <ServiceRef data={{ activeTab: activeTab }} />,
        },
    ];

    return (
        <div className="pe-3">
            <div className="request-title mb-2 me-0">
                <h5>Access and Permission Management</h5>
                <div className="bg-white p-3 h-100 edf-pcontainers">
                    <Tabs onChange={(e) => setActiveTab(e)} defaultActiveKey={activeTab} tabBarGutter={5} items={items1} />
                </div>
            </div>
        </div>
    );
}
