import { createSlice } from '@reduxjs/toolkit';
import { AccountTypes, AddPatient, newRequest, PatientTypes, RequestTypes, SaveRequestService } from 'store/actions/service-request/newRequestActions';

const initialState = {
    loading: false,
    reqInfo: null,
    error: null,
    success: false,
    //getreqtypes
    loading1: false,
    reqType: null,
    error1: null,
    success1: false,
    //getaccounttypes,
    loading2: false,
    accType: null,
    error2: null,
    success2: false,
    //addpatient,
    loading3: false,
    addpatient: null,
    error3: null,
    success3: false,
    //patientype
    loading4: false,
    pntType: null,
    error4: null,
    success4: false,
    //save request
    loading5: false,
    saveReq: null,
    error5: null,
    success5: null,
};

const newReqSlice = createSlice({
    name: 'newrequest',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(newRequest.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(newRequest.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.reqInfo = payload;
                state.success = true;
            })
            .addCase(newRequest.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })

            // register user reducer...
            .addCase(RequestTypes.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(RequestTypes.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.reqType = payload;
                state.success1 = true;
            })
            .addCase(RequestTypes.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.error1 = payload;
                state.success1 = false;
            })

            //acctype
            .addCase(AccountTypes.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(AccountTypes.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.accType = payload;
                state.success2 = true;
            })
            .addCase(AccountTypes.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.error2 = payload;
                state.success2 = false;
            })

            //patientype
            .addCase(PatientTypes.pending, (state) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(PatientTypes.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.pntType = payload;
                state.success4 = true;
            })
            .addCase(PatientTypes.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            })

            //addpatient
            .addCase(AddPatient.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(AddPatient.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.addpatient = payload;
                state.success3 = true;
            })
            .addCase(AddPatient.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })

            //add service request
            .addCase(SaveRequestService.pending, (state) => {
                state.loading5 = true;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(SaveRequestService.fulfilled, (state, { payload }) => {
                state.loading5 = false;
                state.saveReq = payload;
                state.success5 = true;
            })
            .addCase(SaveRequestService.rejected, (state, { payload }) => {
                state.loading5 = false;
                state.error5 = payload;
                state.success5 = false;
            });
    },
});
export default newReqSlice.reducer;
