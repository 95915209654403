import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const listPatient = createAsyncThunk('listpatient', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/getPatient`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// patient/addpatient
export const addPatient = createAsyncThunk('addpatient', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/addpatient`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// patient/listpatientmedication/5
export const patientMedication = createAsyncThunk('patientMedication', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}patient/listpatientmedication/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const deletePatient = createAsyncThunk('deletepatient', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/deletepatient`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// patient/add-medication
export const addMedication = createAsyncThunk('addMedication', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/add-medication`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//patient/getmedication/201
export const getMedication = createAsyncThunk('updateMedication', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}patient/getmedication/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

// patient/delMedication/1
export const deletePatientMedication = createAsyncThunk('deletepatientmedication', async (id) => {
    try {
        const { data } = await ax.delete(`${backendURL}patient/delMedication/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

// patient/getPatient/76
export const getPatient = createAsyncThunk('updatePatient', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}patient/getPatient/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

// patient/savePatientTags
export const patientSaveTags = createAsyncThunk('saveTags', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/savePatientTags`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// patient/getPatientTag/1
export const getPatientTag = createAsyncThunk('getTagPatient', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}patient/getPatientTag/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

// patient/remove-pnttag/2
export const removePntTag = createAsyncThunk('remove pnt tag', async (id) => {
    try {
        const { data } = await ax.delete(`${backendURL}patient/remove-pnttag/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});
//patient export patient/pntxl-export
export const patientDataExport = createAsyncThunk('patient-export', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/reqxl-export`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// patient/patientreqinfo/22
export const patientRequestInfo = createAsyncThunk('patientRequestInfo', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}patient/patientreqinfo/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});
// datahub_report/Prepost_report
export const getComparisonReport = createAsyncThunk('get-comp-report', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}datahub_report/Prepost_report`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// datahub_report/update_Prepost_report
export const getReportComparison = createAsyncThunk('update_Prepost_report', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}datahub_report/update_Prepost_report`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
