import { createSlice } from '@reduxjs/toolkit';
import { getNotification, menuService } from 'store/actions/menuServiceAction';

const initialState = {
    loading: false,
    menu: null,
    error: null,
    success: false,
    //notification
    loading1: false,
    notificationInfo: null,
    error1: null,
    success1: false,
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(menuService.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(menuService.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.menu = payload;
                state.success = true;
            })
            .addCase(menuService.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            .addCase(getNotification.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
                state.notificationInfo = null;
            })
            .addCase(getNotification.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.notificationInfo = payload;
                state.success1 = true;
            })
            .addCase(getNotification.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.error1 = payload;
                state.success1 = false;
            });
    },
});

export default menuSlice.reducer;
