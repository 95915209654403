import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';
import { setDownloadProgress } from 'store/slices/downloadFile';

const backendURL = config.apiURL;

//automate/get_edf_job
export const getEdfJob = createAsyncThunk('getedfjob', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}automate/get_edf_job_update`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getEdfJobAutomate = createAsyncThunk('getedfjobautomate', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}automate/get_edf_job_update_new`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//docrepo/doclist_edf/655
export const edfDocList = createAsyncThunk('doclistEdfprocess', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}docrepo/doclist_edf/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

//docrepo/get_jobinfo (servicerequestid )
export const getEdfJobInfo = createAsyncThunk('getedfjobinfo', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/get_jobinfo`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getEdfJobId = createAsyncThunk('getedfjobs', async (docid) => {
    try {
        const { data } = await ax.get(`${backendURL}automate/get_edf_job_update/${docid}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const addEdfJob = createAsyncThunk('addedfjobs', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}automate/add_edf_job`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// automate/get_step_out
let progress = 0;
export const EdfOutput = createAsyncThunk('addedfjob', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}automate/get_step_out`, payload, {
            onDownloadProgress: (progressEvent) => {
                progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                dispatch(setDownloadProgress(progress));
            },
        });
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// get result document, docrepo/automate_ratiodoclist/1258
export const getResultDocList = createAsyncThunk('result-docu-list', async (docid) => {
    try {
        const { data } = await ax.get(`${backendURL}docrepo/automate_ratiodoclist/${docid}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});
