import React, { useState } from 'react';
import EEGImage from 'assets/images/brandname.svg';
import { Input, Dropdown, Image, Button } from 'components/elements/FormComponent';
import { SearchOutlined, CaretDownOutlined } from 'components/elements/IconComponent';
import { Menu, Typography, Space } from 'components/elements/AntComponent';
import DoctorIcon from 'assets/images/doctor.png';
import Img1 from 'assets/images/psychedelic-images/medic1.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingIcon } from 'assets/images/custom-icons/LoadingOutlined';

const { Text } = Typography;
export default function ViewSite() {
    const navigate = useNavigate();
    const location = useLocation();
    const [current, setCurrent] = useState('mail');
    const [loading, setLoading] = useState(true);

    const handleLoad = () => {
        setLoading(false);
    };
    const onClick = (e) => {
        setCurrent(e.key);
    };
    const items = [
        {
            label: 'Change Password',
            key: '1',
        },
        {
            label: 'Logout',
            key: '2',
        },
    ];
    const items1 = [
        {
            label: 'Home',
            key: 'mail',
        },
        {
            label: 'Favorites',
            key: 'app',
        },
        {
            label: 'Bookmarks',
            key: 'SubMenu',
            children: [
                {
                    type: 'group',
                    label: 'Item 1',
                    children: [
                        {
                            label: 'Option 1',
                            key: 'setting:1',
                        },
                        {
                            label: 'Option 2',
                            key: 'setting:2',
                        },
                    ],
                },
                {
                    type: 'group',
                    label: 'Item 2',
                    children: [
                        {
                            label: 'Option 3',
                            key: 'setting:3',
                        },
                        {
                            label: 'Option 4',
                            key: 'setting:4',
                        },
                    ],
                },
            ],
        },
    ];

    const handleMenuClick = (e) => {
        if (e.key === '2') {
            // showModal();
        }
        if (e.key == '1') {
            // setPassModal(true);
        }
    };
    const medicUrl = 'https://www.hopkinsmedicine.org/news/newsroom/';
    const gotoPage = () => {
        navigate('/psychedelic-search');
    };

    const targetUrl = 'https://home.liebertpub.com/publications/psychedelic-medicine/673';

    return (
        <div>
            <div className="topbar bg-white py-2 px-4 shadow-sm d-flex">
                <img className="logo" src={EEGImage} alt="EEG Logo" height="40px" />
                <div className="ms-auto my-auto col-auto justify-content-between d-flex">
                    <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" className="psychedelic-menu" items={items1} />
                    <Input prefix={<SearchOutlined />} placeholder="Search topics" className="my-auto " />
                    <div className="col-md-4">
                        <div className="d-flex w-100 ">
                            <div className="my-auto user-name col">
                                <div className="antrow  row mx-0 my-auto">
                                    <b className="text-capitalize text-end pe-1 fs-15">{localStorage.getItem('firstname') + ' ' + localStorage.getItem('lastname')}</b>
                                </div>
                                <div className="antrow my-auto text-end">
                                    <Text className="text-uppercase text-secondary fw-500 fs-12 pe-1">
                                        <span className="text-lowercase text-secondary fw-500 pe-1 fs-12">pavithramarie.p@kosoft.co</span>
                                    </Text>
                                </div>
                            </div>

                            <div className="col-md-auto">
                                <div className="avatar mx-auto d-flex ">
                                    <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={['click']} className="col-auto ms-1 my-auto">
                                        <Space>
                                            <img src={DoctorIcon} height="37px" className="rounded-circle shadow-sm border border-primary mt-1 ms-2" alt="user icon" />
                                            {/* {showResearch ? (
                                                <img src={ResearchIcon} height="37px" className="rounded-circle shadow-sm border border-primary mt-1 ant-btn-primary" alt="user icon" />
                                            ) : (
                                                <img src={DoctorIcon} height="37px" className="rounded-circle shadow-sm border border-primary mt-1" alt="user icon" />
                                            )} */}
                                            {/* <Avatar className="anticonuser text-center col-auto h-75 my-auto mt-1" size={36} icon={<UserOutlined />} /> */}
                                            <CaretDownOutlined />
                                        </Space>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-2 py-4 bg-srch">
                <div className="bg-white m-0 mt-2 p-2">
                    <div className="d-flex mb-3">
                        <h4 className="ps-3">{location.state.data.title}</h4>
                        <h6 onClick={gotoPage} className="text-primary text-decoration-underline ms-auto my-auto pointer">
                            Back
                        </h6>
                    </div>
                    <div className=" ps-3 d-flex mb-4">
                        <div className="col-md-2">
                            <Image src={location.state.data.icon} />
                        </div>
                        <div className="col px-3" style={{ textAlign: 'justify' }}>
                            <p>{location.state.data.description}</p>
                        </div>
                    </div>
                    {loading && (
                        <div className="p-4 mt-5 d-flex justify-content-center align-items-center h-75">
                            <div className="loading-icon">
                                <LoadingIcon />
                            </div>
                        </div>
                    )}
                    <div className="px-3" style={{ width: '100%', height: '100vh' }}>
                        <iframe src={targetUrl} title="Google Analytics of Leespring M&S spring" height="880px" width="100%" className="" onLoad={handleLoad}></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}
