import { createSlice } from '@reduxjs/toolkit';
import { diagnosisMaster, addDiagnosis, deleteDiagnosis } from 'store/actions/master-data/DiagnosisActions';
import { addMedTemp, deleteMedTemp, getMastrMedic, getMedTempMaster } from 'store/actions/master-data/MedicationTempAction';

const initialState = {
  loading: false,
  medTempInfo: null,
  error: null,
  success: false,
  //adddiagnosis
  loading1: false,
  addMedTempInfo: null,
  error1: null,
  success1: false,
  //removediagnosis
  loading2: false,
  rmvMedTemp: null,
  error2: null,
  success2: false,
  //get temp
  loading3: false,
  getMedic: null,
  error3: null,
  success3: false,
  //del temp
  loading4: false,
  delTemp: null,
  error4: null,
  success4: false
}

const medicTempSlice = createSlice({
  name: 'medictempslice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get medic template
      .addCase(getMedTempMaster.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getMedTempMaster.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.medTempInfo = payload;
        state.success = true;
      })
      .addCase(getMedTempMaster.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.success = false;
      })
      //add medic template
      .addCase(addMedTemp.pending, (state) => {
        state.loading1 = true;
        state.error1 = null;
        state.success1 = false;
      })
      .addCase(addMedTemp.fulfilled, (state, { payload }) => {
        state.loading1 = false
        state.addMedTempInfo = payload
        state.success1 = true;
      })
      .addCase(addMedTemp.rejected, (state, { payload }) => {
        state.loading1 = false
        state.error1 = payload
        state.success1 = false;
      })
      //delete medic template
      .addCase(getMastrMedic.pending, (state) => {
        state.loading3 = true;
        state.error3 = null;
        state.success3 = false;
      })
      .addCase(getMastrMedic.fulfilled, (state, { payload }) => {
        state.loading3 = false;
        state.getMedic = payload;
        state.success3 = true;
      })
      .addCase(getMastrMedic.rejected, (state, { payload }) => {
        state.loading3 = false;
        state.error3 = payload;
        state.success3 = false;
      })
      //remove medic template
      .addCase(deleteMedTemp.pending, (state) => {
        state.loading4 = true;
        state.error4 = null;
        state.success4 = false;
      })
      .addCase(deleteMedTemp.fulfilled, (state, { payload }) => {
        state.loading4 = false;
        state.delTemp = payload;
        state.success4 = true;
      })
      .addCase(deleteMedTemp.rejected, (state, { payload }) => {
        state.loading4 = false;
        state.error4 = payload;
        state.success4 = false;
      })
  },
})
export default medicTempSlice.reducer

