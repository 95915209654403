import React from 'react';
import Lottie from 'lottie-react';
import { Skeleton,Spin } from 'components/elements/CommonComponent';
import LoadingElem from 'assets/images/animdot.json';


export default function LoadingComponent(){
    return(
        <div className='steps-content bg-white 80vh d-flex justify-content-center align-items-center'>
            <Spin spinning={false}>
                <div className='bg-white p-5 80vh'><Lottie animationData={LoadingElem} style={{width:'150px'}}/></div>
            </Spin>
        </div>
    )
}