import { createSlice } from '@reduxjs/toolkit';
import { getWizardSteps,saveWizardSteps, patientCard, saveReportCmd } from 'store/actions/pipeline-request/wizardAction';

const initialState = {
  loadinga: false,
  wizardsteps: null,
  error: null,
  success: false,
  //p-card
  loadings: false,
  pcardInfo: null,
  error1: null,
  success1: false,
  //save wizard steps
  loading2: false,
  saveWizard: null,
  error2: null,
  success2: false,
  //save report commands
  loading3: false,
  saveCmd: null,
  error3: null,
  success3: false,
}

const wizardSteps = createSlice({

  name: 'wizardstps',
  initialState,
  reducers: {},
  extraReducers:(builder)=> {
    builder
    //get wizard steps
    .addCase(getWizardSteps.pending,(state)=>{
      state.loadinga = true;
      state.error=null;
      state.success = false;
    })
    .addCase(getWizardSteps.fulfilled,(state,{payload})=>{
      state.loadinga = false;
      state.wizardsteps = payload;
      state.success=true;
    })
    .addCase(getWizardSteps.rejected,(state,{payload})=>{
      state.loadinga = false;
      state.error = payload;
      state.success=false;
    })

    //p-card
    .addCase(patientCard.pending,(state)=>{
      state.loadings = true
      state.error1 = null;
      state.success1 = false;
    })
    .addCase(patientCard.fulfilled,(state,{payload})=>{
      state.loadings = false;
      state.pcardInfo = payload;
      state.success1 = true;
    })
    .addCase(patientCard.rejected,(state,{payload})=>{
      state.loadings = false;
      state.error1 = payload;
      state.success1 = false;
    })

     //get wizard steps
    .addCase(saveWizardSteps.pending,(state)=>{
      state.loading2 = true;
      state.error2=null;
      state.success2 = false;
    })
    .addCase(saveWizardSteps.fulfilled,(state,{payload})=>{
      state.loading2 = false;
      state.saveWizard = payload;
      state.success2=true;
    })
    .addCase(saveWizardSteps.rejected,(state,{payload})=>{
      state.loading2 = false;
      state.error2 = payload;
      state.success2=false;
    })

    //save report commands
    .addCase(saveReportCmd.pending,(state)=>{
      state.loading3 = true;
      state.error3=null;
      state.success3 = false;
    })
    .addCase(saveReportCmd.fulfilled,(state,{payload})=>{
      state.loading3 = false;
      state.saveCmd = payload;
      state.success3=true;
    })
    .addCase(saveReportCmd.rejected,(state,{payload})=>{
      state.loading3 = false;
      state.error3 = payload;
      state.success3=false;
    })

  },

})
export default wizardSteps.reducer

