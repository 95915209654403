import axios from 'axios';
import config from '../assets/config';

var base64 = require('base-64');
var utf8 = require('utf8');

export const ax = axios.create({
    baseURL: config.apiURL,
});

// Add a request interceptor
ax.interceptors.request.use(
    (config) => {
        let user = sessionStorage.getItem('username');
        let pass = sessionStorage.getItem('password');
        var text = user + ':' + pass;
        var bytes = utf8.encode(text);
        const token = base64.encode(bytes);
        const formDataEndpoints = [
            'md_management/add_templatemedication',
            'account/saveAccount',
            'patient/requestmedication',
            'docrepo/consentdocupload',
            'docrepo/resultupload',
            'docrepo/docupload',
            'service_request/accountsaveServicerequest',
            'service_request/adminsaveServicerequest',
        ];
        if (!formDataEndpoints.some((endpoint) => config.url.includes(endpoint))) {
            if (
                !config.url.includes('auth/login') &&
                !config.url.includes('auth/password_reset') &&
                !config.url.includes('auth/reset-password/verify-token') &&
                !config.url.includes('auth/password_reset/confirm/') &&
                !config.url.includes('account/getpublicaccount') &&
                !config.url.includes('patient/addpublicpatient') &&
                !config.url.includes('patient/publicrequest_intakeform') &&
                !config.url.includes('md_management/publicphq_mdata') &&
                !config.url.includes('account/getcountrystates')
            ) {
                if (token) {
                    config.headers['Authorization'] = 'Basic ' + token;
                    config.headers['Content-Type'] = 'application/json';
                    config.headers['Accept'] = 'application/json';
                }
            } else {
                if (config.url.includes('patient/publicrequest_intakeform')) {
                    config.headers['Content-Type'] = 'multipart/form-data';
                } else {
                    config.headers['Content-Type'] = 'application/json';
                }
                config.headers['Accept'] = 'application/json';
            }
        } else {
            if (token) {
                config.headers['Authorization'] = 'Basic ' + token;
            }
            // Only set Content-Type to multipart/form-data if the request data is FormData
            if (config.data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            } else {
                config.headers['Content-Type'] = 'application/json';
            }
            config.headers['Accept'] = 'application/json';
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);
