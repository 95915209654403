import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AppLayout from 'components/layout/Layout';
import LoadingComponent from 'components/LoadingComponent';
import { ErrorBoundary } from 'react-error-boundary';
import ReportComparison from 'components/pages/ReportComparison';
import OopsPage from 'components/pages/OopsPage';
import CompareTopography from 'components/pages/CompareTopography';
import { useSelector } from 'react-redux';
import UnAuthorizedPage from 'components/pages/UnAuthorizedPage';
import ArtifactToggle from 'components/jobmanager/ArtifactToggle';
import Security from 'components/security/Security';
import NewServiceRequest from 'components/service-request/NewServiceRequest';
import MedicineAutomation from 'components/research/MedicineAutomation';
import ResearchLayout from 'components/layout/ResearchLayout';
import PsychedelicSearch from 'components/psychedelic/PsychedelicSearch';
import ViewSite from 'components/psychedelic/ViewSite';
import CustomerAgreement from 'components/pages/CustomerAgreement';

const OrderManagement = lazy(() => import('components/order-management/OrderManagement'));
const Dashboard = lazy(() => import('components/Dashboard'));
const AccountManagement = lazy(() => import('components/account/AccountManagement'));
const AccountDetails = lazy(() => import('components/account/AccountDetails'));
const PatientMedication = lazy(() => import('components/patient/PatientMedication'));
const NewRequest = lazy(() => import('components/service-request/NewRequest'));
const PipelineRequest = lazy(() => import('components/pipeline-request/PipelineRequest'));
const StepWizard = lazy(() => import('components/pipeline-request/wizard-steps/StepWizard'));
const ReleasedRequest = lazy(() => import('components/released-request/ReleasedRequest'));
const DatasetInformation1 = lazy(() => import('components/released-request/DatasetInformation1'));
const Diagnosis = lazy(() => import('components/master-data/Diagnosis'));
const Symptoms = lazy(() => import('components/master-data/Symptoms'));
const LifeStyle = lazy(() => import('components/master-data/Lifestyle'));
const Supplement = lazy(() => import('components/master-data/Supplement'));
const Marker = lazy(() => import('components/master-data/Marker'));
const MedicationTemplate = lazy(() => import('components/master-data/MedicationTemplate'));
const Interpretation = lazy(() => import('components/master-data/Interpretation'));
const JobManager = lazy(() => import('components/jobmanager/JobManager'));
const JobEdfProcess = lazy(() => import('components/jobmanager/JobEdfProcess'));
const Settings = lazy(() => import('components/settings/Settings'));
const Search = lazy(() => import('components/search/Search'));
const AmazonSearch = lazy(() => import('components/search/AmazonSearch'));
const ArchivedRequest = lazy(() => import('components/ArchivedRequest'));
const Phq8 = lazy(() => import('components/master-data/Phq8'));
const ListOfPatient = lazy(() => import('components/patient/ListOfPatient'));
const CreditManager = lazy(() => import('components/billing/CreditManager'));
const CreditSettings = lazy(() => import('components/billing/CreditSettings'));
const MyCredit = lazy(() => import('components/billing/account/MyCredit'));
const BillingReport = lazy(() => import('components/billing/BillingReport'));
const BuyCredit = lazy(() => import('components/billing/account/BuyCredit'));
const AccCreditSettings = lazy(() => import('components/settings/AccCreditSettings'));
const PaymentDetails = lazy(() => import('components/billing/account/PaymentDetail'));
const SuccessPayment = lazy(() => import('components/billing/account/modals/SuccessPayment'));
const ErrorPayment = lazy(() => import('components/billing/account/modals/ErrorPayment'));
const InvoiceSuccessPay = lazy(() => import('components/billing/account/modals/InvoiceSuccessPay'));
const InvoiceErrorPay = lazy(() => import('components/billing/account/modals/InvoiceErrorPay'));
const InvoiceManager = lazy(() => import('components/billing/InvoiceManager'));
const Invoice = lazy(() => import('components/billing/Invoice'));
const Invoice1 = lazy(() => import('components/billing/Invoice1'));
const GenerateInvoice = lazy(() => import('components/billing/GenerateInvoice'));
const ReportRate = lazy(() => import('components/order-management/ReportRate'));
const MedicationRec = lazy(() => import('components/master-data/MedResearch'));
const Amplifier = lazy(() => import('components/master-data/Amplifier'));

const extractMenuUrls = (menuData) => {
    const urls = [].concat([
        'search',
        'security',
        'report-comparison',
        'order-management',
        'generate-invoice',
        'invoice1',
        'report-rate',
        'invsuccess-payment',
        'inverror-payment',
        'error-payment',
        'customer-agreement',
        'success-payment',
        'payment-detail',
        'buy-credit',
        'medicine-automation',
        'supplement-automation',
        'lifestyle-automation',
        'research/recommended-medication',
        'amplifier-master'
    ]);

    if (Array.isArray(menuData)) {
        menuData.forEach((item) => {
            if (item.menu_url) urls.push(item.menu_url);
            if (item.submenuservice && Array.isArray(item.submenuservice)) {
                item.submenuservice.forEach((subItem) => {
                    if (subItem.menu_path) urls.push(subItem.menu_path);
                });
            }
        });
    }
    return urls.filter((url) => url);
};

const normalizePath = (path) => {
    return path.replace(/^\//, '').toLowerCase().split(/[?#]/)[0];
};

const isPathAllowed = (currentPath, allowedPaths) => {
    const normalizedCurrentPath = normalizePath(currentPath);
    return allowedPaths.some((path) => {
        const normalizedAllowedPath = normalizePath(path);
        return normalizedCurrentPath === normalizedAllowedPath || normalizedCurrentPath.startsWith(`${normalizedAllowedPath}/`);
    });
};

export default function PrivateRoutes() {
    const location = useLocation();
    const navigate = useNavigate();
    const { menu, loading } = useSelector((state) => state.menu);
    const [allowedPaths, setAllowedPaths] = useState([]);
    const [isAuth, setIsAuth] = useState(null);
    const edit = false;

    useEffect(() => {
        if (!loading && menu?.data) {
            const paths = extractMenuUrls(menu.data);
            setAllowedPaths(paths);
        }
    }, [menu, loading]);

    useEffect(() => {
        setIsAuth(null);
        if (loading || allowedPaths.length === 0) {
            return;
        }

        const currentPath = normalizePath(location.pathname);
        const pathIsAllowed = isPathAllowed(currentPath, allowedPaths);
        setIsAuth(pathIsAllowed);

        if (!pathIsAllowed) {
            navigate('/unauthorized');
        }
    }, [location.pathname, allowedPaths, loading, navigate]);

    return (
        <div>
            {location.pathname !== '/unauthorized' && (
                <ErrorBoundary FallbackComponent={OopsPage}>
                    <AppLayout>
                        <Routes>
                            <Route
                                path="/dashboard"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Dashboard />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/security"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Security />
                                    </Suspense>
                                }
                            />
                            {/* account management */}
                            <Route
                                path="/account/*"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <AccountManagement />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/account/account-details"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <AccountDetails />
                                    </Suspense>
                                }
                            />
                            {/* <Route path="/account/account-details" element={<AppLayout><AccountDetails /></AppLayout>}></Route> */}
                            <Route
                                path="/patient-list/*"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <ListOfPatient />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/patient-list/patient-medication"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <PatientMedication />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/new-request/*"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <NewRequest />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/view-request/*"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <PipelineRequest />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/view-request/pipeline-request"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <StepWizard />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/released-request/*"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <ReleasedRequest />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/released-request/dataset-information"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <DatasetInformation1 />
                                    </Suspense>
                                }
                            ></Route>
                            {/* master data routing */}
                            <Route
                                path="/diagnosis"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Diagnosis />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/symptoms"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Symptoms />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/lifestyle"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <LifeStyle />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/supplement"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Supplement />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/marker-management"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Marker />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/inter-master"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Interpretation />
                                    </Suspense>
                                }
                            />
                             <Route
                                path="/amplifier-master"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Amplifier />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/phq8"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Phq8 />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/recommended-medication"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <MedicationRec />
                                    </Suspense>
                                }
                            />
                            {/* job manager */}
                            <Route
                                path="/order-management"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <OrderManagement />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/edf_job_manager/*"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <JobManager />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/edf_job_manager/edf-processing"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <JobEdfProcess />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/edf_job_manager/edf-analyzer"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <ArtifactToggle />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/edf-config"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Settings />
                                    </Suspense>
                                }
                            />
                            {/* amazon search */}
                            <Route
                                path="/search"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Search />
                                    </Suspense>
                                }
                            ></Route>

                            <Route
                                path="/amazon-search"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <AmazonSearch />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/archive-list"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <ArchivedRequest />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/new-request/add-new-request"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <NewServiceRequest />
                                    </Suspense>
                                }
                            />
                            {/* billing */}
                            <Route
                                path="/credit-manager"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <CreditManager />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/credit-settings"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <CreditSettings />
                                    </Suspense>
                                }
                            ></Route>
                            <Route
                                path="/report-settings"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <AccCreditSettings hasError />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/my-credit"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <MyCredit />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/billing-report"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <BillingReport />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/buy-credit"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <BuyCredit props={{ edit: false, closeModal: () => {} }} />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/payment-details"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <PaymentDetails />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/success-payment"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <SuccessPayment />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/error-payment"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <ErrorPayment />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/invsuccess-payment"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <InvoiceSuccessPay />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/inverror-payment"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <InvoiceErrorPay />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/invoice-manager"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <InvoiceManager />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/invoice"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Invoice />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/invoice1"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Invoice1 />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/report-comparison"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <ReportComparison />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/generate-invoice"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <GenerateInvoice />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/report-rate"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <ReportRate />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/compare-topography"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <CompareTopography />
                                    </Suspense>
                                }
                            ></Route>
                            {/* research automation */}
                            <Route
                                path="/medicine-automation"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <MedicineAutomation />
                                    </Suspense>
                                }
                            ></Route>
                           
                        </Routes>
                    </AppLayout>

                    {/* <ResearchLayout>
                        <Routes>
                            <Route
                                path="/research/recommended-medication"
                                element={
                                    <Suspense fallback={<LoadingComponent />}>
                                        <MedicationTemplate />
                                    </Suspense>
                                }
                            ></Route>
                        </Routes>
                    </ResearchLayout> */}
                </ErrorBoundary>
            )}

            <Routes>
                <Route path="/unauthorized" element={<UnAuthorizedPage />} />
            </Routes>
        </div>
    );
}
