import { createSlice } from '@reduxjs/toolkit';
import { getInterpretationFields, unSelectedMarker,addInterpretationMarker, saveInterpretationMarker, 
  interpretationAutomation, removeInterpretationMarker } from 'store/actions/pipeline-request/wizard-api/InterpretationAction';

const initialState = {
  //interpretation fields
  loading: false,
  interpretFields: null,
  error: null,
  success: false,
  //non markers,
  loading1: false,
  error1: null,
  nonmarkers: null,
  success1: false,
  //save interpretation
  loading2: false,
  error2: null,
  saveInterpret: null,
  success2: false,
  //add interpretation
  loading3: false,
  error3: null,
  addInterpret: null,
  success3: false,
  //automate value
  loading4: false,
  error4: null,
  getautomateValue: null,
  success4: false,
  //remove interpretation
  loading5 :false,
  error5:null,
  removeInterpret:null,
  success5: false,
}


const interpretSlice = createSlice({
  name: 'interpretation-details',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //associated table details
      .addCase(getInterpretationFields.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getInterpretationFields.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.interpretFields = payload;
        state.success = true;
      })
      .addCase(getInterpretationFields.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.success = false;
      })
      //interpretation fields
      .addCase(unSelectedMarker.pending, (state) => {
        state.loading1 = true;
        state.error1 = null;
        state.success1 = false;
      })
      .addCase(unSelectedMarker.fulfilled, (state, { payload }) => {
        state.loading1 = false;
        state.nonmarkers = payload;
        state.success1 = true;
      })
      .addCase(unSelectedMarker.rejected, (state, { payload }) => {
        state.loading1 = false;
        state.error1 = payload;
        state.success1 = false;
      })
      //document download
      .addCase(saveInterpretationMarker.pending, (state) => {
        state.loading2 = true;
        state.error2 = null;
        state.success2 = false;
      })
      .addCase(saveInterpretationMarker.fulfilled, (state, { payload }) => {
        state.loading2 = false;
        state.saveInterpret = payload;
        state.success2 = true;
      })
      .addCase(saveInterpretationMarker.rejected, (state, { payload }) => {
        state.loading2 = false;
        state.error2 = payload;
        state.success2 = false;
      })
      //add InterpretationMarker
      .addCase(addInterpretationMarker.pending, (state) => {
        state.loading3 = true;
        state.error3 = null;
        state.success3 = false;
      })
      .addCase(addInterpretationMarker.fulfilled, (state, { payload }) => {
        state.loading3 = false;
        state.addInterpret = payload;
        state.success3 = true;
      })
      .addCase(addInterpretationMarker.rejected, (state, { payload }) => {
        state.loading3 = false;
        state.error3 = payload;
        state.success3= false;
      })
      //remove InterpretationMarker
      .addCase(removeInterpretationMarker.pending, (state) => {
        state.loading5 = true;
        state.error5 = null;
        state.success5 = false;
      })
      .addCase(removeInterpretationMarker.fulfilled, (state, { payload }) => {
        state.loading5 = false;
        state.removeInterpret = payload;
        state.success5 = true;
      })
      .addCase(removeInterpretationMarker.rejected, (state, { payload }) => {
        state.loading5 = false;
        state.error5 = payload;
        state.success5= false;
      })
      //automation value
      .addCase(interpretationAutomation.pending, (state) => {
        state.loading4 = true;
        state.error4 = null;
        state.success4 = false;
      })
      .addCase(interpretationAutomation.fulfilled, (state, { payload }) => {
        state.loading4 = false;
        state.getautomateValue = payload;
        state.success4 = true;
      })
      .addCase(interpretationAutomation.rejected, (state, { payload }) => {
        state.loading4 = false;
        state.error4 = payload;
        state.success4 = false;
      })
  },
})
export default interpretSlice.reducer

