import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer, Table } from 'components/elements/AntComponent';
import { Input, Select, Form } from 'components/elements/FormComponent';
import { useDispatch, useSelector } from 'components/elements/CommonComponent';
import { SearchOutlined, LoadingOutlined } from 'components/elements/IconComponent';
import { listPatient } from 'store/actions/patient/patientActions';

export default function ExisingPatientModals(props) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [searchTableVal, setsearchTableval] = useState('');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchValue, setSearchValue] = useState('');
    const { loading, patientInfo } = useSelector((state) => state.patient);
    const { accType, loading2 } = useSelector((state) => state.request);
    const [accountId, setAccountId] = useState(0);
    const isAdmin = props?.data?.isAdmin;
    const accTypes = accType?.data || [];
    const [isBilling, setIsBilling] = useState(false);
    const [billingType, setBillingType] = useState('');
    const data = patientInfo != null && ( accountId !== 0 || isAdmin == false)? patientInfo.PatientsDetail : [];
    const totalPage = patientInfo !== null && accountId !== 0 ? patientInfo.DataFinder.totalrecords : 0;

    const accountOptions = accTypes.map((item) => {
        return { value: item.id, label: item.acct_name, isBilling: item.is_billing, billingType: item?.bill_type };
    });

    const handleAccountChange = (e, value) => {
        setAccountId(e);
        setIsBilling(value?.isBilling);
        setBillingType(value?.billingType);
    };

    const handleCloseDrawer = () => {
        props.data.drawerCallbackFunc(false, isBilling, billingType);
        form.resetFields();
    };

    useEffect(() => {
        if (isAdmin) {
            if(props?.data?.accountId){
                setAccountId(Number(props?.data?.accountId))
                form.setFieldsValue({
                    account: Number(props?.data?.accountId) || '',
                })
            }
            else{
                setAccountId(0);
            }
        }
    }, [props?.data?.isOpen == true]);

    const columns = [
        {
            title: 'Patient No',
            dataIndex: 'encoded_PatientNumber',
            key: 'patientno',
            sorter: (a, b) => a.encoded_PatientNumber?.length - b.encoded_PatientNumber?.length,
        },
        {
            title: 'Patient Name',
            dataIndex: 'name',
            key: 'name',
            render: (id, record) => {
                return record.first_name + ' ' + record.last_name;
            },
            onFilter: (value, record) => record.diagnosis_name.toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => a.first_name.length - b.first_name.length,
        },
        {
            title: 'Sex at birth',
            dataIndex: 'gender',
            key: 'gender',
        },
    ];

    function getPatientData(search, page, pageSize, sortField, sortOrder) {
        let data = {
            PntInput: {
                status: 'Active',
                acctid: accountId,
            },
            DataFinder: {
                pagesize: pageSize,
                currentpage: page,
                sortbycolumn: sortField || '',
                sortby: sortOrder || '',
                searchdata: search || '',
            },
        };
        dispatch(listPatient(data));
    }
    useEffect(() => {
        getPatientData(searchTableVal, pageIndex, pageSize, sortField, sortOrder);
    }, [accountId]);
    const tableChange = (pagination, ...sorted) => {
        let sort = '';
        if (sorted[1].order === 'ascend') {
            sort = 'asc';
        } else sort = 'desc';
        setPageIndex(pagination.current);
        setSortField(sorted[1].field);
        setSortOrder(sort);
        getPatientData(searchTableVal, pagination.current, pagination.pageSize, sorted[1].field, sort);
    };
    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            setsearchTableval(e.target.value);
            setPageIndex(1);
            getPatientData(e.target.value, 1, pageSize, null, null);
        }
    };
    const resetSearch = (e) => {
        if (e.key === 'Backspace' && !e.target.value) {
            setsearchTableval('');
            setPageIndex(1);
            getPatientData('', 1, pageSize, null, null);
        }
    };
    const performSearch = (value) => {
        setSearchValue(value);
        setPageIndex(1);
        getPatientData(value, 1, pageSize, null, null);
    };
    const searchbyBtn = () => {
        performSearch(searchValue);
    };
    return (
        <div>
            <Drawer title="Search Patient" placement="right" width={500} onClose={handleCloseDrawer} open={props?.data?.isOpen}>
                <Form form={form} layout="vertical" className="w-100 row m-0" autoComplete="off">
                    <div className="row p-0">
                        {isAdmin && (
                            <div className="col">
                                <Form.Item label="Account" name="account">
                                    <Select
                                        showSearch
                                        placeholder="Search"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                        filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                        notFoundContent={
                                            <div className="text-center p-4">
                                                {loading2 ? (
                                                    <span>
                                                        <LoadingOutlined />
                                                        Loading...
                                                    </span>
                                                ) : (
                                                    <span>No account available</span>
                                                )}
                                            </div>
                                        }
                                        options={accountOptions}
                                        onChange={handleAccountChange}
                                    />
                                </Form.Item>
                            </div>
                        )}
                        <div className="col">
                            <Form.Item label="Patient" name="patient">
                                <Input
                                    size="default"
                                    placeholder="Search"
                                    onKeyDown={(e) => handleSearch(e)}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyUp={(e) => resetSearch(e)}
                                    prefix={<SearchOutlined onClick={() => searchbyBtn()} />}
                                ></Input>
                            </Form.Item>
                        </div>
                    </div>
                </Form>

                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    onChange={tableChange}
                    onRow={(record, id) => {
                        return {
                            onClick: (event) => {
                                props.data.loadData(record, record.id);
                                handleCloseDrawer();
                            },
                        };
                    }}
                    pagination={{
                        current: pageIndex,
                        pageSize: pageSize,
                        total: totalPage,
                        onChange: (page, pageSize) => {
                            setPageIndex(page);
                            setPageSize(pageSize);
                            // getPatientData(searchTableVal, page, pageSize, sortField, sortOrder);
                        },
                    }}
                    locale={{
                        emptyText: <div className="p-3">{data.length == [] && accountId === 0 && isAdmin == true ? 'Select an account to display patient information' : 'No data'}</div>,
                    }}
                />
            </Drawer>
        </div>
    );
}
