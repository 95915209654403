import { createSlice } from '@reduxjs/toolkit';
import { RelRequest,getServicerequest,getDataset,allRelRequest } from 'store/actions/service-request/releasedRequestActions';

const initialState = {
  loading: false,
  reqInfo: null,
  error: null,
  success: false,

  loading1: false,
  getServiceRequestInfo: null,
  error1: null,
  success1: false,

  loading3: false,
  getDatasetInfo: null,
  error2: null,
  success2: false,
  
  loading4:false,
  allRelReqInfo:null,
  error4:null,
  success4: false,
}

const releasedRequestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {},
  extraReducers:(builder) => {
    builder
    .addCase(RelRequest.pending,(state)=>{
      state.loading = true
      state.error = null
      state.success = false;
    })
    .addCase(RelRequest.fulfilled,(state,{payload})=>{
      state.loading = false
      state.reqInfo = payload
      state.success = true;
    })
    .addCase(RelRequest.rejected,(state,{payload})=>{
      state.loading = false
      state.error = payload
      state.success = false;
    })
  
    // getDataServiceRequest
    .addCase(getServicerequest.pending,(state)=>{
      state.loading1 = true
      state.error1 = null;
      state.success1=false;
    })
    .addCase(getServicerequest.fulfilled,(state,{payload})=>{
      state.loading1 = false
      state.getServiceRequestInfo = payload;
      state.success1=true;
    })
    .addCase(getServicerequest.rejected,(state,{payload})=>{
      state.loading1 = false
      state.error1 = payload;
      state.success1=false;
    })

    //getDataset
    .addCase(getDataset.pending,(state)=>{
      state.loading3 = true;
      state.error2 = null;
      state.success2=false;
    })
    .addCase(getDataset.fulfilled,(state,{payload})=>{
      state.loading3 = false;
      state.getDatasetInfo = payload;
      state.success2=true;
    })
    .addCase(getDataset.rejected,(state,{payload})=>{
      state.loading3 = false;
      state.error2 = payload;
      state.success2=false;
    })

    //allRelRequest
    .addCase(allRelRequest.pending,(state)=>{
      state.loading4 = true;
      state.error4 = null;
      state.success4=false;
    })
    .addCase(allRelRequest.fulfilled,(state,{payload})=>{
      state.loading4 = false;
      state.allRelReqInfo = payload;
      state.success4=true;
    })
    .addCase(allRelRequest.rejected,(state,{payload})=>{
      state.loading4 = false;
      state.error4 = payload;
      state.success4=false;
    })
  },
})
export default releasedRequestSlice.reducer

