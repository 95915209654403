import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';
import { setResultDownloadProgress, setResultDownProgress } from 'store/slices/downloadFile';

const backendURL = config.apiURL;

export const approveRequest = createAsyncThunk('approveRequest', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/reqxl-export-Preview/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});
// service_request/savestatusdataset
export const releaseReq = createAsyncThunk('release-rew', async (payload) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/savestatusdataset`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

// docrepo/resultdownload
let progress = 0;
export const resultDownload = createAsyncThunk('result-download', async (payload, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setResultDownloadProgress(0));
        const { data } = await ax.post(`${backendURL}docrepo/resultdownload`, payload, {
            onDownloadProgress: (progressEvent) => {
                progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                dispatch(setResultDownloadProgress(progress));
            },
        });
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

// service_request/getrequestinfo
export const getrequestinfo = createAsyncThunk('getrequestinfo', async (payload) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/getresultinfo`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});
// patient/addtemp_pnt
export const addPntDetail = createAsyncThunk('addontdetail', async (payload) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/addtemp_pnt`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const getTopoInfo = createAsyncThunk('topo-info', async (payload) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/getresultinfosplited`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

// reassessment
export const reassessment = createAsyncThunk('reassessment', async (payload) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/reassessment`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});
