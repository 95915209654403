import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const consentForm = createAsyncThunk('consentform', async (formData, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/consentdocupload`, formData);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getDataset = createAsyncThunk('dataset-inf', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/getdataset/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const EDFconsentForm = createAsyncThunk('consent-form-preivew', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/getdataServicerequest/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});
