import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

// account/getAccountAll

export const getAccountAll = createAsyncThunk(
    'account-all',
    async () => {
        try {
            const { data } = await ax.get(
                `${backendURL}account/getAccountAll`
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return error.response.data;
            } else {
                return error.response;
            }
        }
    }
);

// patient/pnttag-all

export const allPatientTag = createAsyncThunk(
    'pnttagall',
    async () => {
        try {
            const { data } = await ax.get(
                `${backendURL}patient/pnttag-all`
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return error.response.data;
            } else {
                return error.response;
            }
        }
    }
);

// service_request/getassociatecommon 
export const commonMasterData = createAsyncThunk(
    'diag-common',
    async (payload, { rejectWithValue }) => {
        try {
            const { data } = await ax.post(
                `${backendURL}service_request/getassociatecommon_ai`,
                payload
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

//save common service data
export const saveCommonData = createAsyncThunk(
    'save-diag-common',
    async (payload, { rejectWithValue }) => {
        try {
            const { data } = await ax.post(
                `${backendURL}service_request/saveassociatecommon`,
                payload
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

//public patient verify account/getpublicaccount
export const verifyPntForm = createAsyncThunk(
    'verify-pnt-form',
    async (payload, { rejectWithValue }) => {
        try {
            const { data } = await ax.post(
                `${backendURL}account/getpublicaccount`,
                payload
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

//add-public patient intake form patient/publicpatient_intakeform
export const addPntForm = createAsyncThunk(
    'add-pnt-intake-form',
    async (formData, { rejectWithValue }) => {
        try {
            const { data } = await ax.post(
                `${backendURL}patient/publicrequest_intakeform`,
                formData
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

// account/getcountrystates
export const getCountry = createAsyncThunk(
    'get-country',
    async (payload, { rejectWithValue }) => {
        try {
            const { data } = await ax.post(
                `${backendURL}account/getcountrystates`,
                payload
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

//get state
export const getState = createAsyncThunk(
    'get-state',
    async (payload, { rejectWithValue }) => {
        try {
            const { data } = await ax.post(
                `${backendURL}account/getcountrystates`,
                payload
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const getPh8Data = createAsyncThunk(
    'ph8-data',
    async (payload, { rejectWithValue }) => {
        try {
            const { data } = await ax.post(
                `${backendURL}md_management/publicphq_mdata`,
                payload
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

// add_photobiomodulation
export const AddPhotobiomodulation = createAsyncThunk(
    'add-photobiomodulation',
    async (payload, { rejectWithValue }) => {
        try {
            const { data } = await ax.post(
                `${backendURL}service_request/add_photobiomodulation`,
                payload
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);
// get_photobiomodulation
export const GetPhotobiomodulation = createAsyncThunk(
    'get-photobiomodulation',
    async (id) => {
        try {
            const { data } = await ax.get(
                `${backendURL}service_request/get_photobiomodulation/${id}`
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return error.response.data;
            } else {
                return error.message;
            }
        }
    }
);

export const addPDRContent = createAsyncThunk(
    'add-pdr-content',
    async (payload, { rejectWithValue }) => {
        try {
            const { data } = await ax.post(
                `${backendURL}service_request/add_pdrinfo`,
                payload
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getPDRContent = createAsyncThunk(
    'get-pdr-content',
    async (id) => {
        try {
            const { data } = await ax.get(
                `${backendURL}service_request/get_pdrinfo/${id}`
            )
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return error.response.data;
            } else {
                return error.message;
            }
        }
    }
);




