import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';
import { updateProgress } from 'store/slices/uploadFile';

const backendURL = config.apiURL;

export const pipelineRequest = createAsyncThunk('pipeRequest', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/get_service_search`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

let uploadprogress = 0;
export const resultUpload = createAsyncThunk('resultupload', async (formData, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/resultupload`, formData, {
            onUploadProgress: (progressEvent) => {
                uploadprogress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                dispatch(updateProgress(uploadprogress));
            },
        });
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// docrepo/resultlist

export const resultList = createAsyncThunk('resultlist', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/resultlist`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const approveReq = createAsyncThunk('approve-requests', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/active_request/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const archiveRequest = createAsyncThunk('archive-requests', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/saverequestarchived`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const validateRequest = createAsyncThunk('validate-requests', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/getreq_validation`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
