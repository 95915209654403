import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';
import { setReqDownProgress } from 'store/slices/downloadFile';
import { uploadRequestProgress } from 'store/slices/uploadFile';

const backendURL = config.apiURL;

export const saveClinicalHistory = createAsyncThunk('clinicsave', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/savedataset`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getDatasets = createAsyncThunk('dataset-info', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/getdataset/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});

export const sendMail = createAsyncThunk('sendemail', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/mail-consult`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

let uploadprogress = 0;
export const consentForm = createAsyncThunk('consentformupload', async (formData, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/consentdocupload`, formData, {
            onUploadProgress: (progressEvent) => {
                uploadprogress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                dispatch(uploadRequestProgress(uploadprogress));
            },
        });
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

let progress = 0;
export const consentFormDownload = createAsyncThunk('consentFormDownload', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/consentdocdownload`, payload, {
            onDownloadProgress: (progressEvent) => {
                progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                dispatch(setReqDownProgress(progress));
            },
        });
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getPntQuestionnaire = createAsyncThunk('pnt-ques-d', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}patient/getpatientreq_phq/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            // return error.response.data;
        } else {
            // return error.message;
        }
    }
});
