import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const getWizardSteps = createAsyncThunk('getwizardsteps', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/get_wizard_pipelinestage/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const saveWizardSteps = createAsyncThunk('savewizardsteps', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/save_wizard_pipelinestage`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const patientCard = createAsyncThunk('patient-card', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/getdataServicerequest/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const saveReportCmd = createAsyncThunk('save-report-commands', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}billing/SaveCommendSet`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});