import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

//recording analysis
export const GetRecordingAnalysis = createAsyncThunk('recording-analysis-get', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/get_associate_analysisprocedures/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            // return rejectWithValue(error.response.data)
        } else {
            // return rejectWithValue(error.message)
        }
    }
});
//interpretation associated
export const GetAssociatedInterpretation = createAsyncThunk('associate-interpretation-get', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/get_associate_Interpretation/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return;
        } else {
            return;
        }
    }
});

//neurofeedback get
export const GetNeurofeedback = createAsyncThunk('get-neurofeedback', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/get_neurofeedback/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return;
        } else {
            return;
        }
    }
});

export const GetAdjunctTherapy = createAsyncThunk('get-adjunct', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}service_request/get_adjuncttherapies/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return;
        } else return;
    }
});
//lifestyle service_request/add_associate_mdlifestyle`

export const SaveLifestyle = createAsyncThunk('save-lifestyle', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/add_associate_mdlifestyle`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return;
        } else return;
    }
});

//symptoms service_request/add_associate_mdsymptomsTherapies
export const SaveSupplement = createAsyncThunk('save-symptoms', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/add_associate_mdsupplementation`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return;
        } else return;
    }
});

//medication add_associate_mdmedicTherapies
export const SaveMedication = createAsyncThunk('save-medic', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/add_associate_mdmedicTherapies`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return;
        } else return;
    }
});
//save recording analysis service_request/add_associate_analysisprocedures
export const saveRecordingAnalysis = createAsyncThunk('save-recording-analysis', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/add_associate_analysisprocedures`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//save interpretation of findings service_request/add_associate_Interpretation
export const saveInterpretFindings = createAsyncThunk('save-interpret-analysis', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/add_associate_Interpretation`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//save service_request/add_neurofeedback
export const saveNeurofeedback = createAsyncThunk('save-neurofeedback', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/add_neurofeedback`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//save add_adjuncttherapies
export const saveAdjunctTherapy = createAsyncThunk('save-adjtherapy', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}service_request/add_adjuncttherapies`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
