import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

// auth/menuservice
export const menuService = createAsyncThunk('menuservice', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}auth/menuservice`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// datahub_report/get_notification/185
export const getNotification = createAsyncThunk('get-notification', async (payload) => {
    try {
        const { data } = await ax.post(`${backendURL}datahub_report/getclear_notification`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});
