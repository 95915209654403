import { createSlice } from '@reduxjs/toolkit';
import { EDFconsentForm } from 'store/actions/pipeline-request/reqInitAction';
import {  consentForm, consentFormDownload,  getDatasets, getPntQuestionnaire,saveClinicalHistory, sendMail } from 'store/actions/pipeline-request/wizard-api/ReqInfoAction';

const initialState = {
  loading: false,
  clinicalHistory: null,
  error: null,
  success: false,
  //get data
  loading1: false,
  dataclinic: null,
  error1: null,
  success1: false,
  //send email
  loading2: false,
  sendEmail: null,
  error2: null,
  success2: false,
  //consent doc downlaod
  loading3: false,
  docDownload: null,
  error3: null,
  success3: false,
  //consent doc upload
  loading4:false,
  docUpload:null,
  error4:null,
  success4:false,
  //preview sample consent docu
  loading5:false,
  previewDoc:null,
  error5:null,
  success5:false,
  //pnt ques
  loading6:false,
  pntQues:null,
  error6:null,
  success6:false
}


const ReqInfoSlices = createSlice({
  name: 'reqinfodetails',
  initialState,
  reducers: {},
  extraReducers:(builder)=>{
    builder
    //save clinical history
    .addCase(saveClinicalHistory.pending,(state)=>{
      state.loading = true
      state.error = null
      state.success =false;
    })
    .addCase(saveClinicalHistory.fulfilled,(state,{payload})=>{
      state.loading = false
      state.clinicalHistory = payload;
      state.success=true;
    })
    .addCase(saveClinicalHistory.rejected,(state,{payload})=>{
      state.loading = false
      state.error = payload;
      state.success=false;
    })
    //get data
    .addCase(getDatasets.pending,(state)=>{
      state.loading1 = true
      state.error1 = null
      state.success1=false;
    })
    .addCase(getDatasets.fulfilled,(state,{payload})=>{
      state.loading1 = false
      state.dataclinic = payload;
      state.success1=true;
    })
    .addCase(getDatasets.rejected,(state,{payload})=>{
      state.loading1 = false
      state.error1 = payload
      state.success1=false;
    })
    //send email
    .addCase(sendMail.pending,(state)=>{
      state.loading2 = true
      state.error2 = null
      state.success2=false;
    })
    .addCase(sendMail.fulfilled,(state,{payload})=>{
      state.loading2 = false
      state.sendEmail = payload;
      state.success2=true;
    })
    .addCase(sendMail.rejected,(state,{payload})=>{
      state.loading2 = false
      state.error2 = payload
      state.success2=false;
    })
    //consent doc download
    .addCase(consentFormDownload.pending,(state)=>{
      state.loading3= true
      state.error3 = null
      state.success3=false;
    })
    .addCase(consentFormDownload.fulfilled,(state,{payload})=>{
      state.loading3 = false
      state.docDownload = payload;
      state.success3=true;
    })
    .addCase(consentFormDownload.rejected,(state,{payload})=>{
      state.loading3 = false
      state.error3 = payload
      state.success3=false;
    })
    //consent doc upload
    .addCase(consentForm.pending,(state)=>{
      state.loading4= true
      state.error4 = null
      state.success4=false;
    })
    .addCase(consentForm.fulfilled,(state,{payload})=>{
      state.loading4 = false
      state.docUpload = payload;
      state.success4=true;
    })
    .addCase(consentForm.rejected,(state,{payload})=>{
      state.loading4 = false
      state.error4 = payload
      state.success4=false;
    })
    //preview sample consent form
    .addCase(EDFconsentForm.pending,(state)=>{
      state.loading5 = true;
      state.error5 = null;
      state.success5=false;
    })
    .addCase(EDFconsentForm.fulfilled,(state,{payload})=>{
      state.loading5=false;
      state.previewDoc=payload;
      state.success5=true;
    })
    .addCase(EDFconsentForm.rejected,(state,{payload})=>{
      state.loading5 = false;
      state.error5 = payload;
      state.success5=false;
    })
     //pnt questionnaire
     .addCase(getPntQuestionnaire.pending,(state)=>{
      state.loading6 = true;
      state.error6 = null;
      state.success6=false;
    })
    .addCase(getPntQuestionnaire.fulfilled,(state,{payload})=>{
      state.loading6=false;
      state.pntQues=payload;
      state.success6=true;
    })
    .addCase(getPntQuestionnaire.rejected,(state,{payload})=>{
      state.loading6 = false;
      state.error6 = payload;
      state.success6=false;
    })

   
  },
})
export default ReqInfoSlices.reducer

