import { createSlice } from '@reduxjs/toolkit';
import { addMarker, deleteMarker, markerMaster } from 'store/actions/master-data/MarkerActions';

const initialState = {
  loading: false,
  markerInfo: null,
  error: null,
  success: false,
  //addmarker
  loading1: false,
  addMarkerInfo: null,
  error1: null,
  success1: false,
  //removemarker
  loading2: false,
  rmvmarker: null,
  error2: null,
  success2: false
}

const markerMasterSlice = createSlice({
  name: 'interpretationMaster',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get marker
      .addCase(markerMaster.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(markerMaster.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.markerInfo = payload;
        state.success = true;
      })
      .addCase(markerMaster.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.success = false;
      })
      //add marker
      .addCase(addMarker.pending, (state) => {
        state.loading1 = true;
        state.error1 = null;
        state.success1 = false;
      })
      .addCase(addMarker.fulfilled, (state, { payload }) => {
        state.loading1 = false
        state.addMarkerInfo = payload
        state.success1 = true;
      })
      .addCase(addMarker.rejected, (state, { payload }) => {
        state.loading1 = false
        state.error1 = payload
        state.success1 = false;
      })
      //delete marker
      .addCase(deleteMarker.pending, (state) => {
        state.loading2 = true;
        state.error2 = null;
        state.success2 = false;
      })
      .addCase(deleteMarker.fulfilled, (state, { payload }) => {
        state.loading2 = false
        state.rmvmarker = payload
        state.success2 = true;
      })
      .addCase(deleteMarker.rejected, (state, { payload }) => {
        state.loading2 = false
        state.error2 = payload
        state.success2 = false;
      })
  },
})
export default markerMasterSlice.reducer

