import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const getMedTempMaster = createAsyncThunk('med-temp-master', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/get_templatemedication`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const addMedTemp = createAsyncThunk('add-med-temp', async (formData, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/add_templatemedication`, formData);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const deleteMedTemp = createAsyncThunk('remove-med-temp', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/delete_templatemedication`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// md_management/add_templatemedication
export const getMastrMedic = createAsyncThunk('get-temp-d', async () => {
    try {
        const { data } = await ax.get(`${backendURL}md_management/get_templatemedicrefer`);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});
