import React, { useState } from 'react';
import { Table , Tooltip} from 'components/elements/AntComponent';
import { Button } from 'components/elements/FormComponent';
import { EditIcon } from 'assets/images/custom-icons/customIcons';
import RulesModal from './RulesModal';


export default function MedicineAutomation() {
    const [reference, setReference] = useState(null);
    const [open, setOpen] = useState(false);
    const columns = [
        {
            title: 'Medicine Code',
            dataIndex: 'medic_code',
            key: 'medic_code',
        },
        {
            title: 'Medicine Name',
            dataIndex: 'medicine_name',
            key: 'medicine_name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '60%',
        },
        {
            title: 'Submitted On',
            dataIndex: 'submitted_on',
            key: 'submitted_on',
        },
        {
            title:'Action',
            dataIndex:'action',
            key:'action',
            render: (id, record) => {
                return (<>
                    
                        <Tooltip title="Edit" className='mt-0'>
                        <span className='icon-edit'  onClick={() => { setOpen(true) }} ><EditIcon className='me-2'/></span>
                        </Tooltip>
                      
                </>);
            }
            
        }
    ];
    const handleCallback = (item1) => {
        setOpen(item1)
    };
    
    const data = [
        {
            id: 1,
            medic_code: 'MED0923',
            medicine_name: 'Buspirone',
            description: 'Increases in alpha power, decreases in slower frequencies including delta, and improvement in beta profiles have been demonstrated in various populations given this medication',
            submitted_on: '07/29/2024',
        },
        {
            id: 2,
            medic_code: 'MED0923',
            medicine_name: 'Atomoxetine',
            description:
                'A 2009 study demonstrated increases in beta along with improvements in standardized testing in children with ADHD. These findings are consistent, at least in part, with the normalization of EEG findings aberrant in this report.',
            submitted_on: '07/29/2024',
        },
        {
            id: 3,
            medic_code: 'MED0923',
            medicine_name: 'Clonidine',
            description: 'Clonidine has been demonstrated in clinical trials to decrease theta, increase alpha, and overall EEG power, consistent with normalization of this EEG.',
            submitted_on: '07/29/2024',
        },
        {
            id: 4,
            medic_code: 'MED0923',
            medicine_name: 'Clozapine',
            description: 'After six weeks of treatment, spectral EEG changes in patients receiving clozapine resulted in increases in absolute slowing and decreases in mean beta and relative alpha.',
            submitted_on: '07/29/2024',
        },
        {
            id: 5,
            medic_code: 'MED0923',
            medicine_name: 'Desvenlafaxine',
            description:
                "While less is known about this specific medication's EEG effects and more known about similar medications in its class, it has demonstrated improvements in empirical studies and clinical samples consistent trends in this report.",
            submitted_on: '07/29/2024',
        },
    ];

    return (
        <div>
            <div className="d-flex mt-2">
                <h5 className="txt-primary my-auto fw-bold">Medicine Automation</h5>
            </div>
            <div className="mt-3 new-custom-table ">
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey="id"
                    className="col"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                setReference(record);
                            },
                            className: record.id === (reference?.id ?? null) ? 'selected-reference' : '',
                        };
                    }}
                />
                {/* <div className="col-md-3 ps-3">
                    <div className="py-3 px-2 h-100 rounded bg-white">
                        <h6 className="txt-primary fw-bold text-center fs-18">Medicine Rules</h6>
                        <div className="bg-white p-3 h-100">
                            <div className="row m-0">
                                <h6 className="col-md-6 fs-16 px-0">Medicine Code : </h6> <div className="col fs-16">{reference?.medic_code}</div>
                            </div>
                            <div className="row m-0">
                                <h6 className="col-md-6 fs-16 px-0">Medicine Name : </h6> <div className="col fs-16">{reference?.medicine_name}</div>
                            </div>
                            <h6 className="text-dark fw-bold fs-17 mt-3">Description:</h6>
                            <div>{reference?.description}</div>
                        </div>
                    </div>
                </div> */}
            </div>

            <RulesModal  data = {{open: open, handleCallback:handleCallback}}/>
        </div>
    );
}
