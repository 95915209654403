import { createSlice } from '@reduxjs/toolkit';
import {
    listOfJobManager,
    edfJobManager,
    addEdfSetting,
    getEdfSetting,
    cancelEDFjob,
    cancelAllEDFjob,
    resetEDFJob,
    getListOfJobManager,
    resetAllEDFjob,
    getStepStatus,
    getErrorJobs,
    getEnableEdfAnalyzer,
    getEdfAnalyzerTopos,
    getEdfAnalyzerJobs,
} from 'store/actions/jobmanager/jobManagerActions';

const initialState = {
    loading: false,
    jobManagerListInfo: null,
    error: null,
    success: false,
    //add edf setting
    loading2: false,
    addEdfsetting: null,
    error2: null,
    success2: false,
    //get edf setting
    loading3: false,
    getedfsetting: null,
    error3: null,
    success3: false,
    // cancel edf job
    loading4: false,
    cancelJobInfo: null,
    error4: null,
    success4: false,
    // reset edf job
    loading5: false,
    resetInfo: null,
    error5: null,
    success5: false,
    //cancel all job
    loading6: false,
    cancelAllJobInfo: null,
    error6: null,
    success6: false,
    // jobManager job
    loading7: false,
    jobManagerListInfo1: null,
    error7: null,
    success7: false,
    // reset all job
    loading8: false,
    resetAllEDFjob: null,
    error8: null,
    success8: false,

    loading1: false,
    edfJobManagerInfo: null,
    error1: null,
    success1: false,
    //step status
    loading9: false,
    stepStatusInfo: null,
    error9: null,
    success9: null,
    //error jobs get call
    loading10: false,
    errorJobInfo: null,
    error10: null,
    success10: false,
    //all jobs get call
    loading11: false,
    allJobInfo: null,
    error11: null,
    success11: false,
    loading12: false,
    error12: null,
    success12: false,
    enableEdfInfo: null,
    loading13: false,
    error13: null,
    edfAnalyzerInfo: null,
    success13: false,
    //get edf analyzer grid job
    loading14:false,
    error14:null,
    success14:false,
    edfAnalyzerInfos:null,
};

const jobManagerSlice = createSlice({
    name: 'jobmanager',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(listOfJobManager.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(listOfJobManager.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.jobManagerListInfo = payload;
                state.success = true;
            })
            .addCase(listOfJobManager.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })

            // docrepo/edf_list_md/69
            .addCase(edfJobManager.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(edfJobManager.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.edfJobManagerInfo = payload;
                state.success1 = true;
            })
            .addCase(edfJobManager.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.error1 = payload;
                state.success1 = false;
            })
            //errror jobs
            .addCase(getErrorJobs.pending, (state) => {
                state.loading10 = true;
                state.error10 = null;
                state.success10 = false;
            })
            .addCase(getErrorJobs.fulfilled, (state, { payload }) => {
                state.loading10 = false;
                state.errorJobInfo = payload;
                state.success10 = true;
            })
            .addCase(getErrorJobs.rejected, (state, { payload }) => {
                state.loading10 = false;
                state.error10 = payload;
                state.success10 = false;
            })

            //edf setting
            .addCase(addEdfSetting.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(addEdfSetting.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.addEdfsetting = payload;
                state.success2 = true;
            })
            .addCase(addEdfSetting.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.error2 = payload;
                state.success2 = false;
            })

            // settings
            .addCase(getEdfSetting.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(getEdfSetting.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.getedfsetting = payload;
                state.success3 = true;
            })
            .addCase(getEdfSetting.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })

            //cancel job
            .addCase(cancelEDFjob.pending, (state) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(cancelEDFjob.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.cancelJobInfo = payload;
                state.success4 = true;
            })
            .addCase(cancelEDFjob.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            })

            //reset job
            .addCase(resetEDFJob.pending, (state) => {
                state.loading5 = true;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(resetEDFJob.fulfilled, (state, { payload }) => {
                state.loading5 = false;
                state.resetInfo = payload;
                state.success5 = true;
            })
            .addCase(resetEDFJob.rejected, (state, { payload }) => {
                state.loading5 = false;
                state.error5 = payload;
                state.success5 = false;
            })

            //cancel all job
            .addCase(cancelAllEDFjob.pending, (state) => {
                state.loading6 = true;
                state.error6 = null;
                state.success6 = false;
            })
            .addCase(cancelAllEDFjob.fulfilled, (state, { payload }) => {
                state.loading6 = false;
                state.cancelAllJobInfo = payload;
                state.success6 = true;
            })
            .addCase(cancelAllEDFjob.rejected, (state, { payload }) => {
                state.loading6 = false;
                state.error6 = payload;
                state.success6 = false;
            })

            //reset all job
            .addCase(resetAllEDFjob.pending, (state) => {
                state.loading8 = true;
                state.error8 = null;
                state.success8 = false;
            })
            .addCase(resetAllEDFjob.fulfilled, (state, { payload }) => {
                state.loading8 = false;
                state.resetAllEDFjob = payload;
                state.success8 = true;
            })
            .addCase(resetAllEDFjob.rejected, (state, { payload }) => {
                state.loading8 = false;
                state.error8 = payload;
                state.success8 = false;
            })

            // get all Edf process
            .addCase(getListOfJobManager.pending, (state) => {
                state.loading7 = true;
                state.error7 = null;
                state.success7 = false;
            })
            .addCase(getListOfJobManager.fulfilled, (state, { payload }) => {
                state.loading7 = false;
                state.jobManagerListInfo1 = payload;
                state.success7 = true;
            })
            .addCase(getListOfJobManager.rejected, (state, { payload }) => {
                state.loading7 = false;
                state.error7 = payload;
                state.success7 = false;
            })
            // get all step status
            .addCase(getStepStatus.pending, (state) => {
                state.loading9 = true;
                state.error9 = null;
                state.success9 = false;
            })
            .addCase(getStepStatus.fulfilled, (state, { payload }) => {
                state.loading9 = false;
                state.stepStatusInfo = payload;
                state.success9 = true;
            })
            .addCase(getStepStatus.rejected, (state, { payload }) => {
                state.loading9 = false;
                state.error9 = payload;
                state.success9 = false;
            })
            .addCase(getEnableEdfAnalyzer.pending, (state) => {
                state.loading12 = true;
                state.error12 = null;
                state.success12 = false;
                state.enableEdfInfo = null;
            })
            .addCase(getEnableEdfAnalyzer.fulfilled, (state, { payload }) => {
                state.loading12 = false;
                state.enableEdfInfo = payload;
                state.success12 = true;
            })
            .addCase(getEnableEdfAnalyzer.rejected, (state, { payload }) => {
                state.loading12 = false;
                state.error12 = payload;
                state.success12 = false;
            })
            .addCase(getEdfAnalyzerTopos.pending, (state) => {
                state.loading13 = true;
                state.error13 = null;
                state.success13 = false;
                state.edfAnalyzerInfo = null;
            })
            .addCase(getEdfAnalyzerTopos.fulfilled, (state, { payload }) => {
                state.loading13 = false;
                state.edfAnalyzerInfo = payload;
                state.success13 = true;
            })
            .addCase(getEdfAnalyzerTopos.rejected, (state, { payload }) => {
                state.loading13 = false;
                state.error13 = payload;
                state.success13 = false;
            })
            .addCase(getEdfAnalyzerJobs.pending, (state) => {
                state.loading14 = true;
                state.error14 = null;
                state.success14 = false;
                state.edfAnalyzerInfos = null;
            })
            .addCase(getEdfAnalyzerJobs.fulfilled, (state, { payload }) => {
                state.loading14 = false;
                state.edfAnalyzerInfos = payload;
                state.success14 = true;
            })
            .addCase(getEdfAnalyzerJobs.rejected, (state, { payload }) => {
                state.loading14 = false;
                state.error14 = payload;
                state.success14 = false;
            });
    },
});
export default jobManagerSlice.reducer;
