import { Image,Button } from 'components/elements/FormComponent';
import React from 'react';
import PageIcon from 'assets/images/oops-page.png';
import logoIcon from 'assets/images/brandname.png'
import { useNavigate } from 'react-router-dom';

export default function UnAuthorizedPage() {
    const navigate = useNavigate();

    const navigateToDashboard = () => {
        navigate('/dashboard'); // Go back to the previous screen
        // window.location.reload(); // Reload the page
    };

    return (
        <div className='oops-page p-5'>
            <div className='d-flex container '>
                {/* <h3 className='txt-primary fw-bold'>EEG DATA HUB</h3> */}
                <Image src={logoIcon} preview={false} width={250} />
            </div>
            <div className='h-100 d-flex container'>
                <div className='col my-auto ps-2'>
                    <h1 className="error-number mb-2">401</h1>
                    <h2 className="error-title mb-3">Page restricted</h2>
                    <p className="error-text">You are not authorized to access this page</p>
                    <div className='text-start mt-4'>
                    <Button type="primary" onClick={() => navigateToDashboard()} className='me-auto'>Back</Button>
                    </div>
                </div>
                <div className='col'>
                    <Image src={PageIcon} preview={false} />
                </div>

            </div>
        </div>
    )
}