import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from 'store/actions/dashboardActions';
import { Table, Tooltip } from 'components/elements/AntComponent';
import { Input, Button, Radio } from 'components/elements/FormComponent';
import { PlusOutlined, SearchOutlined } from 'components/elements/IconComponent';
import { EditIcon } from 'assets/images/custom-icons/customIcons';
import AddNewUser from 'components/account/modal/AddNewUser';
import { message } from 'components/elements/CommonComponent';

export default function UserDetail() {
    const dispatch = useDispatch();
    const userRole = sessionStorage.getItem('role');
    const { userallInfo, loading1 } = useSelector((state) => state.dashboard);
    const { success4, error4, loading4 } = useSelector((state) => state.account);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const data = userallInfo?.UserDetail || [];
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [userID, setUserID] = useState(false);
    const [searchTableVal, setsearchTableval] = useState('');
    const totalPage = userallInfo ? userallInfo?.DataFinder?.totalrecords : 0;
    const [successmsgUser, setSuccessmsgUser] = useState(false);
    const successMsgUser = successmsgUser ? success4 : null;
    const [showfailmsg, setShowfailmsg] = useState(false);
    const failMsg = showfailmsg ? error4 : null;
    const [isStatus, setIsStatus] = useState('Active');

    useEffect(() => {
        if (successMsgUser) {
            if (userID) {
                message.success('User Updated Successfully');
                setOpen(false);
                setSuccessmsgUser(false);
            } else {
                message.success('User Added Successfully');
                setOpen(false);
                setSuccessmsgUser(false);
            }
            getUserDetails(searchTableVal, pageSize, pageIndex, sortField, sortOrder, isStatus);
        }
        if (failMsg) {
            message.error('This email already exists!');
            setShowfailmsg(false);
        }
    }, [successMsgUser, failMsg]);

    useEffect(() => {
        if (loading4) {
            setSuccessmsgUser(true);
            setShowfailmsg(true);
        }
    }, [loading4]);

    function getUserDetails(search, pageSize, pageIndex, sortField, sortOrder, status) {
        let inputJson = {
            UserInput: {
                acctid: 0,
                status: status || '',
                role: '',
            },
            DataFinder: {
                pagesize: pageSize,
                currentpage: pageIndex,
                sortbycolumn: sortField || '',
                sortby: sortOrder || '',
                searchdata: search,
            },
        };
        dispatch(getAllUsers(inputJson));
    }

    const tableChange = (pagination, ...sorted) => {
        let sort = '';
        if (sorted[1].order === "ascend"){
            sort = "asc";
        }else if(sorted[1].order === 'descend'){
            sort="desc";
        } else sort = "";
        setSortField(sorted[1].field);
        setSortOrder(sort);
        setPageIndex(pagination.current);
        getUserDetails(searchTableVal, pageSize, pagination.current, sorted[1].field, sort, isStatus);
    };

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            setsearchTableval(e.target.value);
            setPageIndex(1);
            getUserDetails(e.target.value, pageSize, 1, null, null, isStatus);
        }
    };

    const resetSearch = (e) => {
        if (e.key === 'Backspace' && !e.target.value) {
            setsearchTableval('');
            setPageIndex(1);
            getUserDetails(e.target.value, pageSize, 1, null, null, isStatus);
        }
    };

    const performSearch = (value) => {
        getUserDetails(value);
        setPageIndex(1);
        getUserDetails(value, pageSize, 1, null, null, isStatus);
    };

    const searchbyBtn = () => {
        performSearch(searchTableVal);
    };
    const handleStatusChange = (e) => {
        getUserDetails(searchTableVal, pageSize, 1, null, null, e.target.value);
        setIsStatus(e.target.value);
        setPageIndex(1);
    };
    useEffect(() => {
        getUserDetails(searchTableVal, pageSize, pageIndex, sortField, sortOrder, isStatus);
    }, []);

    const showModal = (item) => {
        setOpen(item);
    };

    const columns = [
        {
            title: 'S.no',
            dataIndex: 'sno',
            key: 'sno',
            render: (id, record, index) => {
                if (pageIndex === 1) {
                    return index + 1;
                } else {
                    return (pageIndex - 1) * pageSize + (index + 1);
                }
            },
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
            render: (username) => {
                return <div>{username ? username : '---'}</div>;
            },
            sorter: (a, b) => a.username?.length - b.username?.length,
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'firstname',
            sorter: (a, b) => a.first_name?.length - b.first_name?.length,
            render: (firstname) => {
                return <div>{firstname ? firstname : '---'}</div>;
            },
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'lastname',
            render: (lastname) => {
                return <div>{lastname ? lastname : '---'}</div>;
            },
            sorter: (a, b) => a.last_name?.length - b.last_name?.length,
        },
        {
            title: 'Account Name',
            dataIndex: 'accountname',
            key: 'accountname',
            render: (accountname) => {
                return <div>{accountname ? accountname : '---'}</div>;
            },
        },
        {
            title: 'Login From',
            dataIndex: 'date_joined',
            key: 'date_joined',
            render: (date_joined) => {
                const originalDate = new Date(date_joined);
                return <div>{userallInfo ? originalDate?.toLocaleDateString() : '--'}</div>;
            },
        },
        {
            title: 'User Role',
            dataIndex: 'user_role',
            key: 'user_role',
            render: (_, record) => {
                if (record.is_superuser) {
                    return <div>{record.groups?.length == 0 ? 'Admin' : record.groups[0]?.toLowerCase() === 'accounts' ? 'Account Admin' : record.groups[0]?.toLowerCase() === 'billing' ? 'Billing' : 'Technician'}</div>;
                } else if (record.is_staff) {
                    return (
                        <div>{record.groups?.length == 1 ? (record.groups[0]?.toLowerCase() === 'accounts' ? 'Account Admin' : record.groups[0]?.toLowerCase() === 'billing'?'Lab Billing':'Lab Technician') : record.groups[1]?.toLowerCase() === 'billing' ? 'Lab Billing' : 'Lab Technician'}</div>
                    );
                }
            },
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            render: (_, record) => {
                if (record.is_active) {
                    return <Button className="req-released-tag border-0 px-4 mx-auto">Active</Button>;
                } else if (!record.is_active) {
                    return <Button className="req-init-tag border-0 px-3 text-danger mx-auto">InActive</Button>;
                }
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (action, record) => {
                return (
                    <div className="icon-edit">
                        {userRole === 'researcher' ? (
                            <span className="icon-edit-disabled">
                                <EditIcon />
                            </span>
                        ) : (
                            <Tooltip title="Edit" className="mt-0">
                                <span
                                    onClick={() => {
                                        setOpen(true);
                                        setRowData(record);
                                        setUserID(record.id);
                                    }}
                                >
                                    <EditIcon />
                                </span>
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <div className="px-0">
            <div className="row request-title mb-2 grid-header mx-0 ">
                <div className="col text-start ms-1 section-one ps-0 my-auto">
                    <h6 className="my-auto fs-18">List of Users</h6>
                </div>
                <div className="col-md-4 ms-auto section-two pe-0">
                    <div className="row right-side ps-0 mx-0">
                        <div className="col-auto ms-auto text-start ms-1 pe-0 active-btnss">
                            <Radio.Group defaultValue={isStatus} buttonStyle="solid" className="col pe-0" onChange={handleStatusChange}>
                                <Radio.Button className="active" value="Active">
                                    Active
                                </Radio.Button>
                                <Radio.Button className="inactive px-2" value="InActive">
                                    Inactive
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                        <div className="col-md-4 pe-0  child-one">
                            <Input
                                size="default"
                                placeholder="Search"
                                onKeyDown={(e) => handleSearch(e)}
                                value={searchTableVal}
                                onChange={(e) => setsearchTableval(e.target.value)}
                                onKeyUp={(e) => resetSearch(e)}
                                prefix={<SearchOutlined onClick={searchbyBtn} />}
                            />
                            {/* </Input>
                                onKeyDown={(e) => handleSearch(e)}
                                value={searchTableVal}
                                onChange={(e) => setsearchTableval(e.target.value)}
                                onKeyUp={(e) => resetSearch(e)}
                                prefix={<SearchOutlined onClick={searchbyBtn} />}
                            ></Input> */}
                        </div>
                        {/* {userRole === 'researcher' ? (
                            ''
                        ) : ( */}
                        <div className="col-auto pe-0 child-two">
                            <Tooltip title="Add new user " className="mt-0">
                                <Button
                                    size="default"
                                    type="primary"
                                    className="add-btn"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        setOpen(true);
                                        setRowData(null);
                                    }}
                                >
                                    Add
                                </Button>
                            </Tooltip>
                        </div>
                        {/* )} */}
                    </div>
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                rowKey="id"
                className="border rounded"
                loading={loading1}
                onChange={tableChange}
                pagination={{
                    current: pageIndex,
                    pageSize: pageSize,
                    total: totalPage,
                    onChange: (page, pageSize) => {
                        setPageIndex(page);
                        setPageSize(pageSize);
                    },
                }}
            />
            {open ? <AddNewUser data={{ open: open, showModal: showModal, rowData: rowData, isAccount: true }} /> : null}
        </div>
    );
}
