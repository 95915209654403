import { createSlice } from '@reduxjs/toolkit';
import {
    getMedication,
    getPatient,
    listPatient,
    addPatient,
    patientMedication,
    addMedication,
    patientSaveTags,
    getPatientTag,
    deletePatient,
    deletePatientMedication,
    removePntTag,
    patientDataExport,
    patientRequestInfo,
    getComparisonReport, getReportComparison
} from 'store/actions/patient/patientActions';

const initialState = {
    loading: false,
    patientInfo: null,
    error: null,
    success: false,
    //adpatient
    loading2: false,
    addInfo: null,
    error2: null,
    success2: false,
    //deletepatient
    loading10: false,
    delepntInfo: null,
    error10: null,
    success10: false,
    //get medication
    loading3: false,
    medicInfo: null,
    error3: null,
    success3: false,
    //add medicaiton
    loading4: false,
    addmedicInfo: null,
    error4: null,
    success4: false,
    //get all patient
    loading5: false,
    updateInfo: null,
    error5: null,
    success5: false,
    //save patient tags
    loading7: false,
    saveTagInfo: null,
    error7: null,
    success7: false,
    //get patient tags
    loading8: false,
    getTagInfo: null,
    error8: null,
    success8: false,
    //get medication
    loading9: false,
    updateMedicInfo: null,
    error9: null,
    success9: false,
    //delete medic
    loading10: false,
    delMedicInfo: null,
    error10: null,
    success10: false,
    //remove pnt tag
    loading11: false,
    deltaginfo: null,
    error11: null,
    success11: false,
    //pnt export
    loading12: false,
    pntexportInfo: null,
    error12: null,
    success12: false,
    // patientreqinfo
    loading13: false,
    pntreqInfo: null,
    error13: null,
    success13: false,
    //report comparison
    loading14: false,
    cmpreport: null,
    error14: null,
    success14: false,
    //report comparison update
    loading15: false,
    compreport: null,
    error15: null,
    success15: false,
};

const listOfPatientSlice = createSlice({
    name: 'listpatient',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //patient list
            .addCase(listPatient.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(listPatient.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.patientInfo = payload;
                state.success = true;
            })
            .addCase(listPatient.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            //add patient
            .addCase(addPatient.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(addPatient.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.addInfo = payload;
                state.success2 = true;
            })
            .addCase(addPatient.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.error2 = payload;
                state.success2 = false;
            })
            //delete patient
            .addCase(deletePatient.pending, (state) => {
                state.loading10 = true;
                state.error10 = null;
                state.success10 = false;
            })
            .addCase(deletePatient.fulfilled, (state, { payload }) => {
                state.loading10 = false;
                state.delepntInfo = payload;
                state.success10 = true;
            })
            .addCase(deletePatient.rejected, (state, { payload }) => {
                state.loading10 = false;
                state.error10 = payload;
                state.success10 = false;
            })
            //get patient
            .addCase(getPatient.pending, (state) => {
                state.loading5 = true;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(getPatient.fulfilled, (state, { payload }) => {
                state.loading5 = false;
                state.updateInfo = payload;
                state.success5 = false;
            })
            .addCase(getPatient.rejected, (state, { payload }) => {
                state.loading5 = false;
                state.error5 = payload;
                state.success5 = false;
            })
            //get medication patientMedication
            .addCase(patientMedication.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(patientMedication.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.medicInfo = payload;
                state.success3 = true;
            })
            .addCase(patientMedication.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })
            //add medication
            .addCase(addMedication.pending, (state) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(addMedication.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.addmedicInfo = payload;
                state.success4 = true;
            })
            .addCase(addMedication.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            })
            //get medication
            .addCase(getMedication.pending, (state) => {
                state.loading9 = true;
                state.error9 = null;
                state.success9 = false;
            })
            .addCase(getMedication.fulfilled, (state, { payload }) => {
                state.loading9 = true;
                state.updateMedicInfo = payload;
                state.success9 = false;
            })
            .addCase(getMedication.rejected, (state, { payload }) => {
                state.loading9 = false;
                state.error9 = payload;
                state.success9 = false;
            })
            //save tag
            .addCase(patientSaveTags.pending, (state) => {
                state.loading7 = true;
                state.error7 = null;
                state.success7 = false;
            })
            .addCase(patientSaveTags.fulfilled, (state, { payload }) => {
                state.loading7 = false;
                state.saveTagInfo = payload;
                state.success7 = true;
            })
            .addCase(patientSaveTags.rejected, (state, { payload }) => {
                state.loading7 = false;
                state.error7 = payload;
                state.success7 = false;
            })
            //get tag
            .addCase(getPatientTag.pending, (state) => {
                state.loading8 = true;
                state.error8 = null;
                state.success8 = false;
            })
            .addCase(getPatientTag.fulfilled, (state, { payload }) => {
                state.loading8 = false;
                state.getTagInfo = payload;
                state.success8 = true;
            })
            .addCase(getPatientTag.rejected, (state, { payload }) => {
                state.loading8 = false;
                state.error8 = payload;
                state.success8 = false;
            })
            //delete patient medication
            .addCase(deletePatientMedication.pending, (state) => {
                state.loading10 = true;
                state.error10 = null;
                state.success10 = false;
            })
            .addCase(deletePatientMedication.fulfilled, (state, { payload }) => {
                state.loading10 = false;
                state.delMedicInfo = payload;
                state.error10 = null;
                state.success10 = true;
            })
            .addCase(deletePatientMedication.rejected, (state, { payload }) => {
                state.loading10 = false;
                state.error10 = payload;
                state.success10 = false;
            })
            //remove pnt tag
            .addCase(removePntTag.pending, (state) => {
                state.loading11 = true;
                state.error11 = null;
                state.success11 = false;
            })
            .addCase(removePntTag.fulfilled, (state, { payload }) => {
                state.loading11 = false;
                state.deltaginfo = payload;
                state.error11 = null;
                state.success11 = true;
            })
            .addCase(removePntTag.rejected, (state, { payload }) => {
                state.loading11 = false;
                state.error11 = payload;
                state.success11 = false;
            })
            //patient export
            //remove pnt tag
            .addCase(patientDataExport.pending, (state) => {
                state.loading12 = true;
                state.error12 = null;
                state.success12 = false;
            })
            .addCase(patientDataExport.fulfilled, (state, { payload }) => {
                state.loading12 = false;
                state.pntexportInfo = payload;
                state.success12 = true;
            })
            .addCase(patientDataExport.rejected, (state, { payload }) => {
                state.loading12 = false;
                state.error12 = payload;
                state.success12 = false;
            })
            // patientreqinfo
            .addCase(patientRequestInfo.pending, (state) => {
                state.loading13 = true;
                state.error13 = null;
                state.success13 = false;
            })
            .addCase(patientRequestInfo.fulfilled, (state, { payload }) => {
                state.loading13 = false;
                state.pntreqInfo = payload;
                state.success13 = true;
            })
            .addCase(patientRequestInfo.rejected, (state, { payload }) => {
                state.loading13 = false;
                state.error13 = payload;
                state.success13 = false;
            })
            //comparison report
            .addCase(getComparisonReport.pending, (state) => {
                state.loading14 = true;
                state.error14 = null;
                state.success14 = false;
            })
            .addCase(getComparisonReport.fulfilled, (state, { payload }) => {
                state.loading14 = false;
                state.cmpreport = payload;
                state.success14 = true;
            })
            .addCase(getComparisonReport.rejected, (state, { payload }) => {
                state.loading14 = false;
                state.error14 = payload;
                state.success14 = false;
            })
            // update report
            .addCase(getReportComparison.pending, (state) => {
                state.loading15 = true;
                state.error15 = null;
                state.success15 = false;
            })
            .addCase(getReportComparison.fulfilled, (state, { payload }) => {
                state.loading15 = false;
                state.compreport = payload;
                state.success15 = true;
            })
            .addCase(getReportComparison.rejected, (state, { payload }) => {
                state.loading15 = false;
                state.error15 = payload;
                state.success15 = false;
            });
    },
});
export default listOfPatientSlice.reducer;
