import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const InterpretationMaster = createAsyncThunk('interpretation_master', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/interpretationchoicefield_mdata`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any md_management/add-interpretationchoicefield
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const addInterpretation = createAsyncThunk('add-interpretation_master', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/add-interpretationchoicefield`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any md_management/add-interpretationchoicefield
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const deleteIntchoicefield = createAsyncThunk('remove-intchoicefield', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/remove-interpretationchoicefield`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const AuthmateMdata = createAsyncThunk('interpretation_authmate', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/automate_mdata`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any md_management/add-interpretationchoicefield
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
