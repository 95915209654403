import { createSlice } from '@reduxjs/toolkit'
import { getSearchDetails } from 'store/actions/SearchAction';

const initialState = {
    loading: false,
    Searchdata: null,
    error: null,
    success: false,
}

const searchSlices = createSlice({
    name: 'search',
    initialState,
    reducers: {},
    extraReducers: (builder)=> {
      builder
      // search
      .addCase(getSearchDetails.pending,(state)=>{
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getSearchDetails.fulfilled,(state,{payload})=>{
        state.loading = false;
        state.Searchdata = payload;
        state.success = true;
      })
      .addCase(getSearchDetails.rejected,(state,{payload})=>{
        state.loading = false;
        state.error = payload;
        state.success = false;
      })
    },
});

export default searchSlices.reducer

