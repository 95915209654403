import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, Spin } from 'components/elements/CommonComponent';
import { Divider } from 'components/elements/AntComponent';
import { Button } from 'components/elements/FormComponent';
import { LeftOutlined, RightOutlined, CheckCircleOutlined } from 'components/elements/IconComponent';
import 'assets/styles/billing/creditManager.scss';
import { getCreditPackage } from 'store/actions/billing/creditManagerAction';
import PaymentDetails from './PaymentDetail';
import { useNavigate } from 'react-router-dom';

export default function BuyCredit(props) {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [selectedPackage, setSelectedPackage] = useState(2);
    const { loading9, success9, getCrdPkg } = useSelector((state) => state.creditManager);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success9 : null;
    const [bundle, setBundle] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const totalItems = bundle.length;
    const itemsPerPage = 3;

    const handlePackageClick = (packageId) => {
        setSelectedPackage(packageId);
    };
    useEffect(() => {
        dispatch(getCreditPackage());
        setShowSuccessmsg(true);
    }, [dispatch]);

    useEffect(() => {
        if (successmsg) {
            setBundle(getCrdPkg?.data);
            setShowSuccessmsg(false);
        }
    }, [successmsg]);

    const handlePaymentDetail = () => {
        setIsSelected(true);
    };

    const drawerCallbackFunc = (item) => {
        setIsSelected(item);
    };

    const handleNextClick = () => {
        const nextIndex = Math.min(startIndex + 1, totalItems - itemsPerPage);
        setStartIndex(nextIndex);
    };

    const handlePrevClick = () => {
        const prevIndex = Math.max(startIndex - 1, 0);
        setStartIndex(prevIndex);
    };

    const renderNavigationButtonsLeft = () => {
        const showLeftNav = startIndex !== 0;
        return (
            <div className="navigation-buttons  d-flex justify-content-center align-items-center">
                {showLeftNav && (
                    <Button className="arrow-btn right me-3" onClick={handlePrevClick}>
                        <LeftOutlined />
                    </Button>
                )}
            </div>
        );
    };

    const renderNavigationButtonsRight = () => {
        const showRightNav = startIndex + itemsPerPage < totalItems;
        return (
            <div className="navigation-buttons  d-flex justify-content-center align-items-center">
                {showRightNav && (
                    <Button className="arrow-btn left ms-3" onClick={handleNextClick}>
                        <RightOutlined />
                    </Button>
                )}
            </div>
        );
    };
    useEffect(() => {
        const start = startIndex;
        const end = startIndex + 2;
        const middle = bundle?.slice(start, end)[1];
        setSelectedPackage(middle?.id);
    }, [startIndex, bundle]);

    const handleBack = () => {
        history('/my-credit');
    };

    const resetBuyModal = () => {
        setIsSelected(false);
    };

    return (
        <>
            {!isSelected ? (
                <div className="buyCredit">
                    <div className="row me-1 pe-0 justify-content-between mb-1">
                        <h5 className="ps-3 col my-auto"> Plan & Pricing </h5>
                        {!props?.data?.edit ? (
                            <div className="col-auto ms-auto pe-0">
                                <Button type="primary" className="buyBack-btn my-auto" onClick={handleBack}>
                                    Back
                                </Button>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="buyCreditCont  rounded pt-1">
                        <div className=" position-relative">
                            <p className="ps-4 pt-2 plan-title text-center mb-2"> </p>
                        </div>
                        <div className="bundleCont">
                            <h2 className="text-center p-2 mb-5">Choose your pricing plan</h2>
                            <div className="subscription-packages d-flex justify-content-center">
                                {loading9 ? (
                                    <Spin className="h-100 d-flex justify-content-center align-items-center" />
                                ) : (
                                    <>
                                        {startIndex !== 0 && renderNavigationButtonsLeft()}
                                        <div className="center-content">
                                            <div className="d-flex justify-content-center align-items-center">
                                                {bundle.slice(startIndex, startIndex + itemsPerPage).map((pkg) => (
                                                    <div key={pkg.id} className={`package-item bg-white  ${selectedPackage === pkg.id ? 'selected-package' : ''}`} onClick={() => handlePackageClick(pkg.id)}>
                                                        <div className="d-flex packageCont shadow flex-column">
                                                            <div className="d-flex flex-column align-items-center">
                                                                <h4 className="packageName text-secondary text-capitalize fw-bold">{pkg.package_plan}</h4>
                                                                <h2 className="fw-bold my-3 packagePrice">${pkg.package_price}</h2>
                                                            </div>
                                                            <div className="w-100 d-flex justify-content-center mt-5  btn-cont">
                                                                <Button className="package-btn mb-1" type="primary" onClick={handlePaymentDetail}>
                                                                    Buy Now
                                                                </Button>
                                                            </div>
                                                            <Divider className="packageDivider" />
                                                            <div className="d-flex flex-column align-items-start  amountCont mt-3">
                                                                <ul className="list-Cont ps-4">
                                                                    <li>
                                                                        <p className="mb-1">
                                                                            <CheckCircleOutlined className="pe-2 check-icon" />
                                                                            {pkg.credit_count} Credit
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="mb-1">
                                                                            <CheckCircleOutlined className="pe-2 check-icon" />
                                                                            No time limit
                                                                        </p>
                                                                    </li>
                                                                    <li>{/* <p className='mb-1'><CheckCircleOutlined className='pe-2 check-icon'/>{pkg.credit_count} Credit</p> */}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {startIndex !== totalItems - itemsPerPage && renderNavigationButtonsRight()}
                                    </>
                                )}
                            </div>
                        </div>
                        {/* <div className='bundleCont'>
                                <div className="subscription-packages d-flex justify-content-center align-items-center"> 
                                {loading9 ? (
                                    <Spin className='h-100 d-flex justify-content-center align-items-center'/>
                                ) : (
                                    <>
                                        {startIndex !== 0 && renderNavigationButtonsLeft()}
                                        <div className='center-content'>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                {bundle
                                                    .slice(startIndex, startIndex + itemsPerPage)
                                                    .map((pkg) => (
                                                        <div
                                                            key={pkg.id}
                                                            className={`package-item bg-light  ${selectedPackage === pkg.id ? 'selected-package' : ''}`}
                                                            onClick={() => handlePackageClick(pkg.id)}
                                                        >
                                                            <div className='d-flex packageCont shadow  flex-column justify-content-between align-items-center'>
                                                                <div className='d-flex flex-column align-items-center'>
                                                                    <h3 className='packageName text-capitalize'>{pkg.package_plan}</h3>
                                                                </div>
                                                                <div className='d-flex flex-column align-items-center amountCont'>
                                                                    <p className='packagePrice'>${pkg.package_price}</p>
                                                                    <Divider className='packageDivider' />
                                                                    <p className='packageCredit'>{pkg.credit_count} Credit</p>
                                                                </div>
                                                                <div className='w-100 d-flex justify-content-center'>
                                                                    <Button className='package-btn mb-1' type="primary"  onClick={handlePaymentDetail}>Select</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        {startIndex !== totalItems - itemsPerPage &&  renderNavigationButtonsRight()}
                                    </>
                                )}
                                </div>
                            </div> */}
                    </div>
                </div>
            ) : (
                <PaymentDetails
                    data={{ drawerCallbackFunc: drawerCallbackFunc, planDetail: getCrdPkg, selectedPackage: selectedPackage, edit: props?.data?.edit, closeModal: props?.data?.closeModal, resetBuyModal: resetBuyModal }}
                />
            )}
        </>
    );
}
