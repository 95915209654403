import React from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as EditImg } from '../SVG/edit-icon.svg';
import { ReactComponent as ArchiveImg } from '../SVG/archive-icon.svg';
import { ReactComponent as ViewImg } from '../SVG/view-icon.svg';
import { ReactComponent as ResetImg } from '../SVG/reset-icon.svg';
import { ReactComponent as JobImg } from '../SVG/job-icon.svg';
import { ReactComponent as ErrorJobImg } from '../SVG/error-job-icon.svg';
import { ReactComponent as InvoiceImg } from '../SVG/open-invoice.svg';
import { ReactComponent as ClosedInvImg } from '../SVG/closed-invoice.svg';
import { ReactComponent as TotalAmtImg } from '../SVG/total-amount.svg';
import { ReactComponent as UserImg } from '../SVG/user.svg';
import { ReactComponent as CreditPriceImg } from '../SVG/creidt-price.svg';
import { ReactComponent as WaitingPriceImg } from '../SVG/waiting-approval.svg';
import { ReactComponent as UploadImg } from '../SVG/upload-icon.svg';
import { ReactComponent as DownloadImg } from '../downicon.svg';
import { ReactComponent as EyeImg } from '../SVG/eye-icon.svg';
import { ReactComponent as PayImg } from '../SVG/pay-icon.svg';
import { ReactComponent as MedicImg } from '../MedicineIcon.svg';
import { ReactComponent as SuppImg } from '../supplementIcon.svg';
import { ReactComponent as LyfImg } from '../lifestyleIcon.svg';

const EditIcon = () => <Icon component={EditImg} />;
const ArchiveIcon = () => <Icon component={ArchiveImg} />;
const ViewIcon = () => <Icon component={ViewImg} />;
const ResetIcon = () => <Icon component={ResetImg} />;
const JobIcon = () => <Icon component={JobImg} />;
const ErrorJobIcon = () => <Icon component={ErrorJobImg} />;
const OpenInvoiceIcon = () => <Icon component={InvoiceImg} />;
const ClosedInvoiceIcon = () => <Icon component={ClosedInvImg} />;
const TotalAmountIcon = () => <Icon component={TotalAmtImg} />;
const CreditPriceIcon = () => <Icon component={CreditPriceImg} />;
const UserIcon = () => <Icon component={UserImg} />;
const WaitingIcon = () => <Icon component={WaitingPriceImg} />;
const UploadIcon = () => <Icon component={UploadImg} />;
const DownloadIcon = () => <Icon component={DownloadImg} />;
const EyeIcon = () => <Icon component={EyeImg} />;
const PayIcon = () => <Icon component={PayImg} />;
const MedicIcon = () => <Icon component={MedicImg} />;
const SupplementIcon = () => <Icon component={SuppImg} />;
const LifestyleIcon = () => <Icon component={LyfImg} />;

export {
    EditIcon,
    ArchiveIcon,
    ViewIcon,
    ResetIcon,
    JobIcon,
    ErrorJobIcon,
    MedicIcon,
    UploadIcon,
    DownloadIcon,
    OpenInvoiceIcon,
    ClosedInvoiceIcon,
    TotalAmountIcon,
    UserIcon,
    CreditPriceIcon,
    WaitingIcon,
    EyeIcon,
    PayIcon,
    SupplementIcon,
    LifestyleIcon,
};
