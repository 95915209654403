import React, { useState, useEffect } from 'react';
import { Layout, Menu, Row, Col, Typography, Avatar, Badge, Space, Modal, Divider, format, moment, Popover, Switch } from 'components/elements/AntComponent';
import { Input, Dropdown, Button, Form, DatePicker, Select } from 'components/elements/FormComponent';
import { useDispatch, useSelector, message } from 'components/elements/CommonComponent';
import { DoubleLeftOutlined, DoubleRightOutlined, MenuOutlined, CloseOutlined, SearchOutlined, MenuFoldOutlined, UserOutlined, CaretDownOutlined, LogoutOutlined } from 'components/elements/IconComponent';
import 'assets/styles/layout/layout.scss';
import EEGImage from 'assets/images/brandname.svg';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { menuService } from 'store/actions/menuServiceAction';
import { allPatientTag } from 'store/actions/commonServiceAction';
import { ChangePassword, getUserProfile } from 'store/actions/authActions';
import NotificationImg from 'assets/images/notification.png';
import NotificationDrawer from './NotificationDrawer';
import ResearchMenu from './ResearchMenu';
import ResearchIcon from 'assets/images/research-user.png';
import DoctorIcon from 'assets/images/doctor.png';
import { getAccountData } from 'store/actions/account/accountActions';
import { LifestyleIcon, MedicIcon, SupplementIcon } from 'assets/images/custom-icons/customIcons';
import medicineImg from 'assets/images/MedicineIcon.svg';
import supplementImg from 'assets/images/supplementIcon.svg';
import lifestyleImg from 'assets/images/lifestyleIcon.svg';

const { Text } = Typography;

export default function AppLayout({ children }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let location = useLocation();
    const [form] = Form.useForm();
    const userRole = sessionStorage.getItem('role');
    const { accUpdateInfo, loading3 } = useSelector((state) => state.account);
    const { menu, notificationInfo, loading1 } = useSelector((state) => state.menu);
    const { loading2, success2, error2 } = useSelector((state) => state.auth);
    const updateAccdata = accUpdateInfo != null ? accUpdateInfo.data : [];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { allTags } = useSelector((state) => state.commonData);
    const tagdata = allTags != null ? allTags.data : [];
    const [isSearchOpens, setIsSearchOpens] = useState(false);
    const [isExpand, setIsExpand] = useState(false);
    const [category, setCategory] = useState('');
    const [status, setStatus] = useState('');
    const [inputSearch, setInputSearch] = useState('');
    const [dateRangeFlag, setDateRangeFlag] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [statFlag, setStatFlag] = useState(false);
    const [accFlag, setAccFlag] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    //input
    const [mailSearch, setMailSearch] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sDate, setSdate] = useState('');
    const [eDate, setEdate] = useState('');
    const [tagSearch, setTagSearch] = useState('');
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const passwordPattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const [passModal, setPassModal] = useState(false);

    const [showSuccessmsg, setShowsuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success2 : null;
    const parentMatch = useMatch('/account/*');
    const parentMatch1 = useMatch('/view-request/*');
    const parentMatch2 = useMatch('/patient-list/*');
    const parentMatch3 = useMatch('/released-request/*');
    const parentMatch4 = useMatch('/edf_job_manager/*');
    const [collapsed, setCollapsed] = useState(false);
    const { Header, Content, Sider } = Layout;
    const [hide, setHide] = useState(true);
    const val = menu !== null ? menu.data : [];
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showResearch, setShowResearch] = useState(false);

    const [current, setCurrent] = useState(
        location.pathname === '/' || location.pathname === ''
            ? '/login'
            : parentMatch2
              ? '/patient-list'
              : parentMatch
                ? '/account'
                : parentMatch1
                  ? '/view-request'
                  : parentMatch4
                    ? '/edf_job_manager'
                    : parentMatch3
                      ? '/released-request'
                      : location.pathname
    );

    useEffect(() => {
        if (location.pathname == '/' || location.pathname == '') {
            setCurrent('/login');
        } else {
            if (parentMatch == '/account') {
                setCurrent('/account');
            } else if (parentMatch1 == '/view-request') {
                setCurrent('/view-request');
            } else if (parentMatch2 == '/patient-list') {
                setCurrent('/patient-list');
            } else if (parentMatch3 == '/released-request') {
                setCurrent('/released-request');
            } else if (parentMatch4 == '/edf_job_manager') {
                setCurrent('/edf_job_manager');
            } else {
                setCurrent(location.pathname);
            }
        }
    }, [parentMatch]);

    useEffect(() => {
        sessionStorage.setItem('isResearch', showResearch);
    }, [showResearch]);

    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        window.location.href = '/';
        localStorage.clear();
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsSearchOpens(false);
    };

    useEffect(() => {
        if (location) {
            if (current !== location.pathname) {
                setCurrent(location.pathname);
            }
        }
    }, [location, current]);

    function menuSer() {
        let inputJson = {
            accountid: sessionStorage.getItem('accountid'),
            userid: sessionStorage.getItem('userid'),
        };
        dispatch(menuService(inputJson));
    }

    useEffect(() => {
        menuSer();
    }, []);

    const items1 = val?.map((item) => {
        if (item?.submenuservice?.length === 0) {
            return getItem(
                item?.menu_name,
                `/${item?.menu_url}`,
                <img
                    src={require(`../../assets/images/menu-icons/${item?.menu_iconname}`)}
                    alt="sidemenu-icons"
                    width={18}
                    className={`${item?.menu_url == 'archive-list' ? 'svg' : item?.menu_url == 'edf_job_manager' ? 'svg' : 'png'}`}
                />
            );
        } else {
            return getItem(
                item?.menu_name,
                `/${item?.id}`,
                <img src={require(`../../assets/images/menu-icons/${item?.menu_iconname}`)} alt="sidemenu-icons" width={18} />,
                item?.submenuservice?.map((submenu, i) => {
                    return getItem(submenu?.menu_name, `/${submenu?.menu_url}`);
                })
            );
        }
    });
    const items = [
        {
            label: 'Change Password',
            key: '1',
        },
        {
            label: 'Logout',
            key: '2',
        },
    ];

    const options = [
        { value: 'All', label: 'All' },
        { value: 'Account', label: 'Account' },
        { value: 'Patient', label: 'Patient' },
        { value: 'Service Request', label: 'Service Request' },
    ];

    const statusOption = [
        { value: 'All', label: 'All' },
        { value: 'Request Init', label: 'Request Initation' },
        { value: 'On Review', label: 'On Review' },
        { value: 'Released', label: 'Released' },
    ];

    const handleMenuClick = (e) => {
        if (e.key === '2') {
            showModal();
        }
        if (e.key == '1') {
            setPassModal(true);
        }
    };

    useEffect(() => {
        dispatch(allPatientTag());
    }, [dispatch]);

    const tagOptions = tagdata?.map((tag) => {
        return {
            label: tag.TagName,
            value: tag.TagName,
            key: tag.id,
        };
    });

    const handleonSearchClick = () => {
        setIsFilled(false);
        setCategory(false);
        setIsSearchOpens(true);
        setIsExpand(false);
        setStatFlag(false);
        setAccFlag(false);
        form.setFieldsValue({
            category: '',
            status: '',
            'date-start': '',
            'date-end': '',
            email: '',
            tag: [],
        });
    };

    const onFinish = async (values) => {
        try {
            await form.validateFields();
            let values = form.getFieldsValue();

            let reqData = {
                old_password: values.oldpassword,
                new_password: values.password,
            };
            dispatch(ChangePassword(reqData));
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };

    useEffect(() => {
        if (loading2) {
            setShowsuccessmsg(true);
        }
    }, [loading2]);

    useEffect(() => {
        if (successmsg) {
            message.success('Password Updated Successfully');
            setShowsuccessmsg(false);
            setPassModal(false);
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        }
        if (error2) {
            if (error2?.old_password) {
                message.error(`${error2?.old_password[0]}`);
            } else {
                message.error('Old password is not correct');
            }
        }
    }, [successmsg, error2]);

    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
    });

    const handleChangeCategory = (category) => {
        if (category === 'Service Request') {
            setCategory(category);
            setStatFlag(false);
            setAccFlag(false);
            setIsFilled(true);
        } else if (category === 'Account') {
            setCategory(category);
            setStatFlag(true);
            setStatus('');
            setAccFlag(true);
            setTagSearch('');
            setIsFilled(true);
            form.setFieldsValue({
                status: '',
                tags: [],
            });
        } else if (category === 'All') {
            setCategory(category);
            setStatFlag(true);
            setStatus('');
            setAccFlag(false);
            setIsFilled(true);
            form.setFieldsValue({
                status: '',
                tags: [],
            });
        } else {
            setCategory(category);
            setStatFlag(true);
            setStatus('');
            setAccFlag(false);
            setIsFilled(true);
        }
    };
    const handleStatusChange = (status) => {
        setStatus(status);
        setIsFilled(true);
    };

    const handleEmailSearch = (e) => {
        setMailSearch(e.target.value);
        setIsFilled(true);
    };

    const handleDateChange = (date) => {
        setStartDate(format(new Date(date), 'MM/dd/yyyy'));
        setSdate(format(new Date(date), 'yyyy-MM-dd'));
        setDateRangeFlag(false);
    };
    const handleEndDateChange = (date) => {
        setEndDate(format(new Date(date), 'MM/dd/yyyy'));
        setEdate(format(new Date(date), 'yyyy-MM-dd'));
        setDateRangeFlag(false);
    };

    const onCreate = () => {
        setIsSearchOpens(false);

        if (startDate !== '' && endDate !== '' && category === '') {
            setDateRangeFlag(true);
        } else {
            navigate('/search', {
                state: {
                    inputVal: inputSearch || '',
                    categoryVal: category || '',
                    statusVal: status || '',
                    startVal: sDate || '',
                    endVal: eDate || '',
                    mailSearchVal: mailSearch || '',
                    tagSearchVal: tagSearch.toString(',') || '',
                    refresh: location.pathname === '/search' ? true : false,
                },
            });
            setInputSearch('');
            setCategory('');
            setStatus('');
            setSdate('');
            setEdate('');
            setMailSearch('');
            setTagSearch('');
        }
    };

    const disabledDate = (current) => {
        // Disable all dates before 02/01/2023
        return current && (current < moment(startDate) || current > moment().endOf('day'));
    };
    const disabledDate1 = (current) => {
        return current && current > moment().endOf('day');
    };

    const handleIsExpand = () => {
        setIsExpand(!isExpand);
    };

    const handleTagSearch = (value) => {
        setTagSearch(value);
        setIsFilled(true);
    };

    const handleSearch = (e) => {
        setInputSearch(e.target.value);
        setIsFilled(true);
    };

    const handleInpSearch = (e) => {
        setIsFilled(true);
        if (e.key === 'Enter') {
            setInputSearch('');
            navigate('/search', {
                state: {
                    inputVal: e.target.value || '',
                },
            });
        }
    };
    const searchbyBtn = () => {
        setIsFilled(true);
        setInputSearch('');
        navigate('/search', {
            state: {
                inputVal: inputSearch || '',
            },
        });
    };

    const resetSearch = (e) => {
        if (e.key === 'Backspace' && !e.target.value) {
            setIsFilled(false);
            setInputSearch('');
        }
    };

    const handleCancel1 = () => {
        setPassModal(false);
    };

    function getUser() {
        const inputJson = {
            // userid: 218,
            userid: Number(sessionStorage.getItem('userid')),
        };
        dispatch(getUserProfile(inputJson));
    }

    useEffect(() => {
        if (sessionStorage?.getItem('role') !== 'admin') {
            getUser();
        }
    }, []);

    const showDrawer = () => {
        setOpenDrawer(true);
    };

    const notificationContent = (
        <div>
            {notificationInfo?.data?.slice(0, 5)?.map((item, i) => {
                return (
                    <div key={item.id} className="p-3 d-flex border-bottom">
                        <div className="col-auto me-2">
                            <Avatar size={40} />
                        </div>
                        <div className="col">
                            <h6 className="text-capitalize mb-0">{item.label_name}</h6>
                            <p className="mb-0 text-secondary fs-15">{item.content}</p>
                        </div>
                    </div>
                );
            })}
            {notificationInfo?.data?.length > 5 ? (
                <div className="bg-light p-2 text-center fw-bold txt-primary pointer" onClick={showDrawer}>
                    Show all notifications ({notificationInfo?.data?.length - 5})
                </div>
            ) : (
                ''
            )}
            {!notificationInfo || notificationInfo?.data?.length === 0 ? <div className="p-5 fw-bold text-secondary">Notifications not found</div> : ''}
        </div>
    );

    const closeDrawer = () => {
        setOpenDrawer(false);
    };
    useEffect(() => {
        if (sessionStorage.getItem('role') === 'staff') {
            let accid = sessionStorage.getItem('accountid');
            dispatch(getAccountData(accid));
        }
    }, []);
    const items3 = [
        getItem('Medication', '/recommended-medication', <img src={medicineImg} width={18} />, null),
        getItem(
            'Supplement',
            '/supplement',
            <span className="supp-icon">
                <img src={supplementImg} width={17} />
            </span>,
            null
        ),
        getItem('Lifestyle', '/lifestyle', <img src={lifestyleImg} width={20} />, null),
    ];

    return (
        <Layout className={``}>
            {/* {showResearch ? (
                <Sider className="research-sider shadow-sm">
                    <div className="d-flex justify-content-center mt-2 mb-3 flex-wrap ">
                        <img src={ResearchIcon} alt="research user icon" height="65px" width="auto" className="rounded-circle " />
                        <h6 className="text-lightgray fs-11 fw-500 border-bottom pb-2 px-2">RESEARCHER</h6>
                    </div>
                    <ResearchMenu />
                </Sider>
            ) : ( */}
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} className={`${hide} sider shadow-sm`}>
                <div className="d-flex">
                    <img className="logo" src={EEGImage} alt="EEG Logo" width="80%" />
                    {!hide ? (
                        <div className="text-end">
                            <CloseOutlined onClick={() => setHide(!hide)} className={`${hide} bg-transparent text-end ms-auto text-secondary my-auto closemenu menubar ms-6 p-1 mb-1`} />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className="home ms-3 ps-1">Home</div>
                {/* {showResearch ? (
                    <ResearchMenu />
                ) : ( */}

                <Menu
                    onClick={({ key }) => {
                        if (key === '/account/account-details') {
                            navigate(`/account/account-details`, {
                                state: {
                                    uid: accUpdateInfo?.data?.Acc_GUID || '',
                                    accountID: accUpdateInfo?.data?.id,
                                    activeFrom: accUpdateInfo?.data?.created_on,
                                },
                            });
                        } else if (key === '/invoice-manager') {
                            navigate(key, { state: { tab: userRole == 'admin' ? '1' : '3' } });
                        } else {
                            navigate(key);
                        }
                        setHide(true);
                    }}
                    selectedKeys={[current]}
                    mode="inline"
                    items={showResearch ? items3 : items1}
                ></Menu>
                {/* )} */}
            </Sider>
            <Layout className="site-layout">
                <Header className="topbar">
                    <Row justify="space-between">
                        <Col className="my-auto d-flex ">
                            <div className="my-auto d-flex search-indicator">
                                <Col span={3} className="my-auto menubar-container me-2">
                                    <MenuOutlined onClick={() => setHide(!hide)} className={`${hide} my-auto menubar me-2 p-1 mb-1`} />
                                </Col>
                                <Col span={18} className={`${hide} my-auto search`}>
                                    {showResearch ? (
                                        ' '
                                    ) : (
                                        // <img className="logo" src={EEGImage} alt="EEG Logo" height="40px" />
                                        <>
                                            {sessionStorage.getItem('isAdmin') === 'billing' ? (
                                                ''
                                            ) : (
                                                <Input
                                                    size="large"
                                                    placeholder="Search"
                                                    className="ps-2 py-1 pe-1"
                                                    value={inputSearch || ''}
                                                    onChange={(e) => handleSearch(e)}
                                                    onKeyDown={(e) => handleInpSearch(e)}
                                                    onKeyUp={(e) => resetSearch(e)}
                                                    prefix={<SearchOutlined onClick={() => searchbyBtn()} />}
                                                    suffix={<MenuFoldOutlined className="p-1 rounded-circle" onClick={handleonSearchClick} />}
                                                ></Input>
                                            )}
                                        </>
                                    )}
                                </Col>
                            </div>
                        </Col>
                        <Col className="col-md-3 my-auto ms-auto">
                            <Row className="my-auto">
                                <Col span={6} className="ms-auto my-auto text-center pe-2" style={{ visibility: 'hidden' }}>
                                    {sessionStorage?.getItem('role') === 'staff' ? (
                                        ''
                                    ) : (
                                        <>
                                            <div className="txt-primary fw-bold ">Research</div>
                                            <Switch
                                                size="small"
                                                defaultChecked={showResearch}
                                                onChange={(e) => {
                                                    setShowResearch(e);
                                                    sessionStorage.setItem('isResearch', e);
                                                    navigate('/recommended-medication');
                                                    setCurrent('/recommended-medication');
                                                }}
                                            />
                                        </>
                                    )}
                                </Col>
                                {showResearch ? (
                                    ''
                                ) : (
                                    <Col span={3} className="my-auto notification-popup pointer">
                                        {/* <div onClick={showDrawer}>
                                            <Badge count={notificationInfo?.data?.length}>
                                                <img src={NotificationImg} alt="notification icon " />
                                            </Badge>
                                        </div> */}
                                    </Col>
                                )}
                                <Col className="my-auto user-name">
                                    <Row className="antrow my-auto">
                                        <b className="text-capitalize text-end pe-1">{sessionStorage.getItem('firstname') + ' ' + sessionStorage.getItem('lastname')}</b>
                                    </Row>
                                    <Row className="antrow my-auto">
                                        <Text className="text-uppercase text-secondary fw-500 fs-12 pe-1">
                                            <span className="text-uppercase text-secondary fw-500 pe-1 fs-12">{sessionStorage.getItem('isAdmin')}</span>
                                            {sessionStorage.getItem('role') === 'admin'
                                                ? showResearch
                                                    ? 'Researcher'
                                                    : sessionStorage.getItem('isAdmin') === 'billing'
                                                      ? ''
                                                      : sessionStorage.getItem('isAdmin') === 'technician'
                                                        ? ''
                                                        : sessionStorage.getItem('role')
                                                : sessionStorage.getItem('role')}
                                        </Text>
                                    </Row>
                                </Col>

                                <Col span={3}>
                                    <div className="avatar mx-auto d-flex ">
                                        <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={['click']} className="col-auto ms-1 my-auto">
                                            <Space>
                                                <img src={DoctorIcon} height="37px" className="rounded-circle shadow-sm border border-primary mt-1 ms-2" alt="user icon" />
                                                {/* {showResearch ? (
                                                    <img src={ResearchIcon} height="37px" className="rounded-circle shadow-sm border border-primary mt-1 ant-btn-primary" alt="user icon" />
                                                ) : (
                                                    <img src={DoctorIcon} height="37px" className="rounded-circle shadow-sm border border-primary mt-1" alt="user icon" />
                                                )} */}
                                                {/* <Avatar className="anticonuser text-center col-auto h-75 my-auto mt-1" size={36} icon={<UserOutlined />} /> */}
                                                <CaretDownOutlined />
                                            </Space>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Header>
                <Content className={`${showResearch ? '' : 'me-0'} pipe-wizard `}>
                    <div className={`${showResearch ? ' ' : ' me-3'} mb-3 ms-0`}>{children}</div>
                </Content>
            </Layout>
            <NotificationDrawer data={{ openDrawer, closeDrawer, notifyData: notificationInfo?.data, loading1: loading1 }} />
            {/* logout modal */}
            <Modal
                maskClosable={false}
                title={
                    <div className="d-flex">
                        <LogoutOutlined className="col-auto me-1 my-auto" />
                        <div className="col my-auto">Logout</div>
                    </div>
                }
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        No
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Yes
                    </Button>,
                ]}
            >
                <p>Are you sure you want to Logout?</p>
            </Modal>

            {/* change password */}
            <Modal
                title="Change Password"
                open={passModal}
                onOk={handleOk}
                onCancel={handleCancel1}
                maskClosable={false}
                confirmLoading={loading2}
                footer={[
                    <Button key="back" onClick={handleCancel1}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={onFinish}>
                        Reset
                    </Button>,
                ]}
            >
                <Divider />
                <Form form={form} layout="vertical" name="basic" autoComplete="off">
                    <Form.Item
                        name="oldpassword"
                        label="Old Password"
                        className="w-100"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" className="py-2" autoComplete="old-password" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="New Password"
                        className="w-100"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                            {
                                pattern: passwordPattern,
                                message: 'Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" className="py-2" autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item
                        name="cpassword"
                        className="w-100"
                        label="New Password"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                            validateConfirmPassword,
                        ]}
                    >
                        <Input.Password placeholder="Confirm Password" className="py-2" autoComplete="confirm-password" />
                    </Form.Item>
                </Form>
            </Modal>

            {/* search */}
            <Modal
                forceRender
                title="Advanced Search and Filter"
                className="p-0"
                width={800}
                open={isSearchOpens}
                okText="Submit"
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate();
                        })
                        .catch((info) => {});
                }}
                okButtonProps={{ disabled: !isFilled }}
                onCancel={handleCancel}
            >
                <Divider className="header-divider" />
                <div className="row text-start mb-2">
                    <p className=" col-auto mb-0 py-3">What are you looking for?</p>
                    <div className="searchbox col-md-6 p-1">
                        <i className="bi bi-search ps-1 ms-1 pb-2 pt-1"></i>
                        <Input
                            size="middle"
                            className="ps-2 my-2 py-2 pe-1 rounded"
                            width="260px"
                            value={inputSearch || ''}
                            onKeyUp={(e) => resetSearch(e)}
                            onChange={(e) => handleSearch(e)}
                            prefix={<SearchOutlined />}
                            placeholder="Search Request,Patient or etc"
                        />
                    </div>
                    <div className="col-3 py-2 me-1 ms-1 mt-2">
                        <div className=" expand-btn text-end border float-end " onClick={handleIsExpand}>
                            {isExpand ? <DoubleLeftOutlined className="me-2 ms-2 py-2 text-muted" /> : <DoubleRightOutlined className="me-2 ms-2 py-2 text-muted" />}
                        </div>
                    </div>
                    <div>
                        {/* </Row> */}
                        {isExpand ? (
                            <div className=" mt-4 pe-0 bg-light p-2 mx-0 row rounded">
                                <h5>More Filters</h5>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    initialValues={{
                                        modifier: '',
                                    }}
                                >
                                    <div className="row ">
                                        <div className="col-md-6 ">
                                            <Form.Item label="Category" className="w-100 " name="category">
                                                <Select size="large" value={category} onChange={handleChangeCategory} options={options} />
                                            </Form.Item>
                                            {dateRangeFlag ? <div className="text-danger">Select category to search</div> : ''}
                                        </div>
                                        <div className="col-md-6 ">
                                            <Form.Item label="Status" className="w-100 " name="status">
                                                <Select size="large" value={status} disabled={statFlag ? true : false} onChange={handleStatusChange} options={statusOption} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <Row gutter={26}>
                                        <Col span={12}>
                                            <Form.Item label="Range date" className="w-100 mb-0">
                                                <Row gutter={19}>
                                                    <Col span={11}>
                                                        <Form.Item name="todaterange" className="d-inline-block w-100">
                                                            <DatePicker
                                                                className="form-control col-auto"
                                                                format={dateFormatList}
                                                                autoComplete="off"
                                                                placeholderText="Select date"
                                                                name="startDate"
                                                                disabledDate={disabledDate1}
                                                                // maxDate={new}
                                                                value={new Date(startDate) || null}
                                                                onChange={handleDateChange}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <div className="py-2">to</div>
                                                    <Col span={12}>
                                                        <Form.Item name="fromdaterange" className="d-inline-block w-100">
                                                            <DatePicker
                                                                className="form-control col-auto"
                                                                format={dateFormatList}
                                                                placeholderText="Select date"
                                                                name="endDate"
                                                                autoComplete="off"
                                                                disabledDate={disabledDate}
                                                                // maxDate={new Date()}
                                                                value={endDate}
                                                                onChange={handleEndDateChange}
                                                                showMonthDropdown
                                                                disabled={startDate ? false : true}
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="tag" className="w-100 " name="tags">
                                                <Select size="large" mode="multiple" value={tagSearch || ''} options={tagOptions} disabled={accFlag ? true : false} onChange={handleTagSearch} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <div className="row">
                                        <div className="col-md-6 ">
                                            <Form.Item
                                                label="Email"
                                                className="w-100 "
                                                name="email"
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: 'The input is not valid E-mail!',
                                                    },
                                                ]}
                                            >
                                                <Input className="form-control" value={mailSearch || ''} onChange={(e) => handleEmailSearch(e)} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Modal>
        </Layout>
    );
}
