import { createSlice } from '@reduxjs/toolkit';
import {
  addEdfJob, edfDocList, EdfOutput, getEdfJob, getEdfJobAutomate, getEdfJobId,
  getEdfJobInfo, getResultDocList
} from 'store/actions/pipeline-request/edfProcessActions';

const initialState = {
  loading: false,
  edfDoclistInfo: null,
  error: null,
  success: false,

  loading1: false,
  edfjobvalue: null,
  error1: null,
  success1: false,

  loading2: false,
  edfJobInfo: null,
  error2: null,
  success2: false,

  loading3: false,
  edfDocInfo: null,
  error3: null,
  success3: false,

  loading4: false,
  edfoutput: null,
  error4: null,
  success4: false,

  loading5: false,
  edfJobsInfo: null,
  error5: null,
  success5: false,
  //edf result docu
  loading6: false,
  resultDocInfo: null,
  error6: null,
  success6: false,
  // edf job info automate
  loading7: false,
  edfDoclistInfoUpd: null,
  error7: null,
  success7: false,
}

const associateDocSlice = createSlice({
  name: 'doclistEdfprocess',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //automate/get_edf_job
      .addCase(getEdfJob.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(getEdfJob.fulfilled, (state, { payload }) => {
        state.loading = false
        state.edfjobvalue = payload
        state.success = true
      })
      .addCase(getEdfJob.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })

      .addCase(edfDocList.pending, (state) => {
        state.loading1 = true
        state.error1 = null
        state.success1 = false
      })
      .addCase(edfDocList.fulfilled, (state, { payload }) => {
        state.loading1 = false
        state.edfDoclistInfo = payload
        state.success1 = true
      })
      .addCase(edfDocList.rejected, (state, { payload }) => {
        state.loading1 = false;
        state.error1 = payload;
        state.success1 = false;
      })

      //add job
      .addCase(addEdfJob.pending, (state) => {
        state.loading2 = true;
        state.error2 = null;
        state.success2 = false;
      })
      .addCase(addEdfJob.fulfilled, (state, { payload }) => {
        state.loading2 = false;
        state.edfJobInfo = payload;
        state.success2 = true;

      })
      .addCase(addEdfJob.rejected, (state, { payload }) => {
        state.loading2 = false;
        state.error2 = payload;
        state.success2 = false;
      })

      //get edf job by id
      .addCase(getEdfJobId.pending, (state) => {
        state.loading3 = true;
        state.error3 = null;
        state.success3 = false;
      })
      .addCase(getEdfJobId.fulfilled, (state, { payload }) => {
        state.loading3 = false;
        state.edfDocInfo = payload;
        state.success3 = true;
      })
      .addCase(getEdfJobId.rejected, (state, { payload }) => {
        state.loading3 = false;
        state.error3 = payload;
        state.success3 = false;
      })

      //edf output
      .addCase(EdfOutput.pending, (state) => {
        state.loading4 = true;
        state.error4 = null;
        state.success4 = false;
      })
      .addCase(EdfOutput.fulfilled, (state, { payload }) => {
        state.loading4 = false;
        state.edfoutput = payload;
        state.success4 = true;
      })
      .addCase(EdfOutput.rejected, (state, { payload }) => {
        state.loading4 = false;
        state.error4 = payload;
        state.success4 = false;
      })
      //get edf job info
      .addCase(getEdfJobInfo.pending, (state) => {
        state.loading5 = true;
        state.error5 = null;
        state.success5 = false;
      })
      .addCase(getEdfJobInfo.fulfilled, (state, { payload }) => {
        state.loading5 = false;
        state.edfJobsInfo = payload;
        state.success5 = true;
      })
      .addCase(getEdfJobInfo.rejected, (state, { payload }) => {
        state.loading5 = false;
        state.error5 = payload;
        state.success5 = false;
      })
      //getresult
      .addCase(getResultDocList.pending, (state) => {
        state.loading6 = true;
        state.error6 = null;
        state.success6 = false;
      })
      .addCase(getResultDocList.fulfilled, (state, { payload }) => {
        state.loading6 = false;
        state.resultDocInfo = payload;
        state.success6 = true;
      })
      .addCase(getResultDocList.rejected, (state, { payload }) => {
        state.loading6 = false;
        state.error6 = payload;
        state.success6 = false;
      })
       //edf job info update
       .addCase(getEdfJobAutomate.pending, (state) => {
        state.loading7 = true;
        state.error7 = null;
        state.success7 = false;
      })
      .addCase(getEdfJobAutomate.fulfilled, (state,{payload}) => {
        state.loading7 = false;
        state.edfDoclistInfoUpd = payload;
        state.success7 = true;
      })
      .addCase(getEdfJobAutomate.rejected, (state,{payload}) => {
        state.loading7 = false;
        state.error7 = payload;
        state.success7 = false;
      })
  },
})
export default associateDocSlice.reducer

