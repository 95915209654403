import { createSlice } from '@reduxjs/toolkit';
import { getOrderManagement, requestMedication, saveOrder, adminSaveOrder } from 'store/actions/orderManagementAction';

const initialState = {
  loading: false,
  orderInfo: null,
  error: null,
  success: false,
  //save order
  loading1: false,
  saveorder: null,
  error1: null,
  success1: false,
  //request medication
  loading2:false,
  reqMedic:null,
  error2:null,
  success2:false,
  //save admin order
  loading3:false,
  adSaveOrder:null,
  error3:null,
  success3:false
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder)=> {
    builder
    // get order management
    .addCase(getOrderManagement.pending,(state)=>{
      state.loading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(getOrderManagement.fulfilled,(state,{payload})=>{
      state.loading = false;
      state.orderInfo = payload;
      state.success = true;
    })
    .addCase(getOrderManagement.rejected,(state,{payload})=>{
      state.loading = false;
      state.error = payload;
      state.success = false;
    })
    //save order
    .addCase(saveOrder.pending,(state)=>{
      state.loading1 = true;
      state.error1 = null;
      state.success1 = false;
    })
    .addCase(saveOrder.fulfilled,(state,{payload})=>{
      state.loading1 = false;
      state.saveorder = payload;
      state.success1 = true;
    })
    .addCase(saveOrder.rejected,(state,{payload})=>{
      state.loading1 = false;
      state.error1 = payload;
      state.success1 = false;
    })
    //get medicine details
    .addCase(requestMedication.pending,(state)=>{
      state.loading2 = true;
      state.error2 = null;
      state.success2 = false;
    })
    .addCase(requestMedication.fulfilled,(state,{payload})=>{
      state.loading2 = false;
      state.reqMedic = payload;
      state.success2 = true;
    })
    .addCase(requestMedication.rejected,(state,{payload})=>{
      state.loading2 = false;
      state.error2 = payload;
      state.success2 = false;
    })
    //admin save order 
    .addCase(adminSaveOrder.pending,(state)=>{
      state.loading3 = true;
      state.error3 = null;
      state.success3 = false;
    })
    .addCase(adminSaveOrder.fulfilled,(state,{payload})=>{
      state.loading3 = false;
      state.adSaveOrder = payload;
      state.success3 = true;
    })
    .addCase(adminSaveOrder.rejected,(state,{payload})=>{
      state.loading3 = false;
      state.error3 = payload;
      state.success3 = false;
    })
  },
})
export default orderSlice.reducer

