import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const dashboard = createAsyncThunk('account/dashboard', async ({ AccountID, userid }, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}account/dashboard`, { AccountID, userid });
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// getalluser
export const getAllUsers = createAsyncThunk('account/getaslluser', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}account/getalluser`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
