import React, { useState } from 'react';
import { Menu, Layout } from 'components/elements/AntComponent';
import { useNavigate } from 'react-router-dom';
import MedicIcon from 'assets/images/MedicineIcon.svg';

const { Header, Content, Sider } = Layout;

export default function ResearchLayout({ children }) {
    const navigate = useNavigate();
    const [current, setCurrent] = useState('/recommended-medication');
    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }

    const items1 = [getItem('Medication', '/recommended-medication', <img src={MedicIcon} height="20px" alt="medicine icon" />), getItem('Supplement', '/supplement', null), getItem('Lifestyle', '/lifestyle', null)];

    return (
        <div>
            <Layout>
                <Sider>
                    <Menu
                        onClick={({ key }) => {
                            navigate(key);
                            // setHide(true);
                        }}
                        defaultSelectedKeys={[current]}
                        mode="inline"
                        items={items1}
                    ></Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header className="topbar">
                        <div className="col my-auto user-name">
                            <div className="antrow my-auto">
                                <b className="text-capitalize text-end pe-1">{sessionStorage.getItem('firstname') + ' ' + sessionStorage.getItem('lastname')}</b>
                            </div>
                            <div className="antrow my-auto">
                                <p className="text-uppercase pe-1">{sessionStorage.getItem('role')}</p>
                            </div>
                        </div>
                    </Header>
                    <Content className="pipe-wizard me-0">
                        <div className="mb-3 ms-0 me-3">{children}</div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
}
