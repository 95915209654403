import React,{useState} from 'react';
import twoStepImg from 'assets/images/authenticator1.png';
import { Steps } from 'components/elements/AntComponent';
import {Button, Input} from 'components/elements/FormComponent';
import QrCodeImg from 'assets/images/report-icons/qrCode.png';

export default function MfaStepScreen() {
    const [currentStep,setCurrentStep] = useState("1");

    return (
        <div className='container-fluid h-100'>
            
            <div className='d-flex my-auto mt-5 pt-5'>
                <div>
                    <img src={twoStepImg} alt="step icon" className='authenticator-img' height={300} />
                </div>
                <div className=''>
                    <h2>Multi Factor Authentication</h2>
                    <div className='bg-white p-3 rounded'>
                        <Steps
                            direction="vertical"
                            current={currentStep}
                            onChange={(e) => setCurrentStep(e)}
                            items={[
                                {
                                    title: 'Start by getting the app',
                                    description:"On your phone, install the Microsoft Authenticator app.",
                                    status: 'wait'
                                },
                                {
                                    title: 'Scan QR Code',
                                    description:<div>Use the Microsoft Authenticator app to scan the QR code. This will connect the Microsoft Authenticator app with your account.
                                        <div><img src={QrCodeImg} className='qrcode-scanner' height="100px"/></div>
                                    </div>,
                                    status: 'wait'
                                },
                                {
                                    title: 'Enter Code manually',
                                    description:<div>
                                        Enter temporary OTP from your authenticator app.
                                         <div><Input className='bg-light'/></div>
                                    </div>,
                                    status: 'wait'
                                },
                            ]}
                        />
                        <div>
                            <Button type="primary" className='text-end ms-auto'>Next</Button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}