import { createSlice } from '@reduxjs/toolkit';
import { doclistDetails,deleteDocument,getDocEquitypes ,associateDocument,
  updateAssDoc, documentUpload, documentDownload } from 'store/actions/pipeline-request/wizard-api/associateDocumentActions';

const initialState = {
  loading: false,
  associateDocInfo: null,
  error: null,
  success: false,
  //document upload,
  loading1: false,
  error1: null,
  docupload: null,
  success1: false,
  //document download,
  loading2: false,
  error2: null,
  docdownload: null,
  success2: false,
  //get single document
  loading3: false,
  error3: null,
  doclist: null,
  success3: false,
  // //updateassdoc
  loading5: false,
  updateassdoc: null,
  error5: false,
  success5: false,
  //docequitype
  loading6: false,
  docequitype: null,
  error6: false,
  success6: false,
  //delete doc
  loading8: false,
  docdelete: null,
  error8: false,
  success8: false
}

const associateDocSlice = createSlice({
  name: 'associateDocument',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //associated table details
      .addCase(associateDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(associateDocument.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.associateDocInfo = payload;
        state.success = true;
      })
      .addCase(associateDocument.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.success = false;
      })
      //document uplaod
      .addCase(documentUpload.pending, (state) => {
        state.loading1 = true;
        state.error1 = null;
        state.success1 = false;
      })
      .addCase(documentUpload.fulfilled, (state, { payload }) => {
        state.loading1 = false;
        state.docupload = payload;
        state.success1 = true;
      })
      .addCase(documentUpload.rejected, (state, { payload }) => {
        state.loading1 = false;
        state.error1 = payload;
        state.success1 = false;
      })
      //document download
      .addCase(documentDownload.pending, (state) => {
        state.loading2 = true;
        state.error2 = null;
        state.success2 = false;
      })
      .addCase(documentDownload.fulfilled, (state, { payload }) => {
        state.loading2 = false;
        state.docdownload = payload;
        state.success2 = true;
      })
      .addCase(documentDownload.rejected, (state, { payload }) => {
        state.loading2 = false;
        state.error2 = payload;
        state.success2 = false;
      })
      //single document details
      .addCase(doclistDetails.pending, (state) => {
        state.loading3 = true;
        state.error3 = null;
        state.success3 = false;
      })
      .addCase(doclistDetails.fulfilled, (state, { payload }) => {
        state.loading3 = false;
        state.doclist = payload;
        state.success3 = true;
      })
      .addCase(doclistDetails.rejected, (state, { payload }) => {
        state.loading3 = false;
        state.error3 = payload;
        state.success3 = false;
      })
       //  //updateAssDoc
     .addCase(updateAssDoc.pending,(state)=>{
      state.loading5 = true
      state.error5 = null
      state.success5=false;
    })
    .addCase(updateAssDoc.fulfilled,(state,{payload})=>{
      state.loading5 = false
      state.docequitype = payload;
      state.success5=true;
    })
    .addCase(updateAssDoc.rejected,(state,{payload})=>{
      state.loading5 = false
      state.error5 = payload
      state.success5=false;
    })
 

    //  //docequitypes

     .addCase(getDocEquitypes.pending,(state)=>{
      state.loading6 = true
      state.error6 = null
      state.success6=false;
    })
    .addCase(getDocEquitypes.fulfilled,(state,{payload})=>{
      state.loading6 = false
      state.docequitype = payload;
      state.success6=true;
    })
    .addCase(getDocEquitypes.rejected,(state,{payload})=>{
      state.loading6 = false
      state.error6 = payload
      state.success6=false;
    })
    //delete document
    .addCase(deleteDocument.pending,(state)=>{
      state.loading8 = true
      state.error8 = null
      state.success8=false;
    })
    .addCase(deleteDocument.fulfilled,(state,{payload})=>{
      state.loading8 = false
      state.docdelete = payload;
      state.success8=true;
    })
    .addCase(deleteDocument.rejected,(state,{payload})=>{
      state.loading8 = false
      state.error8 = payload
      state.success8=false;
    })

  },
})
export default associateDocSlice.reducer

