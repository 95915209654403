import { createSlice } from '@reduxjs/toolkit';
import { MedicationMaster } from 'store/actions/master-data/MedicationAction';

const initialState = {
  loading: false,
  medicationInfo: null,
  error: null,
  success: false,
}

const medicationMasterSlice = createSlice({

    name: 'medicationMaster',
    initialState,
    reducers: {},
    extraReducers: (builder)=> {
      builder
      // get medication
      .addCase(MedicationMaster.pending,(state)=>{
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(MedicationMaster.fulfilled,(state,{payload})=>{
        state.loading = false;
        state.medicationInfo = payload;
        state.success = true;
      })
      .addCase(MedicationMaster.rejected,(state,{payload})=>{
        state.loading = false;
        state.error = payload;
        state.success=false;
      })
      
    },
})
export default medicationMasterSlice.reducer

