import { url2 } from 'components/elements/CommonComponent';
import React, { useState, useEffect } from 'react';
import { Image } from 'components/elements/FormComponent';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

export default function CompareTopography(props) {
    const location = useLocation();
    const [images, setImages] = useState([]);
    const [fftAbsEC, setFFtAbsEC] = useState([]);
    const [zAbsEC, setZAbsEC] = useState([]);
    const [zAbsEO, setZAbsEO] = useState([]);
    const [absEC, setAbsEC] = useState([]);
    const [absEO, setAbsEO] = useState([]);
    const [relEC, setRelEC] = useState([]);
    const [relEO, setRelEO] = useState([]);
    const [fftRelEC, setFftRelEC] = useState([]);
    const [fftRelEO, setFftRelEO] = useState([]);

    const topoInfo = props.data.topoInfo || '';

    const fetchAndProcessFiles = async () => {
        const newImages = await Promise.all(
            topoInfo?.eofftabs?.map(async (item, index) => {
                if (item) {
                    try {
                        const response = await axios.get(url2 + item);
                        const page1DataValue = response.data?.page1_data;
                        const page2DataValue = response.data?.page2_data;
                        return [[page1DataValue], [page2DataValue]];
                    } catch (error) {
                        console.error('Error fetching data:', error);
                        return '--';
                    }
                } else {
                    return [['--'], ['--']];
                }
            })
        );

        setImages([newImages]);
    };
    const fftAbsolutePowerEC = async () => {
        const newImages = await Promise.all(
            topoInfo?.ecfftabs?.map(async (item, index) => {
                if (item) {
                    try {
                        const response = await axios.get(url2 + item);
                        const page1DataValue = response.data?.page1_data;
                        const page2DataValue = response.data?.page2_data;
                        return [[page1DataValue], [page2DataValue]];
                    } catch (error) {
                        console.error('Error fetching data:', error);
                        return '--';
                    }
                } else {
                    return [['--'], ['--']];
                }
            })
        );

        setFFtAbsEC([newImages]);
    };
    const zAbsolutePowerEO = async () => {
        setZAbsEO([]);
        const newImages = await Promise.all(
            topoInfo?.eozabs?.map(async (item, index) => {
                const lastDotIndex = item.lastIndexOf('.');
                const fileExtension = item.substring(lastDotIndex);
                if (item) {
                    if (fileExtension == '.txt') {
                        try {
                            const response = await axios.get(url2 + item);
                            const page1DataValue = response.data?.page1_data;
                            const page2DataValue = response.data?.page2_data;
                            return [
                                { data: page1DataValue, type: 'txt' },
                                { data: page2DataValue, type: 'txt' },
                            ];
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            return '--';
                        }
                    } else if (fileExtension == '.png') {
                        return [{ data: url2 + item, type: 'png' }];
                    } else if (fileExtension == '.pdf') {
                        return [{ data: url2 + item, type: 'pdf' }];
                    }
                } else {
                    return [['--'], ['--']];
                }
            })
        );
        setZAbsEO([newImages]);
    };
    const zAbsolutePowerEC = async () => {
        setZAbsEC([]);
        const newImages = await Promise.all(
            topoInfo?.eczabs?.map(async (item, index) => {
                const lastDotIndex = item.lastIndexOf('.');
                const fileExtension = item.substring(lastDotIndex);
                if (item) {
                    if (fileExtension == '.txt') {
                        try {
                            const response = await axios.get(url2 + item);
                            const page1DataValue = response.data?.page1_data;
                            const page2DataValue = response.data?.page2_data;
                            return [
                                { data: page1DataValue, type: 'txt' },
                                { data: page2DataValue, type: 'txt' },
                            ];
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            return '--';
                        }
                    } else if (fileExtension == '.png') {
                        return [{ data: url2 + item, type: 'png' }];
                    } else if (fileExtension == '.pdf') {
                        return [{ data: url2 + item, type: 'pdf' }];
                    }
                } else {
                    return [['--'], ['--']];
                }
            })
        );
        setZAbsEC([newImages]);
    };
    const absolutePowerEO = async () => {
        setAbsEO([]);
        const newImages = await Promise.all(
            topoInfo?.eoabs?.map(async (item, index) => {
                const lastDotIndex = item.lastIndexOf('.');
                const fileExtension = item.substring(lastDotIndex);
                if (item) {
                    if (fileExtension == '.txt') {
                        try {
                            const response = await axios.get(url2 + item);
                            const page1DataValue = response.data?.page1_data;
                            const page2DataValue = response.data?.page2_data;
                            return [
                                { data: page1DataValue, type: 'txt' },
                                { data: page2DataValue, type: 'txt' },
                            ];
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            return '--';
                        }
                    } else if (fileExtension == '.png') {
                        return [{ data: url2 + item, type: 'png' }];
                    } else if (fileExtension == '.pdf') {
                        return [{ data: url2 + item, type: 'pdf' }];
                    }
                } else {
                    return [['--'], ['--']];
                }
            })
        );
        setAbsEO([newImages]);
    };
    const absolutePowerEC = async () => {
        setAbsEC([]);
        const newImages = await Promise.all(
            topoInfo?.ecabs?.map(async (item, index) => {
                const lastDotIndex = item.lastIndexOf('.');
                const fileExtension = item.substring(lastDotIndex);
                if (item) {
                    if (fileExtension == '.txt') {
                        try {
                            const response = await axios.get(url2 + item);
                            const page1DataValue = response.data?.page1_data;
                            const page2DataValue = response.data?.page2_data;
                            return [
                                { data: page1DataValue, type: 'txt' },
                                { data: page2DataValue, type: 'txt' },
                            ];
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            return '--';
                        }
                    } else if (fileExtension == '.png') {
                        return [{ data: url2 + item, type: 'png' }];
                    } else if (fileExtension == '.pdf') {
                        return [{ data: url2 + item, type: 'pdf' }];
                    }
                } else {
                    return [['--'], ['--']];
                }
            })
        );
        setAbsEC([newImages]);
    };
    const relativePowerEO = async () => {
        setRelEO([]);
        const newImages = await Promise.all(
            topoInfo?.eorel?.map(async (item, index) => {
                const lastDotIndex = item.lastIndexOf('.');
                const fileExtension = item.substring(lastDotIndex);
                if (item) {
                    if (fileExtension == '.txt') {
                        try {
                            const response = await axios.get(url2 + item);
                            const page1DataValue = response.data?.page1_data;
                            const page2DataValue = response.data?.page2_data;
                            return [
                                { data: page1DataValue, type: 'txt' },
                                { data: page2DataValue, type: 'txt' },
                            ];
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            return '--';
                        }
                    } else if (fileExtension == '.png') {
                        return [{ data: url2 + item, type: 'png' }];
                    } else if (fileExtension == '.pdf') {
                        return [{ data: url2 + item, type: 'pdf' }];
                    }
                } else {
                    return [['--'], ['--']];
                }
            })
        );
        setRelEO([newImages]);
    };
    const relativePowerEC = async () => {
        setRelEC([]);
        const newImages = await Promise.all(
            topoInfo?.ecrel?.map(async (item, index) => {
                const lastDotIndex = item.lastIndexOf('.');
                const fileExtension = item.substring(lastDotIndex);
                if (item) {
                    if (fileExtension == '.txt') {
                        try {
                            const response = await axios.get(url2 + item);
                            const page1DataValue = response.data?.page1_data;
                            const page2DataValue = response.data?.page2_data;
                            return [
                                { data: page1DataValue, type: 'txt' },
                                { data: page2DataValue, type: 'txt' },
                            ];
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            return '--';
                        }
                    } else if (fileExtension == '.png') {
                        return [{ data: url2 + item, type: 'png' }];
                    } else if (fileExtension == '.pdf') {
                        return [{ data: url2 + item, type: 'pdf' }];
                    }
                } else {
                    return [['--'], ['--']];
                }
            })
        );
        setRelEC([newImages]);
    };
    const fftRelativeEO = async () => {
        setFftRelEO([]);
        const newImages = await Promise.all(
            topoInfo?.eofftrel?.map(async (item, index) => {
                const lastDotIndex = item.lastIndexOf('.');
                const fileExtension = item.substring(lastDotIndex);
                if (item) {
                    if (fileExtension == '.txt') {
                        try {
                            const response = await axios.get(url2 + item);
                            const page1DataValue = response.data?.page1_data;
                            const page2DataValue = response.data?.page2_data;
                            return [
                                { data: page1DataValue, type: 'txt' },
                                { data: page2DataValue, type: 'txt' },
                            ];
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            return '--';
                        }
                    } else if (fileExtension == '.png') {
                        return [{ data: url2 + item, type: 'png' }];
                    } else if (fileExtension == '.pdf') {
                        return [{ data: url2 + item, type: 'pdf' }];
                    }
                } else {
                    return [['--'], ['--']];
                }
            })
        );
        setFftRelEO([newImages]);
    };
    const fftRelativeEC = async () => {
        setFftRelEC([]);
        const newImages = await Promise.all(
            topoInfo?.ecfftrel?.map(async (item, index) => {
                const lastDotIndex = item.lastIndexOf('.');
                const fileExtension = item.substring(lastDotIndex);
                if (item) {
                    if (fileExtension == '.txt') {
                        try {
                            const response = await axios.get(url2 + item);
                            const page1DataValue = response.data?.page1_data;
                            const page2DataValue = response.data?.page2_data;
                            return [
                                { data: page1DataValue, type: 'txt' },
                                { data: page2DataValue, type: 'txt' },
                            ];
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            return '--';
                        }
                    } else if (fileExtension == '.png') {
                        return [{ data: url2 + item, type: 'png' }];
                    } else if (fileExtension == '.pdf') {
                        return [{ data: url2 + item, type: 'pdf' }];
                    }
                } else {
                    return [['--'], ['--']];
                }
            })
        );
        setFftRelEC([newImages]);
    };

    useEffect(() => {
        fetchAndProcessFiles();
        fftAbsolutePowerEC();
        zAbsolutePowerEO();
        zAbsolutePowerEC();
        absolutePowerEO();
        absolutePowerEC();
        relativePowerEC();
        relativePowerEO();
        fftRelativeEO();
        fftRelativeEC();
    }, [topoInfo, url2]);

    return (
        <div className="bg-white p-2 mt-2 topo-compare-cont">
            <div className="d-flex border bg-heading">
                {/* <div className="col-md-2 text-center p-2 border-end">Topography</div> */}
                {topoInfo?.reqNo?.map((item, i) => {
                    return (
                        <div key={i} className="col p-2 text-center border-end">
                            {item}
                        </div>
                    );
                })}
            </div>
            <div className="d-flex border-start border-end border-bottom bg-heading2">
                {/* <div className="col-md-2 text-center p-2 border-end">Released On</div> */}
                {topoInfo?.rdate?.map((item, i) => {
                    const originalDate = new Date(item) || null;
                    return (
                        <div key={i} className="col p-2 text-center border-end">
                            {originalDate?.toLocaleDateString()}
                        </div>
                    );
                })}
            </div>
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">PDR Topography</div>
            <div className="d-flex border-start border-end border-bottom">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">PDR Topography</div>
                </div> */}
                {topoInfo?.pdr?.map((item, i) => {
                    return (
                        <div key={i} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                            {item ? <Image className="col " src={url2 + item} height="auto" /> : <div className="my-auto align-items-center h-100 justify-content-center">--</div>}
                        </div>
                    );
                })}
            </div>
            {/* FFT Absolute power EO*/}
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">FFT Absolute Power (Eye Open)</div>
            <div className="d-flex border-start border-end border-bottom ">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">FFT Absolute Power (Eye Open)</div>
                </div> */}
                {images?.map((val, index) => {
                    return val.map((itm, i) => {
                        return (
                            <div key={index + i} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                <div>
                                    {itm[0] == '--' ? (
                                        '--'
                                    ) : itm[1] == '--' ? (
                                        '--'
                                    ) : (
                                        <div className="row m-0">
                                            <div className="col-md-12">
                                                <Image src={`data:image/jpeg;base64,${itm[0]}`} width="auto" height="auto" />
                                            </div>
                                            <div className="col-md-12">
                                                <Image src={`data:image/jpeg;base64,${itm[1]}`} width="auto" height="auto" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    });
                })}
            </div>
            {/* FFT Absolute Power EC */}
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">FFT Absolute Power (Eye Closed)</div>
            <div className="d-flex border-start border-end border-bottom">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">FFT Absolute Power (Eye Closed)</div>
                </div> */}
                {fftAbsEC?.map((val, index) => {
                    return val.map((itm, i) => {
                        return (
                            <div key={index + i} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                <div>
                                    {itm[0] == '--' ? (
                                        '--'
                                    ) : itm[1] == '--' ? (
                                        '--'
                                    ) : (
                                        <div className="row m-0">
                                            <div className="col-md-12">
                                                <Image src={`data:image/jpeg;base64,${itm[0]}`}  width="auto" height="auto"/>
                                            </div>
                                            <div className="col-md-12">
                                                <Image src={`data:image/jpeg;base64,${itm[1]}`}  width="auto" height="auto" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    });
                })}
            </div>
            {/* Z-Score FFT Absolute power EO */}
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">Z-Score FFT Absolute Power (Eye Open)</div>
            <div className="d-flex border-start border-end border-bottom">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">Z-Score FFT Absolute Power (Eye Open)</div>
                </div> */}
                {zAbsEO?.map((val, index) => {
                    return val.map((itm, i) => {
                        return (
                            <div key={index + i} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                <div>
                                    {itm[0] == '--' ? (
                                        '--'
                                    ) : itm[1] == '--' ? (
                                        '--'
                                    ) : (
                                        <div className="row m-0">
                                            {itm[0].type == 'png' ? (
                                                <Image src={itm[0]?.data} width="auto" height="auto" />
                                            ) : itm[0].type == 'txt' ? (
                                                <>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[0].data}`} width="auto" height="auto" />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[1].data}`} width="auto" height="auto" />
                                                    </div>
                                                </>
                                            ) : (
                                                <DocViewer
                                                    className="col-md-4"
                                                    documents={[{ uri: itm[0]?.data }]}
                                                    pluginRenderers={DocViewerRenderers}
                                                    theme={{
                                                        primary: '#5a53b2',
                                                        secondary: '#ffffff',
                                                        tertiary: 'rgb(102 96 176 / 40%)',
                                                        textPrimary: '#ffffff',
                                                        textSecondary: '#5a53b2',
                                                        textTertiary: '#ffffff',
                                                        disableThemeScrollbar: true,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    });
                })}
            </div>
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">Z-Score FFT Absolute Power (Eye Closed)</div>
            <div className="d-flex border-start border-end border-bottom">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">Z-Score FFT Absolute Power (Eye Closed)</div>
                </div> */}
                {zAbsEC?.map((val, index) => {
                    return val.map((itm, i) => {
                        return (
                            <div key={index + 11 + i} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                <div>
                                    {itm[0] == '--' ? (
                                        '--'
                                    ) : itm[1] == '--' ? (
                                        '--'
                                    ) : (
                                        <div className="row m-0">
                                            {itm[0].type == 'png' ? (
                                                <Image src={itm[0]?.data} width="100%" height="auto" />
                                            ) : itm[0].type == 'txt' ? (
                                                <>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[0].data}`} width="auto" height="auto" />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[1].data}`} width="auto" height="auto"/>
                                                    </div>
                                                </>
                                            ) : (
                                                <DocViewer
                                                    className="col-md-4"
                                                    documents={[{ uri: itm[0]?.data }]}
                                                    pluginRenderers={DocViewerRenderers}
                                                    theme={{
                                                        primary: '#5a53b2',
                                                        secondary: '#ffffff',
                                                        tertiary: 'rgb(102 96 176 / 40%)',
                                                        textPrimary: '#ffffff',
                                                        textSecondary: '#5a53b2',
                                                        textTertiary: '#ffffff',
                                                        disableThemeScrollbar: true,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    });
                })}
            </div>
            {/* Absolute Power EO */}
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">Absolute Power (Eye Open)</div>
            <div className="d-flex border-start border-end border-bottom ">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">Absolute Power (Eye Open)</div>
                </div> */}
                {absEO?.map((val, index) => {
                    return val.map((itm, i) => {
                        return (
                            <div key={index + i} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                <div>
                                    {itm[0] == '--' ? (
                                        '--'
                                    ) : itm[1] == '--' ? (
                                        '--'
                                    ) : (
                                        <div className="row m-0 text-center justify-content-center">
                                            {itm[0].type == 'png' ? (
                                                <Image src={itm[0]?.data} width="100%" height="auto" className="mx-auto" />
                                            ) : itm[0].type == 'txt' ? (
                                                <>
                                                    <div className="col-md-12 text-center">
                                                        <Image src={`data:image/jpeg;base64,${itm[0].data}`} width="100%" height="auto" />
                                                    </div>
                                                    <div className="col-md-12 text-center">
                                                        <Image src={`data:image/jpeg;base64,${itm[1].data}`} width="100%" height="auto" />
                                                    </div>
                                                </>
                                            ) : (
                                                <DocViewer
                                                    className="col-md-4"
                                                    documents={[{ uri: itm[0]?.data }]}
                                                    pluginRenderers={DocViewerRenderers}
                                                    theme={{
                                                        primary: '#5a53b2',
                                                        secondary: '#ffffff',
                                                        tertiary: 'rgb(102 96 176 / 40%)',
                                                        textPrimary: '#ffffff',
                                                        textSecondary: '#5a53b2',
                                                        textTertiary: '#ffffff',
                                                        disableThemeScrollbar: true,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    });
                })}
            </div>
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">Absolute Power (Eye Closed)</div>
            <div className="d-flex border-start border-end border-bottom">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">Absolute Power (Eye Closed)</div>
                </div> */}
                {absEC?.map((val, index) => {
                    return val.map((itm, i) => {
                        return (
                            <div key={index + i} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                <div>
                                    {itm[0] == '--' ? (
                                        '--'
                                    ) : itm[1] == '--' ? (
                                        '--'
                                    ) : (
                                        <div className="row m-0 justify-content-center">
                                            {itm[0].type == 'png' ? (
                                                <Image src={itm[0]?.data} width="100%" height="auto" />
                                            ) : itm[0].type == 'txt' ? (
                                                <>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[0].data}`} width="100%" height="auto" />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[1].data}`} width="100%" height="auto" />
                                                    </div>
                                                </>
                                            ) : (
                                                <DocViewer
                                                    className="col-md-4"
                                                    documents={[{ uri: itm[0]?.data }]}
                                                    pluginRenderers={DocViewerRenderers}
                                                    theme={{
                                                        primary: '#5a53b2',
                                                        secondary: '#ffffff',
                                                        tertiary: 'rgb(102 96 176 / 40%)',
                                                        textPrimary: '#ffffff',
                                                        textSecondary: '#5a53b2',
                                                        textTertiary: '#ffffff',
                                                        disableThemeScrollbar: true,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    });
                })}
            </div>
            {/* Relative Power EO */}
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">Relative Power (Eye Open)</div>
            <div className="d-flex border-start border-end border-bottom ">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">Relative Power (Eye Open)</div>
                </div> */}
                {relEO?.map((val, index) => {
                    return val.map((itm, i) => {
                        return (
                            <div key={index + i} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                <div>
                                    {itm[0] == '--' ? (
                                        '--'
                                    ) : itm[1] == '--' ? (
                                        '--'
                                    ) : (
                                        <div className="row m-0 justify-content-center">
                                            {itm[0].type == 'png' ? (
                                                <Image src={itm[0]?.data} width="100%" height="auto" />
                                            ) : itm[0].type == 'txt' ? (
                                                <>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[0].data}`} width="auto" height="auto" />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[1].data}`} width="auto" height="auto" />
                                                    </div>
                                                </>
                                            ) : (
                                                <DocViewer
                                                    className="col-md-4"
                                                    documents={[{ uri: itm[0]?.data }]}
                                                    pluginRenderers={DocViewerRenderers}
                                                    theme={{
                                                        primary: '#5a53b2',
                                                        secondary: '#ffffff',
                                                        tertiary: 'rgb(102 96 176 / 40%)',
                                                        textPrimary: '#ffffff',
                                                        textSecondary: '#5a53b2',
                                                        textTertiary: '#ffffff',
                                                        disableThemeScrollbar: true,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    });
                })}
            </div>
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">Relative Power (Eye Closed)</div>
            <div className="d-flex border-start border-end border-bottom">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">Relative Power (Eye Closed)</div>
                </div> */}
                {relEC?.map((val, index) => {
                    return val.map((itm, i) => {
                        return (
                            <div key={index + i} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                <div>
                                    {itm[0] == '--' ? (
                                        '--'
                                    ) : itm[1] == '--' ? (
                                        '--'
                                    ) : (
                                        <div className="row m-0 justify-content-center">
                                            {itm[0].type == 'png' ? (
                                                <Image src={itm[0]?.data} width="100%" height="auto" />
                                            ) : itm[0].type == 'txt' ? (
                                                <>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[0].data}`} width="auto" height="auto" />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[1].data}`} width="auto" height="auto" />
                                                    </div>
                                                </>
                                            ) : (
                                                <DocViewer
                                                    className="col-md-4"
                                                    documents={[{ uri: itm[0]?.data }]}
                                                    pluginRenderers={DocViewerRenderers}
                                                    theme={{
                                                        primary: '#5a53b2',
                                                        secondary: '#ffffff',
                                                        tertiary: 'rgb(102 96 176 / 40%)',
                                                        textPrimary: '#ffffff',
                                                        textSecondary: '#5a53b2',
                                                        textTertiary: '#ffffff',
                                                        disableThemeScrollbar: true,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    });
                })}
            </div>
            {/* FFT Relative Power EO */}
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">FFT Relative Power (Eye Open)</div>
            <div className="d-flex border-start border-end border-bottom ">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">FFT Relative Power (Eye Open)</div>
                </div> */}
                {fftRelEO?.map((val, index) => {
                    return val.map((itm, i) => {
                        return (
                            <div key={index + i} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                <div>
                                    {itm[0] == '--' ? (
                                        '--'
                                    ) : itm[1] == '--' ? (
                                        '--'
                                    ) : (
                                        <div className="row m-0 justify-content-center">
                                            {itm[0].type == 'png' ? (
                                                <Image src={itm[0]?.data}  width="auto" height="auto"/>
                                            ) : itm[0].type == 'txt' ? (
                                                <>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[0].data}`} width="auto" height="auto" />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[1].data}`} width="auto" height="auto"/>
                                                    </div>
                                                </>
                                            ) : (
                                                <DocViewer
                                                    className="col-md-4"
                                                    documents={[{ uri: itm[0]?.data }]}
                                                    pluginRenderers={DocViewerRenderers}
                                                    theme={{
                                                        primary: '#5a53b2',
                                                        secondary: '#ffffff',
                                                        tertiary: 'rgb(102 96 176 / 40%)',
                                                        textPrimary: '#ffffff',
                                                        textSecondary: '#5a53b2',
                                                        textTertiary: '#ffffff',
                                                        disableThemeScrollbar: true,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    });
                })}
            </div>
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">FFT Relative Power (Eye Closed)</div>
            <div className="d-flex border-start border-end border-bottom">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">FFT Relative Power (Eye Closed)</div>
                </div> */}
                {fftRelEC?.map((val, index) => {
                    return val.map((itm, i) => {
                        return (
                            <div key={index + i} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                                <div>
                                    {itm[0] == '--' ? (
                                        '--'
                                    ) : itm[1] == '--' ? (
                                        '--'
                                    ) : (
                                        <div className="row m-0 justify-content-center">
                                            {itm[0].type == 'png' ? (
                                                <Image src={itm[0]?.data} width="100%" height="auto" />
                                            ) : itm[0].type == 'txt' ? (
                                                <>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[0].data}`} width="auto" height="auto" />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <Image src={`data:image/jpeg;base64,${itm[1].data}`} width="auto" height="auto" />
                                                    </div>
                                                </>
                                            ) : (
                                                <DocViewer
                                                    className="col-md-4"
                                                    documents={[{ uri: itm[0]?.data }]}
                                                    pluginRenderers={DocViewerRenderers}
                                                    theme={{
                                                        primary: '#5a53b2',
                                                        secondary: '#ffffff',
                                                        tertiary: 'rgb(102 96 176 / 40%)',
                                                        textPrimary: '#ffffff',
                                                        textSecondary: '#5a53b2',
                                                        textTertiary: '#ffffff',
                                                        disableThemeScrollbar: true,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    });
                })}
            </div>
            {/* EDF Graph */}
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">Result EDF Graph (Eye Open)</div>
            <div className="d-flex border-start border-end border-bottom ">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">Result EDF Graph (Eye Open)</div>
                </div> */}
                {topoInfo?.eograph?.map((item, i) => {
                    return (
                        <div key={i + 4353} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                            {item ? <Image className="col " src={url2 + item} width="100%" height="auto" /> : '--'}
                        </div>
                    );
                })}
            </div>
            <div className="bg-light border-start border-end border-bottom p-2 fw-500">Result EDF Graph (Eye Closed)</div>
            <div className="d-flex border-start border-end border-bottom">
                {/* <div className="col-md-2 text-start p-2 border-end">
                    <div className="my-auto">Result EDF Graph (Eye Closed)</div>
                </div> */}
                {topoInfo?.ecgraph?.map((item, i) => {
                    return (
                        <div key={i + 133} className={`${topoInfo?.dlen==3?'col-md-4':'col-md-6'} p-2 text-center border-end ${(i + 1) % 2 == 0 ? 'bg-white' : 'bg-highlight-cmp'}`}>
                            {item ? <Image className="col " src={url2 + item} width="100%" height="auto" /> : '--'}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
