import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const lifestyleMaster = createAsyncThunk('lifestyle_master', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/lifestyle_mdata/`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any patient/add-lifestyle/
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const addLifestyle = createAsyncThunk('add_lifestyle', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/add-lifestyle/`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const deleteLifestyle = createAsyncThunk('remove-lifestyle', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}patient/remove-lifestyle`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
