import { createSlice } from '@reduxjs/toolkit';
import { addLifestyle, deleteLifestyle, lifestyleMaster } from 'store/actions/master-data/LifestyleActions';

const initialState = {
  loading: false,
  lifestyleInfo: null,
  error: null,
  success: false,
   //addlifestyle
   loading1:false,
   addlifestyleInfo:null,
   error1:null,
   success1:false,
   //removelifestyle
   loading2:false,
   rmvlife:null,
   error2:null,
   success2:false
}

const lifestyleMasterSlice = createSlice({
  name: 'lifestyleMaster',
  initialState,
  reducers: {},
  extraReducers: (builder)=>{
    builder
    //get lifestyle
    .addCase(lifestyleMaster.pending,(state)=>{
      state.loading = true;
      state.error = null;
      state.success=false;
    })
    .addCase(lifestyleMaster.fulfilled,(state,{payload})=>{
      state.loading = false;
      state.lifestyleInfo = payload;
      state.success=true;
    })
    .addCase(lifestyleMaster.rejected,(state,{payload})=>{
      state.loading = false;
      state.error = payload;
      state.success=false;
    })
    //add lifestyle
    .addCase(addLifestyle.pending,(state)=>{
      state.loading1 = true;
      state.error1 = null;
      state.success1=false;
    })
    .addCase(addLifestyle.fulfilled,(state,{payload})=>{
      state.loading1 = false
      state.addlifestyleInfo = payload
      state.success1=true;
    })
    .addCase(addLifestyle.rejected,(state,{payload})=>{
      state.loading1 = false
      state.error1= payload
      state.success1=false;
    })
    //delete lifestyle
    .addCase(deleteLifestyle.pending,(state)=>{
      state.loading2 = true;
      state.error2 = null;
      state.success2=false;
    })
    .addCase(deleteLifestyle.fulfilled,(state,{payload})=>{
      state.loading2 = false
      state.rmvlife = payload
      state.success2=true;
    })
    .addCase(deleteLifestyle.rejected,(state,{payload})=>{
      state.loading2 = false
      state.error2= payload
      state.success2=false;
    })
  },
})
export default lifestyleMasterSlice.reducer

