import { createSlice } from '@reduxjs/toolkit';
import { userLogin, SendEmail, ChangePassword, confirmPass, VerifyToken, getUserProfile, changeDefaultPass } from 'store/actions/authActions';

const initialState = {
    loading: false,
    userInfo: null,
    error: null,
    success: false,
    //reset-password
    loading1: false,
    resetInfo: null,
    error1: null,
    success1: false,
    //changepassword
    loading2: false,
    passInfo: null,
    error2: null,
    success2: false,
    //reset-pass
    loading3: false,
    cpassInfo: null,
    error3: null,
    success3: false,
    //confirm-pass
    loading4: false,
    tokenInfo: null,
    error4: null,
    success4: false,
    //verify-token
    loading5: false,
    userProfileInfo: null,
    error5: null,
    success5: false,
    //change default password
    loading6: false,
    defaultPassInfo: null,
    error6: null,
    success6: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(userLogin.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.userInfo = payload;
                state.success = true;
            })
            .addCase(userLogin.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            //resetpassword
            .addCase(SendEmail.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(SendEmail.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.resetInfo = payload;
                state.success1 = true;
            })
            .addCase(SendEmail.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.error1 = payload;
                state.success1 = false;
            })
            //changePassword
            .addCase(ChangePassword.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(ChangePassword.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.passInfo = payload;
                state.success2 = true;
            })
            .addCase(ChangePassword.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.error2 = payload;
                state.success2 = false;
            })
            //reset-pass
            .addCase(confirmPass.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(confirmPass.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.cpassInfo = payload;
                state.success3 = true;
            })
            .addCase(confirmPass.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })
            //verify token
            .addCase(VerifyToken.pending, (state) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(VerifyToken.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.tokenInfo = payload;
                state.success4 = true;
            })
            .addCase(VerifyToken.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            })
            .addCase(getUserProfile.pending, (state) => {
                state.loading5 = true;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(getUserProfile.fulfilled, (state, { payload }) => {
                state.loading5 = false;
                state.userProfileInfo = payload;
                state.success5 = true;
            })
            .addCase(getUserProfile.rejected, (state, { payload }) => {
                state.loading5 = false;
                state.error5 = payload;
                state.success5 = false;
            })
            .addCase(changeDefaultPass.pending, (state) => {
                state.loading6 = true;
                state.error6 = null;
                state.success6 = false;
            })
            .addCase(changeDefaultPass.fulfilled, (state, { payload }) => {
                state.loading6 = false;
                state.defaultPassInfo = payload;
                state.success6 = true;
            })
            .addCase(changeDefaultPass.rejected, (state, { payload }) => {
                state.loading6 = false;
                state.error6 = payload;
                state.success6 = false;
            });
    },
});

export default authSlice.reducer;
