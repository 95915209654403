import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Col } from 'components/elements/AntComponent';
import { Form, Button, Input, } from 'components/elements/FormComponent';
import { message, useDispatch, useSelector } from 'components/elements/CommonComponent';
import LoginImage from 'assets/images/login-image.png';
import EEGImage from 'assets/images/brandname.png';
import { SendEmail } from 'store/actions/authActions';
import 'assets/styles/auth/login.scss';

export default function SendEmailForm() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const url = window.origin;
    const { success1, loading1, error1 } = useSelector(state => state.auth);
    const [showSuccessmsg, setShowsuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success1 : null;

    useEffect(() => {
        if (successmsg) {
            navigate('/email-sent');
            setShowsuccessmsg(false);
        }
        if (error1) {
            if (error1?.data) {
                message.error(error1?.data);
            }
            else {
                // toast.error("Email couldn't be sent");
                message.error("Email couldn't be sent");
            }

        }
    }, [successmsg, error1]);

    const onFinish = (values) => {
        let reqData = {
            "email": values?.email?.trim(),
            "domain": `${url}`
        }
        dispatch(SendEmail(reqData));
        setShowsuccessmsg(true);
    }

    return (
        <div className='row login-cont w-100'>
            <Col flex="1 1 40%" offset={2} className="column">
                <img src={LoginImage} width="95%" height="95%" alt="EEG Login" />
            </Col>
            <div className='col-md-5 my-auto'>
                <div className="site-card-border-less-wrapper">
                    <img src={EEGImage} width="60%" height="100" alt="brand name" className='mb-5'></img>
                    <Form
                        form={form}
                        layout="vertical"
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="email"
                            label="Please Enter Your User Email"
                            className='mb-4 pt-5 text-start'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                }, {
                                    type: 'email',
                                    message: "Enter valid email address"

                                },
                            ]}
                        >
                            <Input placeholder='Email' />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                span: 24,
                            }}
                        > <Button block className='btn-save btn' htmlType="submit" size="large" loading={loading1}>
                                Reset
                            </Button>
                        </Form.Item>

                    </Form>
                    <div className='text-center mt-3 pe-1'>
                        <Link to="/login" className='text-decoration-none '>Back to Login ?</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}