import { createSlice } from '@reduxjs/toolkit';
import { dashboard, getAllUsers } from 'store/actions/dashboardActions';

const initialState = {
    loading: false,
    accInfo: null,
    error: null,
    success: false,
    loading1: false,
    userallInfo: null,
    error1: null,
    success1: false,
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(dashboard.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(dashboard.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.accInfo = payload;
                state.success = true;
            })
            .addCase(dashboard.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            .addCase(getAllUsers.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(getAllUsers.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.userallInfo = payload;
                state.success1 = true;
            })
            .addCase(getAllUsers.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.error1 = payload;
                state.success1 = false;
            });
    },
});
export default dashboardSlice.reducer;
