import { createSlice } from '@reduxjs/toolkit';
import { addSupplement, deleteSupplement, supplementMaster } from 'store/actions/master-data/SupplementActions';

const initialState = {
  loading: false,
  supplementInfo: null,
  error: null,
  success: false,
  //addsupplement
  loading1: false,
  addSupplementInfo: null,
  error1: null,
  success1: false,
  //removesupplement
  loading2: false,
  rmvsupp: null,
  error2: null,
  success2: false
}

const supplementMasterSlice = createSlice({
  name: 'supplementMaster',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get supplement
      .addCase(supplementMaster.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(supplementMaster.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.supplementInfo = payload;
        state.success = true;
      })
      .addCase(supplementMaster.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.success = false;
      })
      //add supplement
      .addCase(addSupplement.pending, (state) => {
        state.loading1 = true;
        state.error1 = null;
        state.success1 = false;
      })
      .addCase(addSupplement.fulfilled, (state, { payload }) => {
        state.loading1 = false
        state.addSupplementInfo = payload
        state.success1 = true;
      })
      .addCase(addSupplement.rejected, (state, { payload }) => {
        state.loading1 = false
        state.error1 = payload
        state.success1 = false;
      })
      //delete supplement
      .addCase(deleteSupplement.pending, (state) => {
        state.loading2 = true;
        state.error2 = null;
        state.success2 = false;
      })
      .addCase(deleteSupplement.fulfilled, (state, { payload }) => {
        state.loading2 = false
        state.rmvsupp = payload
        state.success2 = true;
      })
      .addCase(deleteSupplement.rejected, (state, { payload }) => {
        state.loading2 = false
        state.error2 = payload
        state.success2 = false;
      })
  },
})
export default supplementMasterSlice.reducer

