import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const listOfJobManager = createAsyncThunk('jobmanager', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}docrepo/edf_list_md`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getErrorJobs = createAsyncThunk('errorjob', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}automate/get_reqjobs`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//automate/get_reqjob
export const getListOfJobManager = createAsyncThunk('jobmanagesr', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}automate/get_reqjobs`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const edfJobManager = createAsyncThunk('edfjobmanager', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}automate/get_edf_job/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const addEdfSetting = createAsyncThunk('addedfsetting', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/add-jobconfig`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getEdfSetting = createAsyncThunk('getedfsetting', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/jobconfig_mdata`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const resetEDFJob = createAsyncThunk('reset-job', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}automate/edfjob_reset/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const cancelEDFjob = createAsyncThunk('cancel-job', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}automate/edfjob_cancel/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response;
        }
    }
});

export const cancelAllEDFjob = createAsyncThunk('cancel-alljob', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}automate/edfjob_cancel`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const resetAllEDFjob = createAsyncThunk('reset-alljob', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}automate/edfjob_reset`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getStepStatus = createAsyncThunk('steps-checking', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}automate/steps_checking/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});
// api/automate/cars_job
export const getEnableEdfAnalyzer = createAsyncThunk('get-edf-analyzer', async (payload) => {
    try {
        const { data } = await ax.post(`${backendURL}automate/cars_job`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});
// automate/cars_path_info/2110
export const getEdfAnalyzerTopos = createAsyncThunk('get-edf-analyzer-topography', async (id) => {
    try {
        const { data } = await ax.get(`${backendURL}automate/cars_path_info/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});
// automate/get_edf_analyzerjobs
export const getEdfAnalyzerJobs = createAsyncThunk('get-edf-analyzer-jobs', async (payload) => {
    try {
        const { data } = await ax.post(`${backendURL}automate/get_edf_analyzerjobs`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});