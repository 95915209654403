import { createSlice } from '@reduxjs/toolkit';
import { diagnosisMaster,addDiagnosis, deleteDiagnosis } from 'store/actions/master-data/DiagnosisActions';

const initialState = {
  loading: false,
  diagnosisInfo: null,
  error: null,
  success: false,
  //adddiagnosis
  loading1:false,
  addDiagnosisInfo:null,
  error1:null,
  success1:false,
  //removediagnosis
  loading2:false,
  rmvdiag:null,
  error2:null,
  success2:false
}

const diagnosisMasterSlice = createSlice({
  name: 'diagnosisMaster',
  initialState,
  reducers: {},
  extraReducers: (builder)=> {
    builder
    // get diagnosis
    .addCase(diagnosisMaster.pending,(state)=>{
      state.loading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(diagnosisMaster.fulfilled,(state,{payload})=>{
      state.loading = false;
      state.diagnosisInfo = payload;
      state.success = true;
    })
    .addCase(diagnosisMaster.rejected,(state,{payload})=>{
      state.loading = false;
      state.error = payload;
      state.success=false;
    })
    //add diagnosis
    .addCase(addDiagnosis.pending,(state)=>{
      state.loading1 = true;
      state.error1 = null;
      state.success1=false;
    })
    .addCase(addDiagnosis.fulfilled,(state,{payload})=>{
      state.loading1 = false
      state.addDiagnosisInfo = payload
      state.success1=true;
    })
    .addCase(addDiagnosis.rejected,(state,{payload})=>{
      state.loading1 = false
      state.error1= payload
      state.success1=false;
    })
    //delete diagnosis
    .addCase(deleteDiagnosis.pending,(state)=>{
      state.loading2 = true;
      state.error2 = null;
      state.success2=false;
    })
    .addCase(deleteDiagnosis.fulfilled,(state,{payload})=>{
      state.loading2 = false
      state.rmvdiag = payload
      state.success2=true;
    })
    .addCase(deleteDiagnosis.rejected,(state,{payload})=>{
      state.loading2 = false
      state.error2= payload
      state.success2=false;
    })
  },
})
export default diagnosisMasterSlice.reducer

