import { createSlice } from '@reduxjs/toolkit';
import { pipelineRequest, resultUpload, resultList, approveReq, archiveRequest, validateRequest } from 'store/actions/pipeline-request/pipelinereqActions';

const initialState = {
    loadingz: false,
    reqInfo: null,
    error: null,
    success: false,
    //result upload data
    loading7: false,
    resultInfo: null,
    error7: null,
    success7: false,
    //result list
    loading2: false,
    reslistInfo: null,
    error2: null,
    success2: false,
    //approve request
    loading3: false,
    apprD: null,
    error3: null,
    success3: null,
    //archive request,
    loading4: false,
    archiveReq: null,
    error4: null,
    success4: null,
    //validate request
    loading5: false,
    validateReq: null,
    error5: null,
    success5: null,
};

const pipelineReqSlice = createSlice({
    name: 'piperequest',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(pipelineRequest.pending, (state) => {
                state.loadingz = true;
                state.error = null;
                state.success = false;
            })
            .addCase(pipelineRequest.fulfilled, (state, { payload }) => {
                state.loadingz = false;
                state.reqInfo = payload;
                state.success = true;
            })
            .addCase(pipelineRequest.rejected, (state, { payload }) => {
                state.loadingz = false;
                state.error = payload;
                state.success = false;
            })

            //result upload
            .addCase(resultUpload.pending, (state) => {
                state.loading7 = false;
                state.error7 = null;
                state.success7 = false;
            })
            .addCase(resultUpload.fulfilled, (state, { payload }) => {
                state.loading7 = false;
                state.resultInfo = payload;
                state.success7 = true;
            })
            .addCase(resultUpload.rejected, (state, { payload }) => {
                state.loading7 = false;
                state.error7 = payload;
                state.success7 = false;
            })

            //result list
            .addCase(resultList.pending, (state) => {
                state.loading2 = false;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(resultList.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.reslistInfo = payload;
                state.success2 = true;
            })
            .addCase(resultList.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.error2 = payload;
                state.success2 = false;
            })
            //apprve requ
            .addCase(approveReq.pending, (state) => {
                state.loading3 = false;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(approveReq.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.apprD = payload;
                state.success3 = true;
            })
            .addCase(approveReq.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })
            //archived request
            .addCase(archiveRequest.pending, (state) => {
                state.loading4 = false;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(archiveRequest.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.archiveReq = payload;
                state.success4 = true;
            })
            .addCase(archiveRequest.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            })
            //validate request
            .addCase(validateRequest.pending, (state) => {
                state.loading5 = false;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(validateRequest.fulfilled, (state, { payload }) => {
                state.loading5 = false;
                state.validateReq = payload;
                state.success5 = true;
            })
            .addCase(validateRequest.rejected, (state, { payload }) => {
                state.loading5 = false;
                state.error5 = payload;
                state.success5 = false;
            });

        //Export pipeline
        // [exportRequest.pending]: (state) => {
        //   state.loading1 = true
        //   state.error1 = null
        // },
        // [exportRequest.fulfilled]: (state, { payload }) => {
        //   state.loading1 = false
        //   state.expoInfo = payload
        // },
        // [exportRequest.rejected]: (state, { payload }) => {
        //   state.loading1 = false
        //   state.error1 = payload
        // },
    },
});
export default pipelineReqSlice.reducer;
