import React, { useState } from 'react';
import { Modal, Divider, Tooltip } from 'components/elements/AntComponent';
import { Form, Select } from 'components/elements/FormComponent';
import { PlusSquareOutlined, DeleteFilled } from 'components/elements/IconComponent';

const { Option } = Select;

export default function RulesModal(props) {
    const [form] = Form.useForm();
    const [rules, setRules] = useState([{ id: 1 }]);
    const [showOperandSelector, setShowOperandSelector] = useState(false);
    const [selectedOperand, setSelectedOperand] = useState('');
    const [additionalRuleAdded, setAdditionalRuleAdded] = useState(false);

    const handleAddRuleSet = () => {
        if (!additionalRuleAdded) {
            setShowOperandSelector(true);
        }
    };

    const handleOperandSelect = (operand) => {
        setSelectedOperand(operand);
        setRules([...rules, { id: 1, operand }]);
        // setShowOperandSelector(false);
        setAdditionalRuleAdded(true); // Prevent further additions
    };
    const handleCancel = () => {
        setRules([{ id: 1 }]);
        setShowOperandSelector(false);
        setAdditionalRuleAdded(false);
        props?.data?.handleCallback(false);
    };

    const option1 = [
        { label: 'Alpha/beta ratio', value: 'Alpha/beta ratio' },
        { label: 'Theta/beta ratio', value: 'Theta/beta ratio' },
        { label: 'F3>F4 Asymmetry Present', value: 'F3>F4 Asymmetry Present' },
        { label: 'F7>F8 Asymmetry Present', value: 'F7>F8 Asymmetry Present' },
        { label: 'P4>P3 Asymmetry Present', value: 'P4>P3 Asymmetry Present' },
    ];
    const option2 = [
        { label: '<', value: '<' },
        { label: '>', value: '>' },
        { label: '||', value: '||' },
    ];
    const option3 = [
        { label: 'Eye Open', value: 'Eye Open' },
        { label: 'Eye Closed', value: 'Eye Closed' },
    ];
    return (
        <div>
            <Modal
                title="Add medication rules"
                open={props?.data?.open}
                // onOk={() => confirm("True", lifeID)}
                width={800}
                onCancel={handleCancel}
                maskClosable={false}
            >
                <Divider className="mb-3 mt-2" />
                <div className="header-content mt-3 mb-2 d-flex justify-content-between">
                    <h5 className="text-start">Medication Rule</h5>
                    <div className="col-auto me-2">
                        <PlusSquareOutlined
                            className="text-primary"
                            onClick={handleAddRuleSet}
                            disabled={additionalRuleAdded} // Disable if additional rule is added
                        />
                    </div>
                </div>
                <Select options={option3} className="col-md-4 mb-3" placeholder="Select Eye Type" />
                <Form layout="vertical" className="medRule-form">
                    {/* Default Rule Set */}
                    <div className="row m-0 p-2 rule-background">
                        <div className="col-4 ps-0">
                            <Form.Item label="Select Marker" name="marker_default" className="w-100 ps-0" rules={[{ required: false, message: 'This field is required' }]}>
                                <Select mode="multiple" options={option1} />
                            </Form.Item>
                        </div>
                        <div className="col-2">
                            <Form.Item label="Operand" name="operand_default" className="w-100" rules={[{ required: false, message: 'This field is required' }]}>
                                <Select mode="multiple" options={option2}></Select>
                            </Form.Item>
                        </div>
                        <div className="col-4 pe-0">
                            <Form.Item label="Value" name="value_default" className="w-100 pe-0" rules={[{ required: false, message: 'This field is required' }]}>
                                <Select mode="multiple" options={option1} />
                            </Form.Item>
                        </div>
                    </div>

                    {/* Operand Selector */}
                    {showOperandSelector && (
                        <div className="row m-0 p-2 ">
                            <div className="col-12 d-flex justify-content-center">
                                <Select style={{ width: '100px' }} placeholder="Select Operand" onChange={handleOperandSelect}>
                                    <Select.Option value="AND">AND</Select.Option>
                                    <Select.Option value="OR">OR</Select.Option>
                                    <Select.Option value="NOT">NOT</Select.Option>
                                </Select>
                            </div>
                        </div>
                    )}

                    {/* Additional Rule Set */}
                    {additionalRuleAdded &&
                        rules.slice(1).map((rule, index) => {
                            if (index == 0) {
                                return (
                                    <div className="row m-0 p-2 rule-background" key={rule.id}>
                                        <div className="col-4 ps-0">
                                            <Form.Item label="Select Marker" name={`marker_${rule.id}`} className="w-100 ps-0" rules={[{ required: false, message: 'This field is required' }]}>
                                                <Select mode="multiple" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-2">
                                            <Form.Item label="Operand" name={`operand_${rule.id}`} className="w-100" rules={[{ required: false, message: 'This field is required' }]}>
                                                <Select mode="multiple">
                                                    <Select.Option>{}</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-4 pe-0">
                                            <Form.Item label="Value" name={`value_${rule.id}`} className="w-100 pe-0" rules={[{ required: false, message: 'This field is required' }]}>
                                                <Select mode="multiple" />
                                            </Form.Item>
                                        </div>
                                        <div className="col d-flex justify-content-center ps-5 align-items-center">
                                            <Tooltip title="Delete" className="mt-0 text-danger">
                                                <DeleteFilled />
                                            </Tooltip>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                </Form>
            </Modal>
        </div>
    );
}
