import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;
export const phq8Master = createAsyncThunk('phq8_master', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/phq_mdata`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const addPhq8 = createAsyncThunk('add_phq8', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/add_phq`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const deletePhq8 = createAsyncThunk('remove_phq8', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}md_management/remove_phq`, payload);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
