import { createSlice } from '@reduxjs/toolkit';
import { addInterpretation, deleteIntchoicefield, InterpretationMaster,AuthmateMdata } from 'store/actions/master-data/InterpretationActions';

const initialState = {
  loading: false,
  interpretInfo: null,
  error: null,
  success: false,
  //addint
  loading1: false,
  addInterpretInfo: null,
  error1: null,
  success1: false,
  //remove-int
  loading2: false,
  rmvint: null,
  error2: null,
  success2: false,
  //authmataData
  loading3: false,
  authmataData: null,
  error3: null,
  success3: false
}

const interpretationMasterSlice = createSlice({
  name: 'interpretationMaster',
  initialState,
  reducers: {},
  extraReducers:(builder)=> {
    builder
    //get interpretation
    .addCase(InterpretationMaster.pending,(state)=>{
      state.loading = true
      state.error = null;
      state.success=false;
    })
    .addCase(InterpretationMaster.fulfilled,(state,{payload})=>{
      state.loading = false
      state.interpretInfo = payload;
      state.success=true;
    })
    .addCase(InterpretationMaster.rejected,(state,{payload})=>{
      state.loading = false
      state.error = payload;
      state.success=false;
    })
    //add interpretation
    .addCase(addInterpretation.pending,(state)=>{
      state.loading1 = true;
      state.error1 = null;
      state.success1 = false;
    })
    .addCase(addInterpretation.fulfilled,(state,{payload})=>{
      state.loading1 = false;
      state.addInterpretInfo = payload;
      state.success1 = true;
    })
    .addCase(addInterpretation.rejected,(state,{payload})=>{
      state.loading1 = false;
      state.error1 = payload;
      state.success1 = false;
    })
    //delete interpretation
    .addCase(deleteIntchoicefield.pending,(state)=>{
      state.loading2 = true;
      state.error2 = null;
      state.success2 = false;
    })
    .addCase(deleteIntchoicefield.fulfilled,(state,{payload})=>{
      state.loading2 = false
      state.rmvint = payload
      state.success2 = true;
    })
    .addCase(deleteIntchoicefield.rejected,(state,{payload})=>{
      state.loading2 = false
      state.error2 = payload
      state.success2 = false;
    })
   
     .addCase(AuthmateMdata.pending,(state)=>{
      state.loading3 = true;
      state.error3 = null;
      state.success3 = false;
    })
    .addCase(AuthmateMdata.fulfilled,(state,{payload})=>{
      state.loading3 = false
      state.authmataData = payload
      state.success3 = true;
    })
    .addCase(AuthmateMdata.rejected,(state,{payload})=>{
      state.loading3 = false
      state.error3 = payload
      state.success3 = false;
    })
  },
})
export default interpretationMasterSlice.reducer

