import { createSlice } from '@reduxjs/toolkit';
import { phq8Master,deletePhq8,addPhq8} from 'store/actions/master-data/Phq8Actions';

const initialState = {
    loading: false,
    phq8Info: null,
    error: null,
    success: false,
    //addphq8 question
    loading1:false,
    addPhq8Info:null,
    error1:null,
    success1:false,
    //deletephq8
    loading2:false,
    rmvphq8:null,
    error2:null,
    success2:false,

}
const phq8MasterSlice = createSlice({
    name: 'phq8Master',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        //get phq8
        .addCase(phq8Master.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.success = false;
        })
        .addCase(phq8Master.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.phq8Info = payload;
          state.success = true;
        })
        .addCase(phq8Master.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.success = false;
        })
        //add phq8
        .addCase(addPhq8.pending,(state)=>{
          state.loading1 = true;
          state.error1 = null;
          state.success1=false;
        })
        .addCase(addPhq8.fulfilled,(state,{payload})=>{
          state.loading1 = false
          state.addPhq8Info = payload
          state.success1=true;
        })
        .addCase(addPhq8.rejected,(state,{payload})=>{
          state.loading1 = false
          state.error1= payload
          state.success1=false;
        })

        //delete phq8
        .addCase(deletePhq8.pending,(state)=>{
          state.loading2 = true;
          state.error2 = null;
          state.success2=false;
        })
        .addCase(deletePhq8.fulfilled,(state,{payload})=>{
          state.loading2 = false
          state.rmvphq8 = payload
          state.success2=true;
        })
        .addCase(deletePhq8.rejected,(state,{payload})=>{
          state.loading2 = false
          state.error2= payload
          state.success2=false;
        })
    },
})
export default phq8MasterSlice.reducer